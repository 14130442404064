<template>
  <div>
    <div
      :class="
        'con-social-media-post-icon' 
      "
    >
    <div class="con-post-to-space">
      <div class="con-stars-post" @click="likePost(post)">
          
          <i
          v-if="post.type != 'lecture'"
            class="flaticon-star-1"
            :class="{ 'flaticon-star-2': post.is_liked_by_student }"
          ></i><i
            v-else
            class="flaticon-star-1"
            :class="{ 'flaticon-star-2': post.student_rate }"
          ></i>
          <span v-if="post.type != 'lecture'">{{ post.likes_count }}</span>
          <span v-if="post.type == 'lecture'">{{ post.rate_count }}</span>
          <span v-if="post.type == 'lecture'">({{ post.rate_average }})</span>
        </div>
        <div class="con-stars-post" @click="showPost(post)">
          <i class="flaticon-chat" :class="{'flaticon-no-comment':post.disable_comments == true}"></i>
          <span v-if="post.disable_comments != true">{{ post.comments_count}}</span>
        </div>
        
      </div>
      <div class="con-post-to-space">
        <div
          class="con-stars-post con-star-foricon-post-save"
          @click="savePost(post)"
        >
          <i
            :class="{
              'flaticon-bookmark': post.is_saved_by_student,
              'flaticon-bookmark-1': !post.is_saved_by_student,
            }"
          ></i>
        </div>
      </div>
      
    </div>
    <!-- ####################################### -->
    <!-- comment home  -->
    <!-- ####################################### -->
    <div class="con-empty-comments" v-if="$router.currentRoute.name == 'Home' && post.disable_comments == true">
        <i class="flaticon-no-talking"></i>
        <p>No Comments Are Avalible</p>
    </div>
    <div v-if="$router.currentRoute.name == 'Home' && post.disable_comments != true " class="main-con-post-comment" style="border-top:1px solid #eee">
      <div class="con-write-comment">
        <input
          class="input-text-comment"
          type="text"
          v-model="question"
          placeholder="Type Your Comment"
          required
        />
        <input
          class="input-comment-attachment-class"
          type="file"
          name=""
          @change="onChangeUploadAttachment"
          accept="image/*"
          id="input-comment-attachment-id"
        />
        <label class="attachment-label-icon" for="input-comment-attachment-id">
          <i class="fa-solid fa-paperclip"></i
        ></label>
        <button type="submit" @click="askQuestion">
          <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
      
      <div v-if="(post.top_comment.length > 0 )">
        <div class="con-title-top-comment-see-all">
          <p class="text-of-top-comment">
            Top Comment
          </p>
          <router-link :to="`/show-post/${post._id}`">
            See All 
            <i class="fa-solid fa-angle-right"></i>
          </router-link>
        </div>
      <div
        class="con-comment-one"
        v-for="comment in post.top_comment"
        :key="comment.question_id"
      >
        <img :src="comment.student_profile_image_full_path" alt="" />
        <div class="main-con-comment-content">
          <div class="con-comment-content">
            <p class="comment-writer-name">{{ comment.student_name }}</p>
            <p class="comment-content-content">
              {{ comment.question_question }}
            </p>
          </div>
          <img
            v-if="comment.question_image"
            :src="comment.question_image_full_path"
            alt=""
          />
          <div class="con-comment-actions">
            <span>{{ comment.question_created_at }}</span>
            <div class="con-likes-comment">
              <i
                class="fa-star"
                :class="{
                  'fa-regular': !comment.is_liked_by_student,
                  'fa-solid': comment.is_liked_by_student,
                }"
                @click="likecomment(comment, post)"
              ></i>
              <p class="stars-number">{{ comment.question_likes_count }}</p>
            </div>
            <i
              class="fa-solid fa-share"
              @click="showReply(comment.question_id)"
            ></i>
            <span class="repl-count-comment">{{comment.question_replies_count}}</span>
          </div>
          <div 
            v-if="showReplyStatus == comment.question_id  " style="margin-top:10px">
            <div
            class="con-write-comment"
          >
            <input
              class="input-text-comment"
              type="text"
              placeholder="Add Reply . . ."
              v-model="reply"
              required
            />
            <input
              class="input-comment-attachment-class"
              type="file"
              name=""
              @change="onChangeUploadAttachment"
              accept="image/*"
              id="input-comment-attachment-id"
            />
            <label
              class="attachment-label-icon"
              for="input-comment-attachment-id"
            >
              <i class="fa-solid fa-paperclip"></i
            ></label>
            <button type="submit" @click="addReply(comment.question_id)">
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          <div
            class="con-comment-one"
            v-for="reply in comment.question_replys"
            :key="reply.reply_id"
          >
            <img :src="reply.reply_sender_profile_image_full_path" alt="" />
            <div class="main-con-comment-content">
              <div class="con-comment-content">
                <p class="comment-writer-name">{{ reply.reply_sender_name }}</p>
                <p class="comment-content-content">{{ reply.reply_answer }}</p>
              </div>
              <img
                v-if="reply.reply_image_full_path"
                :src="reply.reply_image_full_path"
                alt=""
              />
              <div class="con-comment-actions">
                <span>{{ reply.reply_created_at }}</span>
                <div class="con-likes-comment">
                  <i class=" fa-star" @click="starReply(reply)" :class="{
                  'fa-regular': !reply.is_liked_by_student,
                  'fa-solid': reply.is_liked_by_student,
                }" ></i>
                  <p class="stars-number">{{reply.reply_likes_count}}</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <!-- ####################################### -->
    <!-- comment see post  -->
    <!-- ####################################### -->
    <div class="con-empty-comments" v-if="post.disable_comments == true && $router.currentRoute.name == 'Show Post' ">
        <i class="flaticon-no-talking"></i>
        <p>No Comments Are Avalible</p>
    </div>
    <div v-if="showComments && post.disable_comments != true " class="main-con-post-comment">
      <div class="con-write-comment">
        <input
          class="input-text-comment"
          type="text"
          v-model="question"
          placeholder="Type Your Comment"
          required
        />
        <input
          class="input-comment-attachment-class"
          type="file"
          name=""
          @change="onChangeUploadAttachment"
          accept="image/*"
          id="input-comment-attachment-id"
        />
        <label class="attachment-label-icon" for="input-comment-attachment-id">
          <i class="fa-solid fa-paperclip"></i
        ></label>
        <button type="submit" @click="askQuestion">
          <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
      <div
        class="con-comment-one"
        v-for="comment in comments"
        :key="comment.question_id"
      >
        <img :src="comment.student_profile_image_full_path" alt="" />
        <div class="main-con-comment-content">
          <div class="con-comment-content">
            <p class="comment-writer-name">{{ comment.student_name }}</p>
            <p class="comment-content-content">
              {{ comment.question_question }}
            </p>
          </div>
          <img
            v-if="comment.question_image"
            :src="comment.question_image_full_path"
            alt=""
          />
          <div class="con-comment-actions">
            <span>{{ comment.question_created_at }}</span>
            <div class="con-likes-comment">
              <i
                class="fa-star"
                :class="{
                  'fa-regular': !comment.is_liked_by_student,
                  'fa-solid': comment.is_liked_by_student,
                }"
                @click="likecomment(comment, post)"
              ></i>
              <p class="stars-number">{{ comment.question_likes_count }}</p>
            </div>
            <i
              class="fa-solid fa-share"
              @click="showReply(comment.question_id)"
            ></i>
            <span class="repl-count-comment">{{comment.question_replies_count}}</span>
          </div>
          <div v-if="showReplyStatus " style="margin-top:10px">
            <div
            class="con-write-comment"
            
          >
            <input
              class="input-text-comment"
              type="text"
              placeholder="Add Reply . . ."
              v-model="reply"
              required
            />
            <input
              class="input-comment-attachment-class"
              type="file"
              name=""
              @change="onChangeUploadAttachment"
              accept="image/*"
              id="input-comment-attachment-id"
            />
            <label
              class="attachment-label-icon"
              for="input-comment-attachment-id"
            >
              <i class="fa-solid fa-paperclip"></i
            ></label>
            <button type="submit" @click="addReply(comment.question_id)">
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          <div
            class="con-comment-one"
            v-for="reply in comment.question_replys"
            :key="reply.reply_id"
          >
            <img :src="reply.reply_sender_profile_image_full_path" alt="" />
            <div class="main-con-comment-content">
              <div class="con-comment-content">
                <p class="comment-writer-name">{{ reply.reply_sender_name }}</p>
                <p class="comment-content-content">{{ reply.reply_answer }}</p>
              </div>
              <img
                v-if="reply.reply_image_full_path"
                :src="reply.reply_image_full_path"
                alt=""
              />
              <div class="con-comment-actions">
                <span>{{ reply.reply_created_at }}</span>
                <div class="con-likes-comment">
                  <i class=" fa-star" @click="starReply(reply)" :class="{
                  'fa-regular': !reply.is_liked_by_student,
                  'fa-solid': reply.is_liked_by_student,
                }" ></i>
                  <p class="stars-number">{{reply.reply_likes_count}}</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showComments && !isLastPageComment"
      class="main-con-to-see-more-button"
    >
      <button @click="showNextComments">
        See More <i class="fa-solid fa-ellipsis"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import StarRating from "vue-star-rating";
import axios from "@/axiosConfig";
export default {
  name: "PostFooter",
  props: {
    post: Object,
    showComments: Boolean,
  },
  components: {
    // StarRating,
  },
  data() {
    return {
      question: "",
      reply: "",
      showReplyStatus: 0,
      image: "",
      comment_image: "",
    };
  },
  computed: {
    ...mapGetters("post", ["comments", "nextPage", "isLastPageComment"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("post", [
      "likePostApi",
      // "ratePostApi",
      // "disLikePostApi",
      "askQuestionApi",
      "addReplyApi",
      "getCommentsApi",
      "savePostApi",
      "clearComments",
      "showLecturePostApi",
    ]),
    // ratePost(post, value) {
    //   // var data = {
    //   //   postId: post._id,
    //   //   student_rate: value,
    //   // };
    //   console.log(post);
    //   if (post.type != "lecture") {
    //     this.ratePostApi(data).then(() => {
    //       post["student_rate"] = value;
    //     });
    //   } else if (post.type == "lecture" && post.is_purchased) {
    //     this.ratePostApi(data).then(() => {
    //       post["student_rate"] = value;
    //     });
    //   }
    // },
    likePost(post) {
      if(post.type != 'lecture'){
        this.likePostApi(post._id).then(() => {
        if (post["is_liked_by_student"]) {
          post["likes_count"] = post["likes_count"] - 1;
        } else {
          post["likes_count"] = post["likes_count"] + 1;
        }
        post["is_liked_by_student"] = !post["is_liked_by_student"];
      });
      }
    },
    // disLikePost(post, event) {
    //   this.disLikePostApi(post._id).then(() => {
    //     if (post.is_liked_by_student == 1) {
    //       post["is_liked_by_student"] = 0;
    //     }

    //     if (event.target.closest("svg").classList.contains("dislikePost")) {
    //       event.target.closest("svg").classList.remove("dislikePost");
    //       post["is_disliked_by_student"] = 0;
    //     } else {
    //       event.target.closest("svg").classList.add("dislikePost");
    //       post["is_disliked_by_student"] = 1;
    //       post["likes_count"] = post["likes_count"]
    //         ? post["likes_count"] - 1
    //         : 0;
    //     }
    //   });
    // },
    savePost(post) {
      post.is_saved_by_student = !post.is_saved_by_student;
      this.savePostApi(post._id);

      // .then(() => {
      //   if (post.is_saved_by_student == 1) {
      //     post["is_saved_by_student"] = 0;
      //   }

      //   if (event.target.classList.contains("fa")) {
      //     event.target.classList.remove("fa");
      //     post["is_saved_by_student"] = 0;
      //     post["saves_count"] = post["saves_count"] - 1;
      //   } else {
      //     event.target.classList.add("fa");
      //     post["is_saved_by_student"] = 1;
      //     post["saves_count"] = post["saves_count"] + 1;
      //   }
    },
    askQuestion() {
      if (this.question || this.image) {
        // var data = { question: this.question };
        const formData = new FormData();
        formData.append("image", this.image);
        formData.append("question", this.question ? this.question : "");
        formData.append("post_id", this.post._id);

        this.askQuestionApi(formData).then(() => {
          // let date = new Date();
          // let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
          //   date
          // );
          // let month = new Intl.DateTimeFormat("en", { month: "short" }).format(
          //   date
          // );
          // let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
          //   date
          // );
          // let time = new Intl.DateTimeFormat("en", {
          //   hour: "numeric",
          //   minute: "numeric",
          // }).format(date);
          // this.comments.unshift({
          //   question_created_at: `${month} ${day},${year} : ${time}`,
          //   question_question: data.question,
          //   student_id: this.user.id,
          //   student_name: this.user.name,
          //   student_profile_image_full_path: this.user.profile_image_full_path,
          //   question_image_full_path: this.comment_image,
          //   question_image: this.comment_image,
          // });
        });
        this.question = null;
        this.image = null;
      }
    },
    addReply(question_id) {
      if (this.reply) {
        var data = {
          body: {
            reply: this.reply,
          },
          question_id: question_id,
          post_id: this.post._id,
        };

        const formData = new FormData();

        formData.append("image", this.image);
        formData.append("question_id", question_id);
        // formData.append("post_id", this.post._id);
        formData.append("reply", this.reply);
        formData.append("_method", "patch");
        data.body = formData;
        this.addReplyApi(data).then((response) => {
          console.log(response);
          // let date = new Date();
          // let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
          //   date
          // );
          // let month = new Intl.DateTimeFormat("en", { month: "short" }).format(
          //   date
          // );
          // let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
          //   date
          // );
          // let time = new Intl.DateTimeFormat("en", {
          //   hour: "numeric",
          //   minute: "numeric",
          // }).format(date);
          const index = this.comments.findIndex(
            (comment) => comment["question_id"] === question_id
          );

          this.comments[index]["question_replys"].push(response.data.data);
        });
        this.reply = null;
      }
    },
    starReply(reply) {
      axios.post(`/lecture-questions/replies-like/${this.post._id}/${reply.reply_id}`)
      reply.is_liked_by_student = !reply.is_liked_by_student;
      if(reply.is_liked_by_student){
        reply.reply_likes_count++;
      }else{
        reply.reply_likes_count--;
      }
    },
    showPost(post) {
      if(post.disable_comments != true){
        if (this.$router.currentRoute.name != "Show Post") {
        if (this.post.type == "lecture") {
          this.$router.push("/lecture/" + this.post.lecture_id);
        } else {
          this.$router.push("/show-post/" + this.post._id);
        }
      }
      }
      // else {
      //   this.clearComments();
      //   if (this.showCommentsStatus == 0) {
      //     this.showCommentsStatus = 1;
      //     var data = {
      //       lecture_id: this.post.lecture_id,
      //       nextPage: 1,
      //     };
      //     this.getCommentsApi(data);
      //   } else {
      //     this.showCommentsStatus = 0;
      //   }
      // }
    },
    showNextComments() {
      var data = {
        lecture_id: this.post.lecture_id,
        nextPage: this.nextPage,
      };
      this.getCommentsApi(data);
    },
    showReply(id) {
      this.reply = "";
      if(this.showReplyStatus == 0 ){
        this.showReplyStatus = id ;
      }else{
        this.showReplyStatus = 0 ;
      }
    },
    onChangeUploadAttachment(event) {
      this.image = event.target.files[0];
      this.comment_image = URL.createObjectURL(this.image);
    },
    getComments() {
      if (this.showComments) {
        var data = {
          post_id: this.post._id,
          nextPage: 1,
        };
        // if (this.post.lecture_id) {
        this.getCommentsApi(data);
        // }
      }
    },
    likecomment(comment, post) {
      comment.is_liked_by_student = !comment.is_liked_by_student;
      if (comment.is_liked_by_student) {
        comment.question_likes_count++;
      } else {
        comment.question_likes_count--;
      }
      axios.post(
        `lecture-questions/comments-like/${post._id}/${comment.question_id}`
      );
    },
  },
  watch: {
    post: [
      {
        handler: "getComments",
      },
    ],
  },
  mounted() {
    // this.getComments()
  },
};
</script>

<style scoped>
.savePost {
  font-weight: 900 !important;
  -webkit-text-fill-color: #303030 !important;
}
.con-empty-comments{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 10px 20px;
  justify-content: center;
  border-top: 1px solid #eee;
}
.con-empty-comments i{
  font-size: 25px;
  color: #888da8;
  margin-right: 10px;
  display: flex;
}
.con-empty-comments p{
  font-size: 15px;
  color: #888da8;
  font-weight: 600;
  margin-bottom: 0px;
}
.repl-count-comment{
  margin-left: 5px;
}
</style>
