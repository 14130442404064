<template>
  <div>
    <div v-html="post.iframe"></div>

    <div class="con-post-content con-post-content-Poll">
      <h1>{{ post.body }}</h1>
    </div>
    <post-tags :post="post"></post-tags>
  </div>
</template>
<script>
import PostTags from "../PostTags.vue";
// import { mapActions } from "vuex";
export default {
  components: { PostTags },
  name: "NormalPost",
  props: {
    post: Object,
  },

  // methods: {
  //   ...mapActions("post", ["likePostApi", "disLikePostApi", "savePostApi"]),
  //   likePost(post, event) {
  //     this.likePostApi(post._id).then(() => {
  //       if (post.is_disliked_by_student == 1) {
  //         post["is_disliked_by_student"] = 0;
  //       }

  //       if (event.target.classList.contains("likePost")) {
  //         event.target.classList.remove("likePost");
  //         post["is_liked_by_student"] = 0;
  //         post["likes_count"] = post["likes_count"] - 1;
  //       } else {
  //         event.target.classList.add("likePost");
  //         post["is_liked_by_student"] = 1;
  //         post["likes_count"] = post["likes_count"] + 1;
  //       }
  //     });
  //   },
  //   disLikePost(post, event) {
  //     this.disLikePostApi(post._id).then(() => {
  //       if (post.is_liked_by_student == 1) {
  //         post["is_liked_by_student"] = 0;
  //       }

  //       if (event.target.closest("svg").classList.contains("dislikePost")) {
  //         event.target.closest("svg").classList.remove("dislikePost");
  //         post["is_disliked_by_student"] = 0;
  //       } else {
  //         event.target.closest("svg").classList.add("dislikePost");
  //         post["is_disliked_by_student"] = 1;
  //         post["likes_count"] = post["likes_count"]
  //           ? post["likes_count"] - 1
  //           : 0;
  //       }
  //     });
  //   },
  //   savePost(post, event) {
  //     this.savePostApi(post._id).then(() => {
  //       if (post.is_saved_by_student == 1) {
  //         post["is_saved_by_student"] = 0;
  //       }

  //       if (event.target.classList.contains("savePost")) {
  //         event.target.classList.remove("savePost");
  //         post["is_saved_by_student"] = 0;
  //         post["saves_count"] = post["saves_count"] - 1;
  //       } else {
  //         event.target.classList.add("savePost");
  //         post["is_saved_by_student"] = 1;
  //         post["saves_count"] = post["saves_count"] + 1;
  //       }
  //     });
  //   },
  // },
};
</script>
<style scoped>
.con-post-content{
  padding-top: 5px;
}
</style>