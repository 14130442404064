<template>
  <!-- <main class="main-content">
    <div class="examContent">
      <div class="container">
        <div class="exam-progress d-flex">
          <h3>{{( exam.content)?  exam.content.model.title : '' }}</h3>
        </div>
        <div class="theExamContent d-lg-flex">
          <div class="examQuestions" style="width: 100%">
            <div class="questionsSteps">
              <div class="create-steps" id="create-steps">
                <h3></h3>
                <section
                  v-for="(question, index) in exam.student_progress_questions"
                  :key="index"
                  :class="'step-section step-' + (index + 1)"
                  :data-question-id="question.question.id"
                  :data-current-content-id="exam.id"
                >
                  <div class="question-1-step">
                    <div class="question-top-content">
                      <div
                        class="question-header d-flex justify-content-between"
                      >
                        <span
                          >السؤال
                          <small
                            >{{ index + 1 }}/{{
                              exam.student_progress_questions.length
                            }}</small
                          ></span
                        >
                        <span style="font-size: 22px"
                          ><i class="far fa-circle"></i
                        ></span>
                        <span
                          >درجة السؤال:
                          <small
                            >{{ question.question.degree }} درجة</small
                          ></span
                        >
                      </div>
                      <div class="question-body" style="text-align: left">
                        <img
                          v-if="question.question.image_full_path"
                          :src="question.question.image_full_path"
                        />
                        <h4 v-html="question.question.question"></h4>
                        <ul
                          v-if="question.question.type == 'multi_choose'"
                          style="margin: 20px"
                        >
                          <li
                            v-for="(answer, answerIndex) in question.question.answers"
                            :key="answerIndex"
                          >
                            <div class="">
                              <div class="">
                                <div
                                  v-if="answer.id == question.correct_answer_id"
                                  v-html="(answer.answer)? answer.answer : answers[answerIndex]"
                                  style="
                                    background: green;
                                    color: white;
                                    padding: 5px;
                                    margin-bottom: 3px;
                                  "
                                ></div>
                                <div
                                  v-else-if="answer.id == question.answer_id"
                                  v-html="(answer.answer)? answer.answer : answers[answerIndex]"
                                  style="
                                    background: red;
                                    color: white;
                                    padding: 5px;
                                    margin-bottom: 3px;
                                  "
                                ></div>
                                <div
                                  v-else
                                  v-html="(answer.answer)? answer.answer : answers[answerIndex]"
                                ></div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <ul
                          v-if="question.question.type == 'true_or_false'"
                          style="margin: 20px"
                        >
                          <li
                            v-for="(answer, answerIndex) in {
                              true: 'correct',
                              false: 'wrong',
                            }"
                            :key="answerIndex"
                          >
                            <div class="row">
                              <div class="col-md-12">
                                <div
                                  v-if="answer.id == question.correct_answer_id"
                                  style="
                                    background: green;
                                    color: white;
                                    padding: 5px;
                                    margin-bottom: 3px;
                                  "
                                >
                                  correct
                                </div>
                                <div
                                  v-else-if="answer.id == question.answer_id"
                                  style="
                                    background: red;
                                    color: white;
                                    padding: 5px;
                                    margin-bottom: 3px;
                                  "
                                >
                                  wrong
                                </div>
                                <div v-else>{{ answer }}</div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-if="question.question.hint" class="question-foot">
                        <h4>معلومة هامة</h4>
                        <p style="min-height: 0px !important">
                          {{ question.question.hint }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style="text-align: center">
                    <button
                      v-for="(question, questionIndex) in exam.questions"
                      :key="questionIndex"
                      class="question-step"
                      type="button"
                      :click="goTo(questionIndex)"
                    >
                      {{ questionIndex + 1 }}
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main> -->
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="main-con-to-exam-rsult-page">
          <div class="con-allteacher-page">
            <h1>My Exam Result</h1>
          </div>
          <h1 class="exam-name-title">
            {{ exam.content ? exam.content.model.title : "" }}
          </h1>
          <div
            v-for="(question, index) in exam.student_progress_questions"
            :key="index"
            :class="
              'main-con-to-question-exam main-con-to-question-exam-result step-' +
              (index + 1)
            "
            :data-question-id="question.question.id"
            :data-current-content-id="exam.id"
          >
            <div
              v-if="question.answer_id == question.correct_answer_id"
              class="show-answer-right-or-wrong yes-1"
            >
              <i class="fa-solid fa-check"></i>
            </div>
            <div v-else class="show-answer-right-or-wrong no-1">
              <i class="fa-solid fa-xmark"></i>
            </div>
            <div class="con-header-question-exam">
              <div class="con-question-exam-number-degree">
                <h1>Question {{ index + 1 }}</h1>
                <span
                  >({{ index + 1 }}/{{
                    exam.student_progress_questions.length
                  }})</span
                >
                <span
                  >Degree : <small>{{ question.question.degree }}</small></span
                >
              </div>
              <div class="exam-title-1">
                <p v-html="question.question.question"></p>
              </div>
              <div class="con-question-exam-img">
                <img
                  v-if="question.question.image_full_path"
                  :src="question.question.image_full_path"
                />
              </div>
            </div>
            <div v-if="question.question.type == 'multi_choose'">
              <div
                v-for="(answer, answerIndex) in question.question.answers"
                :key="answerIndex"
              >
                <div
                  v-if="answer.id == question.correct_answer_id"
                  class="con-answer-card-Question right-question"
                >
                  <span>{{ answers[answerIndex] }}</span>
                  <p v-html="answer.answer ? answer.answer : ''"></p>
                  <img
                    v-if="question.question.image_full_path"
                    :src="question.question.image_full_path"
                  />
                </div>
                <div
                  v-else-if="answer.id == question.answer_id"
                  class="con-answer-card-Question wrong-question"
                >
                  <span>{{ answers[answerIndex] }}</span>
                  <p v-html="answer.answer ? answer.answer : ''"></p>
                  <img
                    v-if="question.question.image_full_path"
                    :src="question.question.image_full_path"
                  />
                </div>
                <div v-else class="con-answer-card-Question">
                  <span>{{ answers[answerIndex] }}</span>
                  <p v-html="answer.answer ? answer.answer : ''"></p>
                  <img
                    v-if="question.question.image_full_path"
                    :src="question.question.image_full_path"
                  />
                </div>
              </div>
            </div>

            <div v-else-if="question.question.type == 'true_or_false'">
              <div
                v-for="(answer, answerIndex) in ['true', 'false']"
                :key="answerIndex"
              >
                <div
                  v-if="answer == question.correct_answer_id"
                  class="con-answer-card-Question right-question"
                >
                  <span>{{ answers[answerIndex] }}</span>
                  <p>{{ answer }}</p>
                </div>
                <div
                  v-else-if="answer == question.answer_id"
                  class="con-answer-card-Question wrong-question"
                >
                  <span>{{ answers[answerIndex] }}</span>
                  <p>{{ answer }}</p>
                </div>
                <div v-else class="con-answer-card-Question">
                  <span>{{ answers[answerIndex] }}</span>
                  <p>{{ answer }}</p>
                </div>
              </div>
            </div>
            <div class="con-question-hint-p" v-if="question.question.hint">
              <h1>Additional Notes</h1>
              <p>
                {{ question.question.hint }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ExamResult",
  data: function () {
    return {
      answers: ["A", "B", "C", "D"],
    };
  },
  computed: {
    ...mapGetters("examResult", ["exam"]),
  },
  methods: {
    ...mapActions("examResult", ["getExamResultApi"]),
    goTo(index) {
      index;
    },
  },
  beforeMount() {
    this.getExamResultApi(this.$route.params.id);
  },
};
</script>

<style>
.step-section {
  border-bottom: 1px solid;
}
</style>