<template>
  <div class="con-side-bar-course-ins-course">
    <div class="main-con-to-button-img">
      <div class="con-togle-button" id="side-ins-course-but-id" @click="showBar">
        <div class="line-1"></div>
        <div class="line-2"></div>
        <div class="line-3"></div>
      </div>
      <img src="@/assets/images/10.png" alt="" />
    </div>
    <h1 class="h1-ins-lecture-side-title-lecture">{{ lecture["title"] }}</h1>
    <div class="main-main-con-icons-side-bar">
      <div
        :class="[
          'con-content-side-ins-course',
          !!(currentContent.content_id == content.id) && 'active',
        ]"
        v-for="content in lecture.contents"
        :key="content.id"
        @click="getContent(content.id)"
      >
        <div class="con-svg" v-if="content['type'] == 'video'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:svgjs="http://svgjs.com/svgjs"
            version="1.1"
            width="512"
            height="512"
            x="0"
            y="0"
            viewBox="0 0 189.524 189.524"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
            class=""
          >
            <g>
              <g xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path
                    clip-rule="evenodd"
                    d="m94.762 180.048c47.102 0 85.286-38.183 85.286-85.286 0-47.102-38.183-85.286-85.286-85.286-47.102 0-85.286 38.184-85.286 85.286s38.184 85.286 85.286 85.286zm0 9.476c52.335 0 94.762-42.427 94.762-94.762 0-52.336-42.427-94.762-94.762-94.762-52.336 0-94.762 42.426-94.762 94.762 0 52.335 42.426 94.762 94.762 94.762z"
                    fill-rule="evenodd"
                    data-original="#000000"
                    class=""
                  ></path>
                </g>
                <g>
                  <path
                    clip-rule="evenodd"
                    d="m119.386 94.762-43.577-29.051v58.103zm8.804-5.519c3.939 2.626 3.939 8.413 0 11.039l-51.545 34.364c-4.408 2.939-10.313-.222-10.313-5.52v-68.727c0-5.298 5.905-8.458 10.313-5.519z"
                    fill-rule="evenodd"
                    data-original="#000000"
                    class=""
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div
          class="con-svg"
          v-if="content['type'] == 'exam' || content['type'] == 'homework'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:svgjs="http://svgjs.com/svgjs"
            version="1.1"
            width="512"
            height="512"
            x="0"
            y="0"
            viewBox="0 0 128 128"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
            class=""
          >
            <g>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m109.048 11.293h-4.178a6.653 6.653 0 0 0 -6.646 6.646v61.791a1.721 1.721 0 0 0 .025.251c0 .026 0 .053.008.078a1.714 1.714 0 0 0 .124.389l.006.019 6.985 15.052a1.75 1.75 0 0 0 3.174 0l6.985-15.052.006-.019a1.706 1.706 0 0 0 .124-.388c0-.027 0-.053.008-.08a1.708 1.708 0 0 0 .025-.25v-61.791a6.653 6.653 0 0 0 -6.646-6.646zm-7.324 66.687v-51.665h3.485v51.665zm6.985-51.665h3.485v51.665h-3.485zm-6.985-8.376a3.149 3.149 0 0 1 3.146-3.146h4.178a3.149 3.149 0 0 1 3.146 3.146v4.876h-10.47zm5.235 72.686-4.244-9.145h8.485z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m21.643 116.707h75.729a9.347 9.347 0 0 0 9.337-9.336v-3.585a1.749 1.749 0 0 0 -1.75-1.75h-13.423v-88.993a1.749 1.749 0 0 0 -1.75-1.75h-75.73a1.749 1.749 0 0 0 -1.75 1.75v94.328a9.346 9.346 0 0 0 9.336 9.336zm81.566-9.336a5.843 5.843 0 0 1 -5.837 5.836h-68.447a9.3 9.3 0 0 0 2.054-5.836v-1.835h72.23zm-87.4 0v-92.578h72.23v87.243h-58.81a1.749 1.749 0 0 0 -1.75 1.75v3.585a5.842 5.842 0 0 1 -5.835 5.836 5.843 5.843 0 0 1 -5.838-5.836z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m78.815 50.534h-37.6a1.75 1.75 0 0 0 0 3.5h37.6a1.75 1.75 0 0 0 0-3.5z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m78.815 67.888h-37.6a1.75 1.75 0 0 0 0 3.5h37.6a1.75 1.75 0 0 0 0-3.5z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m33.313 63.744h-8.287a1.75 1.75 0 0 0 -1.75 1.75v8.287a1.75 1.75 0 0 0 1.75 1.75h8.287a1.749 1.749 0 0 0 1.75-1.75v-8.287a1.749 1.749 0 0 0 -1.75-1.75zm-1.75 8.287h-4.787v-4.787h4.787z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m33.313 81.1h-8.287a1.75 1.75 0 0 0 -1.75 1.75v8.287a1.75 1.75 0 0 0 1.75 1.75h8.287a1.749 1.749 0 0 0 1.75-1.75v-8.288a1.749 1.749 0 0 0 -1.75-1.749zm-1.75 8.287h-4.787v-4.787h4.787z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m78.815 85.242h-37.6a1.75 1.75 0 0 0 0 3.5h37.6a1.75 1.75 0 0 0 0-3.5z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m26.783 50.554a1.749 1.749 0 1 0 -2.474 2.474l3.733 3.734a1.752 1.752 0 0 0 2.475 0l6.483-6.482a1.75 1.75 0 1 0 -2.475-2.474l-5.246 5.244z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m22.92 37.08h8.713a1.75 1.75 0 1 0 0-3.5h-6.963v-3.775h6.963a1.75 1.75 0 1 0 0-3.5h-6.963v-3.776h6.963a1.75 1.75 0 1 0 0-3.5h-8.713a1.75 1.75 0 0 0 -1.75 1.75v14.551a1.749 1.749 0 0 0 1.75 1.75z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m68.538 37.08a1.749 1.749 0 0 0 1.75-1.75v-9.607l3.081 3.805a1.808 1.808 0 0 0 2.721 0l3.081-3.8v9.602a1.75 1.75 0 0 0 3.5 0v-14.551a1.75 1.75 0 0 0 -3.11-1.1l-4.832 5.968-4.829-5.969a1.75 1.75 0 0 0 -3.11 1.1v14.552a1.749 1.749 0 0 0 1.748 1.75z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m36.454 36.743a1.749 1.749 0 0 0 2.445-.381l3.9-5.341 3.9 5.341a1.75 1.75 0 0 0 2.833-2.062l-4.562-6.245 4.562-6.243a1.75 1.75 0 1 0 -2.826-2.065l-3.9 5.342-3.9-5.342a1.75 1.75 0 1 0 -2.826 2.065l4.562 6.243-4.569 6.245a1.75 1.75 0 0 0 .381 2.443z"
                data-original="#000000"
                class=""
              ></path>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="m53.972 37.08a1.75 1.75 0 0 0 1.75-1.75v-5.525h5.213v5.525a1.75 1.75 0 0 0 3.5 0v-14.551a1.751 1.751 0 0 0 -1.75-1.75h-8.713a1.75 1.75 0 0 0 -1.75 1.75v14.551a1.749 1.749 0 0 0 1.75 1.75zm6.963-14.551v3.776h-5.213v-3.776z"
                data-original="#000000"
                class=""
              ></path>
            </g>
          </svg>
        </div>
        <div class="con-svg" v-if="content['type'] == 'file'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns:svgjs="http://svgjs.com/svgjs"
            version="1.1"
            width="512"
            height="512"
            x="0"
            y="0"
            viewBox="0 0 367.6 367.6"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
            class=""
          >
            <g>
              <g xmlns="http://www.w3.org/2000/svg">
                <g>
                  <g>
                    <path
                      d="M328.6,81.6c-0.4,0-0.4-0.4-0.8-0.8c-0.4-0.4-0.4-0.8-0.8-1.2L258.2,2.4c-0.4-0.4-1.2-0.8-1.6-1.2     c-0.4,0-0.4-0.4-0.8-0.4c-0.8-0.4-2-0.8-3.2-0.8H83.8C59,0,38.6,20.4,38.6,45.2v277.2c0,24.8,20.4,45.2,45.2,45.2h200     c24.8,0,45.2-20.4,45.2-45.2v-238C329,83.6,328.6,82.4,328.6,81.6z M260.2,27.2l44.4,50h-44.4V27.2z M313.8,322     c0,16.8-13.2,30.4-30,30.4h-200c-16.8,0-30-13.6-30-30V44.8c0-16.8,13.6-30,30-30H245v69.6c0,4,3.2,7.6,7.6,7.6h61.2V322z"
                      data-original="#000000"
                      class=""
                    ></path>
                    <path
                      d="M92.6,92h66.8c4,0,7.6-3.2,7.6-7.6s-3.2-7.6-7.6-7.6H92.6c-4,0-7.6,3.2-7.6,7.6S88.6,92,92.6,92z"
                      data-original="#000000"
                      class=""
                    ></path>
                    <path
                      d="M159.4,275.6H92.6c-4,0-7.6,3.2-7.6,7.6c0,4,3.2,7.6,7.6,7.6h66.8c4,0,7.6-3.2,7.6-7.6     C167,279.2,163.4,275.6,159.4,275.6z"
                      data-original="#000000"
                      class=""
                    ></path>
                    <path
                      d="M85,134.8c0,4,3.2,7.6,7.6,7.6H271c4,0,7.6-3.2,7.6-7.6c0-4-3.2-7.6-7.6-7.6H92.6C88.6,127.2,85,130.4,85,134.8z"
                      data-original="#000000"
                      class=""
                    ></path>
                    <path
                      d="M271,164.8H92.6c-4,0-7.6,3.2-7.6,7.6c0,4,3.2,7.6,7.6,7.6H271c4,0,7.6-3.2,7.6-7.6C278.6,168,275.4,164.8,271,164.8z"
                      data-original="#000000"
                      class=""
                    ></path>
                    <path
                      d="M271,202H92.6c-4,0-7.6,3.2-7.6,7.6c0,4,3.2,7.6,7.6,7.6H271c4,0,7.6-3.2,7.6-7.6C278.6,205.2,275.4,202,271,202z"
                      data-original="#000000"
                      class=""
                    ></path>
                    <path
                      d="M271,239.2H92.6c-4,0-7.6,3.2-7.6,7.6c0,4,3.2,7.6,7.6,7.6H271c4,0,7.6-3.2,7.6-7.6C278.6,242.8,275.4,239.2,271,239.2z"
                      data-original="#000000"
                      class=""
                    ></path>
                  </g>
                </g>
              </g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
              <g xmlns="http://www.w3.org/2000/svg"></g>
            </g>
          </svg>
        </div>
        <p v-if="content.type != 'section'" >{{ content["title"] }}</p>
        
        <div class="con-con-p-lectue-pur-section" v-else>
          <h3>{{content["title"] }}</h3>
        </div>
      </div>
      <div
        
        class="con-content-side-ins-course"
        :class="{'active': (currentContent.content)? ((currentContent.content.type == 'rate')? 1  : 0): 0}"
        @click="getContent('rate')"
      >
      <div class="con-svg">
        <i class="fa-regular fa-star"></i>
        
      </div>
      <p>Rating</p>
    </div>
    </div>
    <div
      class="con-img-footer-teache-side-bar"
      @click="$router.push('/teacher/' + lecture.teacher_id)"
    >
      <img :src="teacher.teacher_profile_image" alt="" />
      <div class="con-name-category-side-footer">
        <p>{{ teacher.teacher_name }}</p>
        <span>{{ teacher.teacher_subject_name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LectureContentSidebar",
  computed: {
    ...mapGetters("lecturePurchased", ["currentContent", "lecture", "teacher"]),
  },
  data(){
    return{
      showSideBar:false,
    }
  },
  methods: {
    ...mapActions("lecturePurchased", ["getLectureProgressApi"]),
    getContent(content) {
      this.$router.push({ query: { content: content } });
      this.getLectureProgressApi(this.$route.params.id)
        .then(() => {})
        .catch((err) => console.log(err));
    },
    showBar(){
      this.showSideBar = !this.showSideBar
      this.$emit('show',this.showSideBar)
    },  
  },
  mounted: function () {
  },
};
</script>
<style scoped>
.con-con-p-lectue-pur-section{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.con-con-p-lectue-pur-section h3{
  font-size:17px ;
  font-weight: 600;
  color: #898989;
  margin-bottom: 0px;
  position: relative;
  width:auto;

}
.con-con-p-lectue-pur-section h3::after{
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(-130%,-50%);
  width: 50px;
  background: rgb(199, 199, 199);
  height: 2px;
  
}
.con-con-p-lectue-pur-section::after{
  content:'';
  position: absolute;
  width: 80%;
  height: 2px;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  background-color: rgb(199, 199, 199);
  display: none;
}
.con-con-p-lectue-pur-section h3::before{
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(130%,-50%);
  width: 50px;
  background: rgb(199, 199, 199);
  height: 2px;
  
}
.con-side-bar-content-ins-course.hide .con-con-p-lectue-pur-section h3{
  display: none;
}
.con-side-bar-content-ins-course.hide .con-con-p-lectue-pur-section::after{
  display: block;
}
</style>