import store from "@/store";
import Axios from "axios";
import router from "./router";

const axios = Axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.VUE_APP_API_URL_PRODUCTION
      : process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    responseType: "json",
  },
});

axios.interceptors.request.use((config) => {
  const token = store.getters["auth/getUserToken"];

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      router.push("/login");
      // return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

export default axios;
