<template>
  <!-- <div class="container-fluid con-flu-456 con-flu-457 con-flu-4231">
    <img
      src="@/assets/img/tms empty.png"
      alt=""
      class="img-sign-up-096 img-sign-up-022"
    />

    <div class="container con-9020 con-9021">
      <div class="con-563-sign-up">
        <h1>Register</h1>
        <form @submit.prevent="register">
          <div class="form-237 form-2354">
            <input
              type="text"
              placeholder="Enter Student Name"
              v-model="values.name"
              @blur="validate('name')"
              @keypress="validate('name')"
              :class="['form-control', !!errors.name && 'is-invalid']"
            />
            <p
              :class="['form-input-hint', !!errors.name && 'invalid-feedback']"
              v-if="!!errors.name"
            >
              {{ errors.name }}
            </p>
            <label for="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                />
              </svg>
              <span>Student Name</span>
            </label>
          </div>
          <div class="form-237 form-2354">
            <input
              style="-webkit-appearance: none"
              type="number"
              placeholder="Enter Your Phone Number"
              v-model="values.phone_number"
              @blur="validate('phone_number')"
              @keypress="validate('phone_number')"
              :class="[
                'phone_number form-control',
                !!errors.phone_number && 'is-invalid',
              ]"
            />
            <p
              :class="[
                'form-input-hint',
                !!errors.phone_number && 'invalid-feedback',
              ]"
              v-if="!!errors.phone_number"
            >
              {{ errors.phone_number }}
            </p>
            <label for="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-telephone-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
              </svg>
              <span>Phone number</span>
            </label>
          </div>
          <div class="form-237 form-2354">
            <select
              v-model="values.education_level_id"
              @blur="validate('education_level_id')"
              @keypress="validate('education_level_id')"
              :class="[!!errors.education_level_id && 'is-invalid']"
            >
              <option value="" selected="true">Choose Education Level</option>
              <option
                v-for="educationLevel in educationLevels"
                :key="educationLevel.id"
                :value="educationLevel.id"
              >
                {{ educationLevel.name }}
              </option>
            </select>
            <p
              :class="[
                'form-input-hint',
                !!errors.education_level_id && 'invalid-feedback',
              ]"
              v-if="!!errors.education_level_id"
            >
              {{ errors.education_level_id }}
            </p>
          </div>
          <div class="form-237 form-2354">
            <input
              type="text"
              placeholder="Enter Your Email"
              v-model="values.email"
              @blur="validate('email')"
              @keypress="validate('email')"
              :class="['form-control', !!errors.email && 'is-invalid']"
            />
            <p
              :class="['form-input-hint', !!errors.email && 'invalid-feedback']"
              v-if="!!errors.email"
            >
              {{ errors.email }}
            </p>
            <label for="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-envelope-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"
                />
              </svg>
              <span>Email Address</span>
            </label>
          </div>
          <div class="form-237 form-2354">
            <input
              :type="passwordStatus ? 'text' : 'password'"
              placeholder="Enter Your Password"
              v-model="values.password"
              @blur="validate('password')"
              @keypress="validate('password')"
              :class="['form-control', !!errors.password && 'is-invalid']"
            />
            <p
              :class="[
                'form-input-hint',
                !!errors.password && 'invalid-feedback',
              ]"
              v-if="!!errors.password"
            >
              {{ errors.password }}
            </p>
            <svg
              v-show="passwordStatus"
              @click="passwordToggleShow"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-eye-fill-11"
              viewBox="0 0 16 16"
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path
                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
              />
            </svg>
            <svg
              v-show="!passwordStatus"
              @click="passwordToggleShow"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-eye-slash-fill-11"
              viewBox="0 0 16 16"
            >
              <path
                d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"
              />
              <path
                d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"
              />
            </svg>
            <label for="">
              <span>Password</span>
            </label>
          </div>
          <div class="form-237 form-2354">
            <input
              :type="passwordConfirmStatus ? 'text' : 'password'"
              placeholder="Enter Your Password Confirmation"
              v-model="values.passwordConfirmation"
              @blur="validate('passwordConfirmation')"
              @keypress="validate('passwordConfirmation')"
              :class="[
                'form-control',
                !!errors.passwordConfirmation && 'is-invalid',
              ]"
            />
            <p
              :class="[
                'form-input-hint',
                !!errors.passwordConfirmation && 'invalid-feedback',
              ]"
              v-if="!!errors.passwordConfirmation"
            >
              {{ errors.passwordConfirmation }}
            </p>
            <svg
              v-show="passwordConfirmStatus"
              @click="passwordConfirmToggleShow"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-eye-fill-11"
              viewBox="0 0 16 16"
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path
                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
              />
            </svg>
            <svg
              v-show="!passwordConfirmStatus"
              @click="passwordConfirmToggleShow"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-eye-slash-fill-11"
              viewBox="0 0 16 16"
            >
              <path
                d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"
              />
              <path
                d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"
              />
            </svg>
            <label for="">
              <span>Confirm Password</span>
            </label>
          </div>
          <div>
            <input type="submit" value="" id="submit-5843" />
            <label for="submit-5843" class="submit-5843">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="white"
                class="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </label>
          </div>
        </form>
      </div>
      <div class="con-box-you-have-acc">
        <p>Already have an account?</p>
        <router-link to="/login">Login</router-link>
      </div>
    </div>
  </div> -->

  <div>
    <div class="banner-sign-page"></div>
    <div class="main-con-to-sign-form">
      <form @submit.prevent="register">
        <div class="con-logo-letter">
          <img src="@/assets/images/logo fav.icon.png" alt="" />
        </div>
        <h1>Sign Up</h1>
        <div class="con-input-form-sign">
          <label for="">Name</label>
          <input
            type="text"
            placeholder="Enter Name"
            v-model="values.name"
            @blur="validate('name')"
            @keypress="validate('name')"
            :class="['form-control', !!errors.name && 'is-invalid']"
          />
          <p
            :class="['form-input-hint', !!errors.name && 'invalid-feedback']"
            v-if="!!errors.name"
          >
            {{ errors.name }}
          </p>
          <i class="fa-regular fa-user"></i>
        </div>
        <div class="con-input-form-sign">
          <label for="">Phone number </label>
          <input
            style="-webkit-appearance: none"
            type="number"
            placeholder="Enter Your Phone Number"
            v-model="values.phone_number"
            @blur="validate('phone_number')"
            @keypress="validate('phone_number')"
            :class="[
              'phone_number form-control',
              !!errors.phone_number && 'is-invalid',
            ]"
          />
          <p
            :class="[
              'form-input-hint',
              !!errors.phone_number && 'invalid-feedback',
            ]"
            v-if="!!errors.phone_number"
          >
            {{ errors.phone_number }}
          </p>
          <i class="fa-solid fa-mobile-screen-button"></i>
        </div>
        <div class="con-input-form-sign">
          <label for="">Education Level</label>
          <select
            v-model="values.education_level_id"
            @blur="validate('education_level_id')"
            @keypress="validate('education_level_id')"
            :class="[!!errors.education_level_id && 'is-invalid']"
          >
            <option value="" selected="true">Choose Education Level</option>
            <option
              v-for="educationLevel in educationLevels"
              :key="educationLevel.id"
              :value="educationLevel.id"
            >
              {{ educationLevel.name }}
            </option></select
          ><i class="fa-solid fa-graduation-cap"></i>
          <p
            :class="[
              'form-input-hint',
              !!errors.education_level_id && 'invalid-feedback',
            ]"
            v-if="!!errors.education_level_id"
          >
            {{ errors.education_level_id }}
          </p>
        </div>
        <div class="con-input-form-sign">
          <label for="">Email</label>
          <input
            type="text"
            v-model="values.email"
            placeholder="Enter Your Email"
            @blur="validate('email')"
            @keypress="validate('email')"
            :class="['form-control', !!errors.email && 'is-invalid']"
          />
          <p
            :class="['form-input-hint', !!errors.email && 'invalid-feedback']"
            v-if="!!errors.email"
          >
            {{ errors.email }}
          </p>
          <i class="fa-regular fa-envelope"></i>
        </div>
        <div class="con-input-form-sign">
          <label for="">Password</label>
          <input
        :type="passwordStatus ? 'text' : 'password'"
              placeholder="Enter Your Password"
              v-model="values.password"
              @blur="validate('password')"
              @keypress="validate('password')"
              :class="['form-control', !!errors.password && 'is-invalid']"
          /> <p
              :class="[
                'form-input-hint',
                !!errors.password && 'invalid-feedback',
              ]"
              v-if="!!errors.password"
            >
              {{ errors.password }}
            </p>
          <i
              v-show="!passwordStatus"
              @click="passwordToggleShow"
            class="fa-regular fa-eye-slash"
          ></i>
          <i  v-show="passwordStatus"
              @click="passwordToggleShow"
            class="fa-regular fa-eye"
          ></i>
        </div>
        <div class="con-input-form-sign">
          <label for="">Confirm Password</label>
          <input :type="passwordConfirmStatus ? 'text' : 'password'"
              placeholder="Enter Your Password Confirmation"
              v-model="values.passwordConfirmation"
              @blur="validate('passwordConfirmation')"
              @keypress="validate('passwordConfirmation')"
              :class="[
                'form-control',
                !!errors.passwordConfirmation && 'is-invalid',
              ]" />
            <p
              :class="[
                'form-input-hint',
                !!errors.passwordConfirmation && 'invalid-feedback',
              ]"
              v-if="!!errors.passwordConfirmation"
            >
              {{ errors.passwordConfirmation }}
            </p><i
              v-show="passwordConfirmStatus"
              @click="passwordConfirmToggleShow"
            class="fa-regular fa-eye"
          ></i>
          <i
              v-show="!passwordConfirmStatus"
              @click="passwordConfirmToggleShow"
            class="fa-regular fa-eye-slash"
          ></i>
        </div>
        <div class="con-input-submit">
          <input type="submit" value="Sign Up " />
        </div>
        <div class="con-i-not-member">
          <p>I already have acount</p>
          <a href="#">Sign In </a>
        </div>
      </form>
    </div>
    <div class="con-sign-in-sign-up-footer">
      <div class="by-who">
        <p>@ By Varskey</p>
      </div>
      <div class="privacy-seacrh">
        <router-link to="/privacy">privacy & policy</router-link>
        <router-link to="/terms">Serch Terms</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as Yup from "yup";

const registerSchema = Yup.object().shape({
  name: Yup.string().required(),
  phone_number: Yup.number().required(),
  education_level_id: Yup.string().required(),
  email: Yup.string().required().email(),
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
export default {
  name: "Register",
  data() {
    return {
      passwordStatus: 0,
      passwordConfirmStatus: 0,
      values: {
        name: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        phone_number: "",
        education_level_id: "",
      },
      errors: {
        name: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        phone_number: "",
        education_level_id: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["educationLevels"]),
  },
  methods: {
    ...mapActions("auth", ["educationLevelApi", "registerApi"]),
    validate(field) {
      registerSchema.validateAt(field, this.values).catch(() => {});
    },
    register: function () {
      registerSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.errors = {};
          let data = {
            name: this.values.name,
            email: this.values.email,
            password: this.values.password,
            phone_number: this.values.phone_number,
            education_level_id: this.values.education_level_id,
          };
          this.registerApi(data)
            .then(() => this.$router.push("/verification-code"))
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },
    passwordToggleShow() {
      this.passwordStatus = !this.passwordStatus;
    },
    passwordConfirmToggleShow() {
      this.passwordConfirmStatus = !this.passwordConfirmStatus;
    },
  },
  beforeMount() {
    this.educationLevelApi();
  },
};
</script>

<style>
.phone_number::-webkit-inner-spin-button,
.phone_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
</style>