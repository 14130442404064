<template>
  <div class="min-con-ins-coure-102">
    <div class="row">
      <div class="col-12">
        <div
          class="
            main-con-to-question-card-exam main-con-to-question-card-exam-2
          "
        >
          <div
            class="main-con-to-question-exam"
            v-for="(question, index) in content.questions"
            :key="question.id"
          >
            <div class="con-header-question-exam">
              <div class="con-question-exam-number-degree">
                <h1>Question {{ index + 1 }}</h1>
                <span>({{ index + 1 }}/{{ content.questions.length }})</span>
              </div>
              <div class="exam-title-1">
                <p>
                  {{ question.question.question }}
                </p>
              </div>
              <div class="con-question-exam-img" v-if="question.question.image">
                <img :src="question.question.image_full_path" alt="" />
              </div>
            </div>
            <div v-if="question.question.type == 'multi_choose'">
              <div
                :class="
                  'con-answer-card-Question question_' +
                  question.question_id +
                  (question.answer_id == answer.id ? ' selected' : '')
                "
                v-for="(answer, index) in question.question.answers"
                :key="answer.id"
                @click="answerQuestion(question.question, answer.id, $event)"
              >
                <span>{{ answers[index] }}</span>
                <p>{{ answer.answer }}</p>
                <img v-if="answer.image" :src="image_full_path" alt="" />
              </div>
            </div>
            <div v-else>
              <div
                :class="
                  'con-answer-card-Question question_' +
                  question.question_id +
                  (question.answer_id == 'true'  ? ' selected' : '')
                "
                @click="answerQuestion(question.question, 'true' , $event)"
              >
                <span>{{ answers[index] }}</span>
                <p>Right</p>
              </div>
              <div
                :class="
                  'con-answer-card-Question question_' +
                  question.question_id +
                  (question.answer_id == 'false'  ? ' selected' : '')
                "
                @click="answerQuestion(question.question, 'false' , $event)"
              >
                <span>{{ answers[index] }}</span>
                <p>Wrong</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ExamExam",
  props: {
    content: Object,
  },
  data: function () {
    return {
      answers: ["A", "B", "C", "D"],
      questionSelectedAnswers: [],
    };
  },
  methods: {
    ...mapActions("lecturePurchased", ["answerQuestionApi"]),
    answerQuestion(item, answer, event) {
      this.answerQuestionApi({ item_id: item.id, answer: answer })
        .then(() => {
          console.log(item);
          document
            .querySelectorAll(".con-answer-card-Question.question_" + item.id)
            .forEach((element) => {
              element.classList.remove("selected");
            });
          event.target.classList.add("selected");
          if (
            document.querySelectorAll(
              ".con-answer-card-Question.question_" + item.id + ".selected"
            ).length == this.content.questions.length
          ) {
            this.$emit("endExamStatus", true);
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    if (
      document.querySelectorAll(".con-answer-card-Question.selected").length ==
      this.content.questions.length
    ) {
      console.log(1);
      this.$emit("endExamStatus", true);
    }
  },
};
</script>