<template>
  <div>
    <header>
      <div class="main-con-for-primary-header">
        <div class="row">
          <div class="col-5 col-md-3">
            <div class="con-logo-website">
              <router-link to="/">
                <img src="@/assets/images/logo horizontal.png" alt=""
              /></router-link>
            </div>
          </div>
          <div class="col-6 disnone-mobile">
            <div class="con-search-box-header" v-if="show">
              <i class="fa-solid fa-magnifying-glass" @click="search"></i>
              <input
                type="text"
                v-model="q"
                v-on:keyup.enter="search"
                placeholder="Search here ....."
              />
            </div>
          </div>
          <div class="col-7 col-md-3">
            <div class="con-header-right">
              <div class="dropdown show notification-drop-down">
                <a
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-regular fa-bell"></i
                  ><span class="number-noti" v-if="Notification.unread_notifications_count != 0 ">{{Notification.unread_notifications_count}}</span></a
                >
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <div class="con-header-noti-drop-down">
                    <p>Notification</p>
                    <router-link to="/notification">See All</router-link>
                  </div>
                  <div v-if="Notification.unread_notifications && Notification.unread_notifications.length >  0 ">
                    <li li v-for="(noti,idexn) in Notification.unread_notifications" :key="idexn">
                    <router-link class="dropdown-item" v-if="noti.read_at == null" :to="noti.type == 'lecture' || noti.type == 'question'? `/lecture/${noti.lecture_id}`: `/show-post/${noti.post_id}`">
                      <div class="con-one-notification" @click.once="makeRead(noti.id,noti.read_at)">
                        <div class="con-img-notification">
                          <img
                            :src="noti.teacher_profile_image"
                            alt=""
                          /><i :class="{'flaticon-play-button':noti.type =='lecture','flaticon-paper-plane':noti.type =='comment','flaticon-question':noti.type =='question'}"></i>
                        </div>
                        <div class="content-of-notification">
                          <p class="title-of-notification">{{noti.teacher_name}}</p>
                          <p class="notification-happen">
                            {{noti.short_description}}
                          </p>
                          <span class="date-of-notification">{{noti.created_at}}</span>
                        </div>
                        <span class="unread" v-if="(noti.read_at == null)"></span>
                      </div>
                    </router-link>
                  </li>
                  </div>
                  <div class="con-empty-noti" v-else>
                    <i class="flaticon-notification-bell"></i>
                    <p> No Notification Avalible Yet</p>

                  </div>
                  <!-- <div class="con-seeAll-noti-but ">
                    <router-link to="/notification"> See All </router-link>
                  </div> -->
                </div>
              </div>
              <div
                class="con-more-icon-setting"
                @click="$router.push('/settings')"
              >
                <i class="fa-solid fa-gear"></i>
              </div>
              <div
                class="con-student-img-header"
                @click="$router.push('/profile')"
              >
                <img :src="user.profile_image_full_path" alt="" />
              </div>
              <div class="con-button-lang">
                <switch-language />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="mobile">
      <ul>
        <li>
          <a href="#" @click="$router.push('/')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="511pt"
              viewBox="0 1 511 511.999"
              width="511pt"
            >
              <path
                d="m498.699219 222.695312c-.015625-.011718-.027344-.027343-.039063-.039062l-208.855468-208.847656c-8.902344-8.90625-20.738282-13.808594-33.328126-13.808594-12.589843 0-24.425781 4.902344-33.332031 13.808594l-208.746093 208.742187c-.070313.070313-.144532.144531-.210938.214844-18.28125 18.386719-18.25 48.21875.089844 66.558594 8.378906 8.382812 19.441406 13.234375 31.273437 13.746093.484375.046876.96875.070313 1.457031.070313h8.320313v153.695313c0 30.417968 24.75 55.164062 55.167969 55.164062h81.710937c8.285157 0 15-6.71875 15-15v-120.5c0-13.878906 11.292969-25.167969 25.171875-25.167969h48.195313c13.878906 0 25.167969 11.289063 25.167969 25.167969v120.5c0 8.28125 6.714843 15 15 15h81.710937c30.421875 0 55.167969-24.746094 55.167969-55.164062v-153.695313h7.71875c12.585937 0 24.421875-4.902344 33.332031-13.8125 18.359375-18.367187 18.367187-48.253906.027344-66.632813zm-21.242188 45.421876c-3.238281 3.238281-7.542969 5.023437-12.117187 5.023437h-22.71875c-8.285156 0-15 6.714844-15 15v168.695313c0 13.875-11.289063 25.164062-25.167969 25.164062h-66.710937v-105.5c0-30.417969-24.746094-55.167969-55.167969-55.167969h-48.195313c-30.421875 0-55.171875 24.75-55.171875 55.167969v105.5h-66.710937c-13.875 0-25.167969-11.289062-25.167969-25.164062v-168.695313c0-8.285156-6.714844-15-15-15h-22.328125c-.234375-.015625-.464844-.027344-.703125-.03125-4.46875-.078125-8.660156-1.851563-11.800781-4.996094-6.679688-6.679687-6.679688-17.550781 0-24.234375.003906 0 .003906-.003906.007812-.007812l.011719-.011719 208.847656-208.839844c3.234375-3.238281 7.535157-5.019531 12.113281-5.019531 4.574219 0 8.875 1.78125 12.113282 5.019531l208.800781 208.796875c.03125.03125.066406.0625.097656.09375 6.644531 6.691406 6.632813 17.539063-.03125 24.207032zm0 0"
              ></path></svg
            ><span>Home</span></a
          >
        </li>
        <li>
          <a href="#" @click="$router.push('/search')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 512.005 512.005"
              style="enable-background: new 0 0 512.005 512.005"
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667    S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6    c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z     M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z"
                  ></path>
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g></svg
            ><span>Search</span></a
          >
        </li>
        <li>
          <a href="#" @click="$router.push('/list-my-lectures')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="512pt"
              viewBox="0 0 512 512"
              width="512pt"
            >
              <path
                d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.640625-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.640625 74.980469 181.019531 0 50.53125-14.996094 99.675781-43.375 142.117188-6.136719 9.183593-18.558594 11.648437-27.742188 5.511718-9.179687-6.140625-11.648437-18.5625-5.507812-27.742187 23.960938-35.84375 36.625-77.296875 36.625-119.886719 0-119.101562-96.898438-216-216-216s-216 96.898438-216 216 96.898438 216 216 216c39.503906 0 78.144531-10.757812 111.738281-31.105469 9.445313-5.722656 21.742188-2.703125 27.46875 6.746094 5.722657 9.445313 2.703125 21.746094-6.746093 27.464844-39.84375 24.136719-85.648438 36.894531-132.460938 36.894531zm-21.742188-161.707031 99.703126-57.761719c13.171874-7.632812 21.039062-21.289062 21.039062-36.53125s-7.867188-28.898438-21.042969-36.53125l-99.699219-57.761719c-13.183593-7.636719-28.9375-7.65625-42.136718-.046875-13.226563 7.621094-21.121094 21.296875-21.121094 36.578125v115.523438c0 15.28125 7.894531 28.957031 21.121094 36.578125 6.589844 3.796875 13.808594 5.691406 21.027344 5.691406 7.25.003906 14.503906-1.910156 21.109374-5.738281zm-20.050781-153.976563 99.699219 57.761719c.300781.175781 1.09375.632813 1.09375 1.921875s-.792969 1.746094-1.09375 1.921875l-99.699219 57.761719c-.316406.179687-1.054687.609375-2.109375 0-1.097656-.632813-1.097656-1.535156-1.097656-1.921875v-115.523438c0-.386719 0-1.289062 1.097656-1.921875.40625-.234375.765625-.3125 1.070313-.3125.488281-.003906.84375.203125 1.039062.3125zm0 0"
              ></path></svg
            ><span>Lecture</span></a
          >
        </li>
        <li>
          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="511pt"
              viewBox="0 1 511 511.999"
              width="511pt"
            >
              <path
                d="m498.699219 222.695312c-.015625-.011718-.027344-.027343-.039063-.039062l-208.855468-208.847656c-8.902344-8.90625-20.738282-13.808594-33.328126-13.808594-12.589843 0-24.425781 4.902344-33.332031 13.808594l-208.746093 208.742187c-.070313.070313-.144532.144531-.210938.214844-18.28125 18.386719-18.25 48.21875.089844 66.558594 8.378906 8.382812 19.441406 13.234375 31.273437 13.746093.484375.046876.96875.070313 1.457031.070313h8.320313v153.695313c0 30.417968 24.75 55.164062 55.167969 55.164062h81.710937c8.285157 0 15-6.71875 15-15v-120.5c0-13.878906 11.292969-25.167969 25.171875-25.167969h48.195313c13.878906 0 25.167969 11.289063 25.167969 25.167969v120.5c0 8.28125 6.714843 15 15 15h81.710937c30.421875 0 55.167969-24.746094 55.167969-55.164062v-153.695313h7.71875c12.585937 0 24.421875-4.902344 33.332031-13.8125 18.359375-18.367187 18.367187-48.253906.027344-66.632813zm-21.242188 45.421876c-3.238281 3.238281-7.542969 5.023437-12.117187 5.023437h-22.71875c-8.285156 0-15 6.714844-15 15v168.695313c0 13.875-11.289063 25.164062-25.167969 25.164062h-66.710937v-105.5c0-30.417969-24.746094-55.167969-55.167969-55.167969h-48.195313c-30.421875 0-55.171875 24.75-55.171875 55.167969v105.5h-66.710937c-13.875 0-25.167969-11.289062-25.167969-25.164062v-168.695313c0-8.285156-6.714844-15-15-15h-22.328125c-.234375-.015625-.464844-.027344-.703125-.03125-4.46875-.078125-8.660156-1.851563-11.800781-4.996094-6.679688-6.679687-6.679688-17.550781 0-24.234375.003906 0 .003906-.003906.007812-.007812l.011719-.011719 208.847656-208.839844c3.234375-3.238281 7.535157-5.019531 12.113281-5.019531 4.574219 0 8.875 1.78125 12.113282 5.019531l208.800781 208.796875c.03125.03125.066406.0625.097656.09375 6.644531 6.691406 6.632813 17.539063-.03125 24.207032zm0 0"
              ></path></svg
            ><span>Unknown </span></a
          >
        </li>
        <li>
          <a href="#" @click="$router.push('/settings')">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              height="512"
              viewBox="0 0 32 32"
              width="512"
              data-name="Layer 1"
            >
              <path
                d="m12 15h-6a3 3 0 0 1 -3-3v-6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3zm-6-10a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1z"
              ></path>
              <path
                d="m26 15h-6a3 3 0 0 1 -3-3v-6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3zm-6-10a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1z"
              ></path>
              <path
                d="m12 29h-6a3 3 0 0 1 -3-3v-6a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3zm-6-10a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1z"
              ></path>
              <path
                d="m23 29a6 6 0 1 1 6-6 6.006 6.006 0 0 1 -6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0 -4-4z"
              ></path></svg
            ><span>More</span></a
          >
        </li>
      </ul>
    </header>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SwitchLanguage from "@/components/SwitchLanguage.vue";
import axios from "@/axiosConfig";
export default {
  name: "AppHeader",
  components: {
    SwitchLanguage,
  },
  data() {
    return {
      show: true,
      q: "",
      Notification: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    $route() {
      this.checkCurrentRoute();
    },
  },
  methods: {
    checkCurrentRoute() {
      this.q = "";
      if (this.$route.name == "Search") {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    search() {
      if (this.q) {
        this.$router.push({ name: "Search", query: { q: this.q } });
      } else {
        this.$router.push("/search");
      }
    },
    getNotification() {
      axios.get(`/notifications`).then((response) => {
        this.Notification = response.data.data;
        
      });
    },
    makeRead(id ){
      axios.post(`/notification-mark-as-read/${id}`).then((res)=>{
        console.log(this.Notification.unread_notifications.filter((e) => e.id == id)[0]['read_at'], "sssss",res.data.data);
        this.Notification.unread_notifications.filter((e) => e.id == id)[0]['read_at'] = res.data.data;
        
      });
      this.Notification.unread_notifications_count--
    }
  },
  beforeMount() {
    this.checkCurrentRoute();
    this.getNotification();
  },
};
</script>
<style scoped>
.con-header-noti-drop-down{
  display: flex;
  align-items:center;
  justify-content: space-between;
  padding: 10px 10px ;
}
.con-header-noti-drop-down p{
  font-size:15px;
  color: #2d2d2d; 
  margin-bottom: 0px;
  font-weight: 700;

}
.con-header-noti-drop-down a{
  font-size:13px;
  color: #888da8; 
  margin-bottom: 0px;
  font-weight: 500;
  
}
.con-empty-noti{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.con-empty-noti i{
  font-size: 70px;
  color: #888da8;
  margin-bottom: 10px;
  display: flex;
}
.con-empty-noti p{
  font-size: 17px;
  color: #2d2d2d;
  font-weight: 700;
  margin-bottom: 0px;
}
</style>