import axios from "@/axiosConfig";

export default ({
  state:{
    exam : [],
  },
  actions: {
    getExamResultApi({commit}, id){
      return new Promise((resolve, reject) => {
        axios.get('/lectures/exam-results/' +  id)
        .then(response => {
          console.log(response);
          commit('SET_EXAM', response);
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
  },
  mutations: {
    SET_EXAM(state, response){
      state.exam = response.data.data.exam;
    },
  },
  getters : {
    exam: state => state.exam,
  },
  namespaced: true,
});
