<template>
  <div
    v-if="!showMobileTopLecturesAndFollowTeacher && !post.is_reported_by_student"
    class="con-post-lecture-card" 
  >
    <PostHeader :post="post"></PostHeader>
    <LecturePost v-if="post.type == 'lecture'" :post="post"></LecturePost>
    <NormalPost v-else-if="post.type == 'normal'" :post="post"></NormalPost>
    <PollPost v-else-if="post.type == 'poll'" :post="post"></PollPost>
    <VideoPost v-else-if="post.type == 'video'" :post="post"></VideoPost>
    <QuestionPost
      v-else-if="post.type == 'question'"
      :post="post"
    ></QuestionPost>
    <PostFooter v-if="post" :post="post" :showComments="showComments"></PostFooter>
  </div>
  <div v-else-if="(showMobileTopLecturesAndFollowTeacher && !post.is_reported_by_student)">
    <div class="con-post-lecture-card" v-if="!post.is_reported_by_student">
      <PostHeader :post="post"></PostHeader>
      <LecturePost v-if="post.type == 'lecture'" :post="post"></LecturePost>
      <NormalPost v-else-if="post.type == 'normal'" :post="post"></NormalPost>
      <PollPost v-else-if="post.type == 'poll'" :post="post"></PollPost>
      <VideoPost v-else-if="post.type == 'video'" :post="post"></VideoPost>
      <QuestionPost
        v-else-if="post.type == 'question'"
        :post="post"
      ></QuestionPost>
      <PostFooter v-if="post" :post="post"  :showComments="showComments"></PostFooter>
    </div>
    <div class="main-con-to-follow-card-mobile-1">
      <div class="con-header-mobile-follow-teacher">
        <h1>Suggestion Teacher</h1>
        <router-link to="/list-all-teachers">See All</router-link>
      </div>
      <carousel class="owl-carousel-customized-3" :nav="false" :items="1">
        <div class="item" v-for="teacher in teachersToFollow" :key="teacher.id">
          <div
            class="con-follow-card-mobile"
          >
            <img :src="teacher.profile_image"  @click="$router.push('/teacher/' + teacher.id)" alt="" /><a
              href="#"
              @click="$router.push('/teacher/' + teacher.id)"
            >
              {{ teacher.name }}
            </a>
            <p>{{ teacher.subject_name }}</p>
            <div class="con-card-follow-rateing">
              <i class="fa-solid fa-star"></i><span>(3.5)</span>
            </div>
            <button
              class="unfollw"
              v-if="teacher.is_followed"
              v-on:click.prevent="followTeacher(teacher.id)"
            >
              unfollow
            </button>
            <button v-else v-on:click.prevent="followTeacher(teacher.id)">
              Follow
            </button>
          </div>
        </div>
      </carousel>
    </div>
    <div class="main-con-top-lecture-mobile-card">
      <div class="con-header-mobile-follow-teacher">
        <h1>Top Lecture</h1>
        <router-link to="/list-top-lectures">See All</router-link>
      </div>
      <carousel class="owl-carousel-customized-3" :nav="false" :items="1">
        <div
          class="item"
          v-for="lecture in topLectures"
          :key="lecture.lecture_id"
        >
          <div
            class="con-top-lecture-mobile-card"
            @click="$router.push('/lecture/' + lecture.lecture_id)"
          >
            <img :src="lecture.lecture_image" alt="" />
            <p>{{ lecture.lecture_title }}</p>
            <span>{{ lecture.lecture_subscription_fees }}</span>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NormalPost from "./partials/types/NormalPost.vue";
import LecturePost from "./partials/types/LecturePost.vue";
import PollPost from "./partials/types/PollPost.vue";
import QuestionPost from "./partials/types/QuestionPost.vue";
import PostHeader from "./partials/PostHeader.vue";
import PostFooter from "./partials/PostFooter.vue";
import VideoPost from "./partials/types/VideoPost.vue";
import carousel from "vue-owl-carousel";
export default {
  name: "Post",
  components: {
    PostHeader,
    PostFooter,
    LecturePost,
    NormalPost,
    PollPost,
    QuestionPost,
    carousel,
    VideoPost
  },
  props: {
    post: Object,
    showComments: Boolean,
    showMobileTopLecturesAndFollowTeacher: Boolean,
  },
  computed: {
    ...mapGetters("home", ["topLectures", "teachersToFollow"]),
  },

  methods: {
    ...mapActions("home", [
      "topLecturesApi",
      "getTeachersToFollowApi",
      "followTeacherApi",
    ]),
    followTeacher(id) {
      this.followTeacherApi(id).then(() => {
        const itemIndex = this.teachersToFollow.findIndex((x) => x.id === id);
        this.teachersToFollow[itemIndex]["is_followed"] = 1;
      });
    },
  },
  beforeMount() {
    if (this.showMobileTopLecturesAndFollowTeacher) {
      this.topLecturesApi();
      this.getTeachersToFollowApi();
    }
  },
};
</script>
<style scoped>
.item {
  margin-left: 10px;
}
</style>