import axios from "@/axiosConfig";

export default {
  state: {
    post: {},
    comments: [],
    nextPage: 0,
    isLastPageComment: 0,
    reportsTypes: [],
  },
  actions: {
    // ratePostApi(_, data) {
    //   axios.post('/post-rate/' + data.postId, {student_rate : data.student_rate})
    //     .then(response => {
    //       console.log(response);
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // },
    likePostApi(_, postId) {
      axios
        .post("/post-like/" + postId)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reportTypesApi({ commit }) {
      axios.get(`/report-types`).then((response) => {
        commit("SET_REPORT_TYPES", response);
      });
    },
    disLikePostApi(_, postId) {
      axios
        .post("/post-dislike/" + postId)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    savePostApi(_, postId) {
      axios
        .post("/post-save/" + postId)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    askQuestionApi(_, data) {
      axios
        .post("/lecture-questions", data)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addReplyApi(_, data) {
      return new Promise((resolve) => {
        axios
          .post(
            "/lecture-questions/reply/" +
              data.post_id +
              "/" +
              data["question_id"],
            data.body
          )
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      
    },
    getCommentsApi({ commit}, data) {
      console.log(data);
      var page = (data.nextPage)? "?page=" + data.nextPage : '';
      return new Promise((resolve, reject) => {
        axios
          .get("/lecture-questions/comments/" + data.post_id + page)
          .then((response) => {
            commit("SET_COMMENTS", response);
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    clearComments({ commit }) {
      commit("CLEAR_COMMENTS");
    },
    selectPostOptionApi(_, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/select-post-option/" + data.postId + "/" + data.optionId)
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    showLecturePostApi({ commit }, postId) {
      return new Promise((resolve, reject) => {
        axios
          .get("/posts/" + postId)
          .then((response) => {
            commit("SET_POST", response);
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
  },
  mutations: {
    SET_COMMENTS(state, response) {
      state.comments = [];
      response.data.data.forEach((element) => {
        state.comments.push(element);
      });
      state.nextPage =
        response.data.pagination.current_page + 1 <=
        response.data.pagination.total
          ? response.data.pagination.current_page + 1
          : response.data.pagination.total;
      state.isLastPageComment =
        response.data.pagination.current_page ==
        response.data.pagination.last_page
          ? 1
          : 0;
    },
    SET_REPORT_TYPES(state, response) {
      state.reportsTypes = response.data.data;
    },
    SET_TEACHERS_TO_FOLLOW(state, response) {
      state.teachersToFollow = response.data.data;
    },
    SET_TOP_LECTURES(state, response) {
      state.topLectures = response.data.data;
    },
    CLEAR_COMMENTS(state) {
      state.comments = [];
    },
    SET_POST(state, response) {
      state.post = response.data.data;
    },
  },
  getters: {
    post: (state) => state.post,
    comments: (state) => state.comments,
    nextPage: (state) => state.nextPage,
    teachersToFollow: (state) => state.teachersToFollow,
    topLectures: (state) => state.topLectures,
    isLastPageComment: (state) => state.isLastPageComment,
    getReportTypes: (state) => state.reportsTypes,
  },
  namespaced: true,
};
