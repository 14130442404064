import axios from "@/axiosConfig";

const checkSearchString = (q) => {
  
  let type = '/normal';
  if (typeof q === 'object' && q !== null) {
    type = '/hashtag';
    q = q.tagId;
  }
  return { q, type }
}

export default ({
  state: {
    teachers: [],
    posts: [],
    nextPage: 0
  },
  actions: {
    getPostsApi({ commit }, q) {
      var filter = checkSearchString(q);
      axios.get(`/search/posts/${filter.q}${filter.type}`)
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getLecturesApi({ commit }, q) {
      var filter = checkSearchString(q);
      axios.get(`/search/lectures/${filter.q}${filter.type}`)
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getQuestionsApi({ commit }, q) {
      var filter = checkSearchString(q);
      axios.get(`/search/questions/${filter.q}${filter.type}`)
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getPollsApi({ commit }, q) {
      var filter = checkSearchString(q);
      axios.get(`/search/polls/${filter.q}${filter.type}`)
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getTeachersApi({ commit }, q) {
      var filter = checkSearchString(q);
      axios.get(`/search/teachers/${filter.q}${filter.type}`)
        .then(response => {
          console.log(response);
          commit('SET_TEACHERS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getTagsApi({ commit }) {
      axios.get('/tags')
        .then(response => {
          console.log(response);
          commit('SET_TAGS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  mutations: {
    SET_TEACHERS(state, response) {
      state.teachers = response.data.data;
      state.nextPage = ((response.data.pagination.current_page + 1) <= response.data.pagination.total) ? response.data.pagination.current_page + 1 : response.data.pagination.total;
    },
    SET_POSTS(state, response) {
      state.posts = response.data.data;
      state.nextPage = ((response.data.pagination.current_page + 1) <= response.data.pagination.total) ? response.data.pagination.current_page + 1 : response.data.pagination.total;
    },
    SET_TAGS(state, response) {
      state.tags = response.data.data;
    },
  },
  getters: {
    teachers: state => state.teachers,
    posts: state => state.posts,
    nextPage: state => state.nextPage,
    tags: state => state.tags,
  },
  namespaced: true,
});
