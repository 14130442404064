<template>
  <div>
    <div class="con-post-img" v-bind:style="{background:`url( ${post.attachment_full_path}) no-repeat center center `}">
      
      <div class="post-price-style-3"> {{post.subscription_fees}}</div>
    </div>
    <div class="con-bottom-post-bar">
        <p>{{post.title}} </p>
        <button
        @click="$router.push('/lecture/' + post.lecture_id)"
        > {{post.is_purchased? 'Show Now':'Buy Now'}}</button>

      </div>
    <div class="con-post-content">
      <!-- <h1>{{ post.title }}</h1> -->
      <p>
        {{ post.body }}
      </p>
    </div>
    <post-tags :post="post"></post-tags>
    <!-- <div class="con-bar-post-action-price">
      <div class="con-price">
        <p>Price :</p>
        <span> {{ post.subscription_fees }}</span>
      </div> -->
      <!-- <a
        v-if="post.is_purchased"
        class="con-postg-action"
        href="#"
        @click="$router.push('/lecture/' + post.lecture_id)"
        >Show Now</a
      >
      <a
        v-else
        class="con-postg-action"
        href="#"
        @click="$router.push('/lecture/' + post.lecture_id)"
        >Buy Now</a
      > -->
    <!-- </div> -->
  </div>
</template>

<script>
import PostTags from "../PostTags.vue";
export default {
  components: { PostTags },
  name: "LecturePost",
  props: {
    post: Object,
  },
  
};
</script>