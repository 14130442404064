<template>
  <!-- <div class="container-fluid con-flu-456 con-flu-457 con-flu-4231">
    <img
      src="@/assets/img/tms empty.png"
      alt=""
      class="img-sign-up-096 img-sign-up-022"
    />
    <div class="container con-9020 con-9021 con-9033">
      <div class="con-563-sign-up">
        <div class="container">
          <h1>Login</h1>

          <form class="login" @submit.prevent="login">
            <div class="new-form-237">
              <input
                type="text"
                v-model="values.email"
                placeholder="Enter Your Email"
                @blur="validate('email')"
                @keypress="validate('email')"
                :class="['form-control', !!errors.email && 'is-invalid']"
              />
              <p
                :class="[
                  'form-input-hint',
                  !!errors.email && 'invalid-feedback',
                ]"
                v-if="!!errors.email"
              >
                {{ errors.email }}
              </p>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-envelope-fill-11"
                viewBox="0 0 16 16"
              >
                <path
                  d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"
                />
              </svg>
            </div>
            <div class="new-form-237">
              <input
                :type="passwordStatus ? 'text' : 'password'"
                v-model="values.password"
                placeholder="Enter Your password"
                class="form-input"
                @blur="validate('password')"
                @keypress="validate('password')"
                :class="['form-control', !!errors.password && 'is-invalid']"
              />
              <p
                :class="[
                  'form-input-hint',
                  !!errors.password && 'invalid-feedback',
                ]"
                v-if="!!errors.password"
              >
                {{ errors.password }}
              </p>
              <svg
                v-show="passwordStatus"
                @click="passwordToggleShow"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-eye-fill-113"
                viewBox="0 0 16 16"
              >
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path
                  d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                />
              </svg>
              <svg
                v-show="!passwordStatus"
                @click="passwordToggleShow"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-eye-slash-fill-113"
                viewBox="0 0 16 16"
              >
                <path
                  d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"
                />
                <path
                  d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"
                />
              </svg>
            </div>

            <router-link class="for-pass-a-112" to="/forget-password"
              >Forget Password?</router-link
            >

            <input type="submit" value="" id="submit-5843" />
            <label for="submit-5843" class="submit-5843 submit-58223">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="white"
                class="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </label>
          </form>
        </div>
      </div>
      <div class="con-box-you-have-acc">
        <p>Don:t have an account?</p>
        <router-link to="/register">Register</router-link>
      </div>
    </div>
  </div> -->
  <div>
    <div class="banner-sign-page"></div>
    <div class="main-con-to-sign-form">
      <form class="login" @submit.prevent="login">
        <div class="con-logo-letter">
          <img src="@/assets/images/logo fav.icon.png" alt="" />
        </div>
        <h1> {{
          $t("login")
        }}</h1>
        <div class="con-input-form-sign">
          <label for="">Email</label>
          <input
            type="email"
            v-model="values.email"
            placeholder="Enter Your Email"
            @blur="validate('email')"
            @keypress="validate('email')"
            :class="['form-control', !!errors.email && 'is-invalid']"
          />
          <p
            :class="['form-input-hint', !!errors.email && 'invalid-feedback']"
            v-if="!!errors.email"
          >
            {{ errors.email }}
          </p>
          <i class="fa-regular fa-envelope"></i>
        </div>
        <div class="con-input-form-sign">
          <label for="">Password</label>
          <input
            :type="passwordStatus ? 'text' : 'password'"
            v-model="values.password"
            placeholder="Enter Your password"
            class="form-input"
            @blur="validate('password')"
            @keypress="validate('password')"
            :class="['form-control', !!errors.password && 'is-invalid']"
          />
          <p
            :class="[
              'form-input-hint',
              !!errors.password && 'invalid-feedback',
            ]"
            v-if="!!errors.password"
          >
            {{ errors.password }}
          </p>
          <i
            v-show="passwordStatus"
            @click="passwordToggleShow"
            class="fa-regular fa-eye"
          ></i>
          <i
            v-show="!passwordStatus"
            @click="passwordToggleShow"
            class="fa-regular fa-eye-slash"
          ></i>
        </div>
        <div class="con-forget-password-remmber-me">
          <div class="con-remmber-me">
            <input
              class="remmber-me-class"
              type="checkbox"
              name=""
              id="remmber-me-id"
            />
            <label for="remmber-me-id">
              <i class="fa-solid fa-check"></i>
              <p>Remmber Me</p>
            </label>
          </div>
          <!-- <a href="#">Forget Password</a> -->
           <router-link class="for-pass-a-112" to="/forget-password"
              >Forget Password?</router-link
            >
        </div>
        <div class="con-input-submit">
          <input type="submit" value="Sign In" />
        </div>
        <!-- <p class="p-seprator">Or Continue With</p> -->
        <!-- <div class="con-social-media-icon">
          <img src="images/facebook.png" alt="" /><img
            src="images/google-plus.png"
            alt=""
          /><img src="images/twitter.png" alt="" />
        </div> -->
        <div class="con-i-not-member">
          <p>I am n't member</p>
          <!-- <a href="#">Sign Up</a> -->
          <router-link to="/register">Register</router-link>
        </div>
      </form>
    </div>
    <div class="con-sign-in-sign-up-footer">
      <div class="by-who">
        <p>powerd by Varskey</p>
      </div>
      <div class="privacy-seacrh">
        <router-link to="/privacy">privacy & policy</router-link>
        <router-link to="/termsandconditions">Terms & Conditions</router-link>
        <router-link to="/terms">Serch Terms</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as Yup from "yup";

const loginSchema = Yup.object().shape({
  email: Yup.string().required().email(),
  password: Yup.string().required(),
});

export default {
  name: "Login",
  data() {
    return {
      passwordStatus: 0,
      values: {
        email: "",
        password: "",
      },
      errors: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["loginApi"]),
    validate(field) {
      loginSchema.validateAt(field, this.values).catch(() => {});
    },
    login: function () {
      loginSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.errors = {};
          let email = this.values.email;
          let password = this.values.password;
          this.loginApi({ email, password })
            .then(() => this.$router.push("/"))
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },
    passwordToggleShow() {
      this.passwordStatus = !this.passwordStatus;
    },
  },
};
</script>