<template>
  <div class="min-con-ins-coure-102">
    <div class="main-con-files">
      <!-- <div class="con-file-card-1">
        <img src="images/pdf.png" alt="" />
        <div class="con-file-card-1-content">
          <p>file 1 of lecture 1</p>
          <span>content 1 of lecture 1</span>
        </div>
        <button><img src="images/download (122).png" alt="" />Download</button>
      </div>
      <div class="con-file-card-1">
        <img src="images/doc (1).png" alt="" />
        <div class="con-file-card-1-content">
          <p>file 1 of lecture 1</p>
          <span>content 1 of lecture 1</span>
        </div>
        <button><img src="images/download (122).png" alt="" />Download</button>
      </div>
      <div class="con-file-card-1">
        <img src="images/xls (1).png" alt="" />
        <div class="con-file-card-1-content">
          <p>file 1 of lecture 1</p>
          <span>content 1 of lecture 1</span>
        </div>
        <button><img src="images/download (122).png" alt="" />Download</button>
      </div> -->
      <div class="con-file-card-1">
        <img
          :src="currentContent.content.content.file_image_full_path"
          alt=""
        />
        <div class="con-file-card-1-content">
          <p>{{ currentContent.content.content.file }}</p>
          <span>{{ currentContent.content.content.title }}</span>
        </div>
        <button @click.prevent="downloadItem(currentContent.content.content.file_full_path)">
          <img src="@/assets/images/download (122).png" alt="" />Download
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "File",
  computed: {
    ...mapGetters("lecturePurchased", ["currentContent"]),
  },
  methods: {
    downloadItem(url) {
      window.location.href=url;
    },
  },
};
</script>