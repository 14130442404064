<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <ul class="search-ul-page-bar">
          <li>
            <div class="con-search-box-page">
              <i
                class="fa-solid fa-magnifying-glass"
                type="submit"
                @click="search"
              ></i>
              <input
                type="text"
                placeholder="Search here ......."
                v-model="q"
                v-on:keyup.enter="search"
                @input="showDropdownChange"
              />
            </div>
            <ul>
              <li
                @click="searchBytag(tag.id, tag.tag)"
                v-for="(tag, index) in filteredTags"
                :key="index"
              >
                <i class="fa-solid fa-magnifying-glass"></i
                ><span>{{ tag.tag }}</span>
              </li>
            </ul>
            <div class="dropdown">
              <div
                id="myDropdown"
                :class="'dropdown-content ' + (showDropdown ? 'show' : '')"
              >
                <a href="#" @click="search"
                  ><i class="fa fa-search"></i> {{ q }}</a
                >
                <a
                  href="#"
                  @click="searchBytag(tag.id, tag.tag)"
                  v-for="(tag, index) in filteredTags"
                  :key="index"
                  >{{ tag.tag }}</a
                >
              </div>
            </div>
          </li>
        </ul>
        <div class="row">
          <div class="col-12 col-lg-8">
            <ul class="ul-profile-teacher-tabs">
              <li
                class="active"
                @click.prevent="getPosts($event)"
                data-cont=".con-tabs-profile-all"
              >
                Posts
              </li>
              <li
                @click.prevent="getLectures($event)"
                data-cont=".con-tabs-profile-lecture"
              >
                Lectures
              </li>
              <li
                @click.prevent="getQuestions($event)"
                data-cont=".con-tabs-profile-my-lecture"
              >
                Questions
              </li>
              <li
                @click.prevent="getPolls($event)"
                data-cont=".con-tabs-profile-saved"
              >
                Polls
              </li>
              <li
                @click.prevent="getTeachers($event)"
                data-cont=".con-tabs-profile-liked"
              >
                Teachers
              </li>
            </ul>
            <div
              class="main-con-to-posts main-con-to-posts-profile-teacher"
              v-if="selectedType != 'teachers'"
            >
              <timeline
                :emptyCase="emptyCase"
                :posts="posts"
                :showMobileTopLecturesAndFollowTeacher="false"
              ></timeline>
            </div>
            <div v-else-if="selectedType == 'teachers'">
              <div class="row">
                <div
                  class="col-12 col-sm-6 col-md-4 col-lg-3"
                  v-for="teacher in teachers"
                  :key="teacher.id"
                >
                  <div class="con-teacher-card">
                    <img
                      @click="$router.push('/teacher/' + teacher.id)"
                      :src="teacher.profile_image"
                      alt=""
                    /><span> <i class="fa-solid fa-star"></i>(3,5)</span>
                    <p
                      class="teacher-card-name"
                      @click="$router.push('/teacher/' + teacher.id)"
                    >
                      {{ teacher.name }}
                    </p>
                    <p class="teacher-card-department">
                      {{ teacher.subject_name }}
                    </p>
                    <button
                      class="unfollw"
                      v-if="teacher.is_followed"
                      v-on:click.prevent="followTeacher(teacher.id)"
                    >
                      unfollow
                    </button>
                    <button
                      v-else
                      v-on:click.prevent="followTeacher(teacher.id)"
                    >
                      Follow
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="main-con-to-side-bar">
              <teacher-to-follow></teacher-to-follow>
              <top-lectures :topLectures="topLectures"></top-lectures>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TopLectures from "@/components/sidebar/TopLectures.vue";
import Timeline from "@/components/timeline/Timeline.vue";
import TeacherToFollow from "../components/sidebar/TeacherToFollow.vue";

export default {
  components: { Timeline, TopLectures, TeacherToFollow },
  name: "Search",
  data() {
    return {
      q: "",
      showDropdown: false,
      filteredTags: [],
      selectedType: "posts",
      emptyCase: {
        // 'image' : 'img/4170023.png',
        firstTitle: "No Result Found",
        secondTitle:
          "Sorry We did not found any result for your seach please search again",
      },
    };
  },
  computed: {
    ...mapGetters("search", ["teachers", "posts", "tags"]),
    ...mapGetters("home", ["topLectures"]),
  },
  methods: {
    ...mapActions("search", [
      "getPostsApi",
      "getLecturesApi",
      "getQuestionsApi",
      "getPollsApi",
      "getTeachersApi",
      "getTagsApi",
    ]),
    ...mapActions("listAllTeachers", ["followTeacherApi"]),
    ...mapActions("home", ["topLecturesApi"]),
    followTeacher(id) {
      this.followTeacherApi(id).then(() => {
        const itemIndex = this.teachers.findIndex((x) => x.id === id);
        if (this.teachers[itemIndex]["is_followed"]) {
          this.teachers[itemIndex]["is_followed"] = 0;
        } else {
          this.teachers[itemIndex]["is_followed"] = 1;
        }
      });
    },
    removeActiveClass(event) {
      for (const li of document.querySelectorAll(
        ".ul-profile-teacher-tabs li.active"
      )) {
        li.classList.remove("active");
      }
      event.target.classList.add("active");
    },
    getPosts(event) {
      this.removeActiveClass(event);
      this.selectedType = "posts";
      this.search(event);
    },
    getLectures(event) {
      this.removeActiveClass(event);
      this.selectedType = "lectures";
      this.search(event);
    },
    getQuestions(event) {
      this.removeActiveClass(event);
      this.selectedType = "questions";
      this.search(event);
    },
    getPolls(event) {
      this.removeActiveClass(event);
      this.selectedType = "polls";
      this.search(event);
    },
    getTeachers(event) {
      this.removeActiveClass(event);
      this.selectedType = "teachers";
      this.search();
    },
    search() {
      this.showDropdown = false;
      if (this.q) {
        if (this.selectedType == "posts") {
          this.getPostsApi(this.q);
        } else if (this.selectedType == "lectures") {
          this.getLecturesApi(this.q);
        } else if (this.selectedType == "questions") {
          this.getQuestionsApi(this.q);
        } else if (this.selectedType == "polls") {
          this.getPollsApi(this.q);
        } else if (this.selectedType == "teachers") {
          this.getTeachersApi(this.q);
        }
      }
    },
    searchBytag(tagId, tagTag) {
      this.q = tagTag;
      this.showDropdown = false;
      if (this.selectedType == "posts") {
        this.getPostsApi({ tagId: tagId, type: "hashtag" });
      } else if (this.selectedType == "lectures") {
        this.getLecturesApi({ tagId: tagId, type: "hashtag" });
      } else if (this.selectedType == "questions") {
        this.getQuestionsApi({ tagId: tagId, type: "hashtag" });
      } else if (this.selectedType == "polls") {
        this.getPollsApi({ tagId: tagId, type: "hashtag" });
      } else if (this.selectedType == "teachers") {
        this.getTeachersApi({ tagId: tagId, type: "hashtag" });
      }
    },
    showDropdownChange() {
      if (this.q) {
        this.filteredTags = this.tags.filter((o) => o["tag"].includes(this.q));
        this.showDropdown = true;
      } else {
        this.showDropdown = false;
      }
    },
    close() {
      this.showDropdown = false;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    checkUrlSearchQuery() {
      if (this.$route.query.q && this.$route.query.tag) {
        this.q = this.$route.query.q;
        this.searchBytag(this.$route.query.tag, this.$route.query.q);
      }else{
        this.q = this.$route.query.q;
        this.search();
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  beforeMount() {
    this.getTagsApi();
    this.checkUrlSearchQuery();
    this.topLecturesApi();
  },
};
</script>

<style>
.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdownMain {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  /* width: fit-content; */
  margin: auto;
}
.dropdown {
  top: 2px;
  position: relative;
  z-index: 9;
}

.dropdown-content {
  display: none;
  background-color: white;
  padding: 10px 0px 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px #d5d5d545;
  /* margin: 10px 0px; */
  width: 100%;
  position: absolute;
}

.dropdown-content a {
  color: black;
  padding: 12px 0px 12px 10px;
  text-decoration: none;
  display: block;
  width: 100%;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
.searchMain {
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px #d5d5d545;
  margin: 10px 0px;
}
.bi-search-30 {
  fill: #444;
}
</style>