import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Lecture from "../views/Lecture/Lecture.vue";
import LecturePurchased from "../views/Lecture/LecturePurchased.vue";
import LectureExamResults from "../views/Lecture/LectureExamResults.vue";
import Login from "../views/Auth/Login.vue";
import Register from "../views/Auth/Register.vue";
import ForgetPassword from "../views/Auth/ForgetPassword.vue";
import ResetPassword from "../views/Auth/ResetPassword.vue";
import Token from "../views/Auth/Token.vue";
import Teacher from "../views/Teacher/TeacherProfile.vue";
import ListAllTeachers from "../views/Teacher/ListAllTeachers.vue";
import ListTopLectures from "../views/ListTopLectures.vue";
import ShowLecturePost from "../views/Post/ShowLecturePost.vue";
import Profile from "../views/Profile/Profile.vue";
import ProfileEdit from "../views/Profile/ProfileEdit.vue";
import Settings from "../views/Settings/Settings.vue";
import ListMyLecture from "../views/Settings/ListMyLecture.vue";
import ListMyExams from "../views/Settings/ListMyExams.vue";
import ListMyWallet from "../views/Settings/ListMyWallet.vue";
import Search from "../views/Search.vue";
import Notification from "../views/Notification.vue";
import Privacy from "../views/Settings/Privacy.vue";
import Terms from "../views/Settings/Terms.vue";
import Conditions from "../views/Settings/Conditions.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/show-post/:id",
    name: "Show Post",
    component: ShowLecturePost,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lecture/:id",
    name: "Lecture",
    component: Lecture,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lecture-purchased/:id",
    name: "Lecture Purchased",
    component: LecturePurchased,
    meta: {
      requiresAuth: true,
      layout: "lecture",
    },
  },
  {
    path: "/lecture-exam-results/:id",
    name: "Lecture Exam Results",
    component: LectureExamResults,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/teacher/:id",
    name: "Teacher",
    component: Teacher,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/list-all-teachers",
    name: "List All Teachers",
    component: ListAllTeachers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/list-top-lectures",
    name: "List Top Lectures",
    component: ListTopLectures,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile-edit",
    name: "Profile Edit",
    component: ProfileEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/list-my-lectures",
    name: "List My Lectures",
    component: ListMyLecture,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/exams",
    name: "ListMyExams",
    component: ListMyExams,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/wallet",
    name: "ListMyWallet",
    component: ListMyWallet,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      layout: "auth",
    },
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      layout: "auth",
    },
    component: Register,
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    meta: {
      layout: "auth",
    },
    component: ForgetPassword,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    meta: {
      layout: "auth",
    },
    component: ResetPassword,
  },
  {
    path: "/verification-code",
    name: "Verification Code",
    meta: {
      layout: "auth",
    },
    component: Token,
  },
  {
    path: "/notification",
    name: "notification",
    meta: {
      requiresAuth: true,
    },
    component: Notification,
  },

  {
    path: "/privacy",
    name: "privacy",
    meta: {
      requiresAuth: true,
    },
    component: Privacy,
  },
  {
    path: "/terms",
    name: "terms",
    meta: {
      requiresAuth: true,
    },
    component: Terms,
  },

  {
    path: "/termsandconditions",
    name: "termsandconditions",
    meta: {
      requiresAuth: true,
    },
    component: Conditions,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router;
