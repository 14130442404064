<template>
  <!-- <div>
    <img
      v-if="post.attachment"
      :src="post.attachment_full_path"
      alt=""
      class="img-3"
    />
    <p class="p-005">
      {{ post.body }}
    </p>
    <div class="poll-option" v-for="option in post.options" :key="option._id">
      <input
        type="radio"
        :name="'answer-' + post._id"
        class="answer-but"
        :id="'answer-but-' + option._id"
        :checked="option.student_select"
        @click="handelAnswer(post._id, option._id)"
      />
      <label
        v-if="post.post_answered_by_student == 1"
        :class="
          'answer-con' +
          (option.student_select == 1
            ? ' answer-con-selected'
            : ' answer-con-not-selected')
        "
        :style="
          'background: linear-gradient(90deg, #ff0000 ' +
          option.option_select_count_percentage +
          ', #fff ' +
          option.option_select_count_percentage +
          ')'
        "
        :for="'answer-but-' + option._id"
      >
        <div>{{ option.option }}</div>
        <div>{{ option.option_select_count_percentage }}</div>
      </label>
      <label v-else :class="'answer-con'" :for="'answer-but-' + option._id">
        {{ option.option }}
      </label>
    </div>
  </div> -->
  <div>
    <div class="con-post-img" v-if="post.attachment" v-bind:style="{background:`url( ${post.attachment_full_path}) no-repeat center center `}">
      <!-- <img :src="post.attachment_full_path" alt="" /> -->
    </div>
    <div class="con-post-content con-post-content-Poll">
      <h1>{{ post.body }}</h1>
      <div class="con-poll-cards">
        <div
          class="poll-option"
          v-for="option in post.options"
          :key="option._id"
        >
          <input
            class="poll-post-class"
            type="radio"
            :name="'poll-post' + post._id"
            :id="'poll-post-id-' + option._id"
            :checked="option.student_select"
            :disabled="Boolean(post.post_answered_by_student)"
            @click="handelAnswer(post._id, option._id)"
          />
          <label
            
            class="poll-card-post"
            :class="{'check-span-poll-correct':option.is_correct && post.post_answered_by_student ,'selected':option.student_select && post.post_answered_by_student,'check-span-poll-wrong':!option.is_correct && option.student_select &&post.post_answered_by_student}"
            :for="'poll-post-id-' + option._id"
          >
            <span class="check-span-poll" :class="{'checked':option.student_select}"> </span>
            <p :class="{'checked':option.student_select}" v-if="option.option != ''">{{ option.option }}</p>
            <div class='option-image' v-bind:style="{background:`url( ${option.image}) no-repeat center center `}" v-else></div>
            <span class="span-poll-persentage" v-if="post.post_answered_by_student" :class="{'checked':option.student_select}">{{
              option.option_select_count_percentage
            }}</span>
            <span class="fill-span-question" :class="{'correct':option.is_correct && post.post_answered_by_student,'wrong':!option.is_correct && option.student_select &&post.post_answered_by_student }" v-if="post.post_answered_by_student" ></span>
          </label>
          <!-- <label
            v-else-if="post.post_answered_by_student && !option.is_correct"
            class="poll-card-post check-span-poll-wrong"
            :style="'--my-width-var:' + option.option_select_count_percentage"
            :for="'poll-post-id-' + option._id"
          >
            <span class="check-span-poll"> </span>
            <p>{{ option.option }}</p>
            <span class="span-poll-persentage">{{
              option.option_select_count_percentage
            }}</span>
          </label>
          <label
            v-else
            class="poll-card-post"
            :for="'poll-post-id-' + option._id"
          >
            <span class="check-span-poll"> </span>
            <p>{{ option.option }}</p>
            <span class="span-poll-persentage">{{
              option.option_select_count_percentage
            }}</span>
          </label> -->
        </div>
      </div>
    </div>
    <post-tags :post="post"></post-tags>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import PostTags from '../PostTags.vue';
export default {
  components: { PostTags },
  name: "QuestionPost",
  props: {
    post: Object,
  },
  methods: {
    ...mapActions("post", ["selectPostOptionApi"]),
    handelAnswer(postId, optionId) {
      if (
        this.post.options.filter((option) => option.student_select == 1)
          .length == 0
      ) {
        this.selectPostOptionApi({ postId, optionId }).then((response) => {
          this.post.options = response.data.data.options;
          this.post.post_answered_by_student = 1;
        });
      }
    },
  },
  
};
</script>
<style>
:root {
  --my-width-var: 0%;
}
</style>

<style scoped>
.check-span-poll-correct .span-poll-persentage,
.check-span-poll-correct p {
  color: #816797 !important;
  display: block;
}

.check-span-poll-correct .check-span-poll {
  background-color: #816797 !important;
}
.check-span-poll-wrong.selected{
  border:2px solid #e32718;
}
.check-span-poll-correct.selected{
  border:2px solid #816797;
}
.check-span-poll-correct::after {
  background-color: #dcd7e0;
  width: 100% !important;
  
}

.check-span-poll-wrong .span-poll-persentage,
.check-span-poll-wrong p {
  color: #e32718 !important;
  display: block;
}

.check-span-poll-wrong .check-span-poll {
  background-color: #e32718 !important;
}

.check-span-poll-wrong::after {
  background-color: #e3261847;
  width: 100% !important;
}
</style>
<style scoped>
.option-image{
  background-size: cover !important;
  width: 90%;
  flex-grow: 1;
  height: 45px;
  position:relative;
  z-index:2;
}
.poll-card-post{
  padding: 0px;
}
.check-span-poll{
  margin:0px 10px !important
}
.span-poll-persentage{
  position: absolute;
  top:50%;
  right:20px ;
  transform: translate(0%,-50%);
  z-index: 3;

}
.poll-card-post p{
  padding:12px 0px;
}
</style>