<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="con-allteacher-page">
          <h1>Terms & Conditions</h1>
<div class="main-con-to-privacy-and-policy">
  <div class="row">
    <div class="col-6">
      <img src="@/assets/images/19197826-[Converted].png" class="free-pick-image-privacy">
    </div>
    <div class="col-6">
      <div class="main-con-to-privacy-and-policy-1">
      <div class="con-one-privacy-and-policy-card">
    <div class="con-div-img-icon-priv">
      <img src="@/assets/images/terms-and-conditions.png">
    </div>
    <p>{{ term }}</p>
    </div>
  </div>
    </div>
  </div>
</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axiosConfig";
export default{
  
  data(){
    return{
      term:''
    }
  },
  methods:{
    getText(){
      axios.get(`/terms-and-conditions`).then((response)=>{
        this.term = response.data.data
      })
    },
  },
  beforeMount(){
    this.getText();
  }
};
</script>
<style scoped>
.main-con-to-privacy-and-policy{
  display:flex;
  align-items:center;
  justify-content:center;
  min-height:60vh;
}
.con-one-privacy-and-policy-card{
  max-width:700px;
  background-color:white;
  padding:20px;
  border-radius:15px;
  box-shadow:0px 0px 10px 0px #eee;
  position:relative;
  padding-top:70px;
}
.con-div-img-icon-priv{
  position:absolute;
  top:0px;
  left:50%;
  transform:translate(-50%,-50%);
  width:100px;
  height:100px;
  background-color:white;
  border-radius:50%;
  box-shadow:0px 0px 10px 0px #eee;
  display:flex;
  align-items:center;
  justify-content:center;
}
.con-div-img-icon-priv img{
  width:70px;
}
.main-con-to-privacy-and-policy-1{
  display:flex;
  align-items:center;
  justify-content:center;
  min-height:100%;
}
.free-pick-image-privacy{
  width:100%;
  max-width:500px;
  display:block;
  margin:auto;
}
.con-one-privacy-and-policy-card p{ 
font-size:15px;
color:#888da8;
font-weight:600px;
line-height:40px;
text-align:center;
}
</style>
