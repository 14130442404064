<template>
  <div class="con-img-blank" v-if="!posts.length">
    <img src="@/assets/images/4170023.png" alt="" />
    <h1>{{ emptyCase.firstTitle }}</h1>
    <p>{{ emptyCase.secondTitle }}</p>
  </div>
  <div :class="'w-100 ' + ((showMobileTopLecturesAndFollowTeacher) ? 'timeline-post-size' : '')" style="" v-else>
    <Post
      v-for="(post, index) in posts"
      :key="`${index}_${post._id}`"
      :post="post"
      :showComments="false"
      :showMobileTopLecturesAndFollowTeacher="(showMobileTopLecturesAndFollowTeacher && posts.length > 0 && index == 1)? true : false"
    ></Post>
  </div>
</template>

<script>
import Post from "./posts/Post.vue";
export default {
  name: "Timeline",
  components: {
    Post,
  },
  props: {
    posts: Array,
    emptyCase: Object,
    showMobileTopLecturesAndFollowTeacher:Boolean
  },
};
</script>
<style scoped>
.con-img-blank {
  display: flex !important;
}
.timeline-post-size{
  width: 100%; max-width: 600px
}
</style>