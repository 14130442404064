<template>
  <div class="con-next-preves-button">
    <div>
      <button
        class="prev"
        v-show="contentManagement.previous"
        @click="getContent(contentManagement.previous)"
      >
        <i class="fa-solid fa-angle-left"></i><span>Preves </span>
      </button>
    </div>
    <div>
      <button
        class="next"
        v-show="
          contentManagement.next || (!contentManagement.next && !studentIsRated && currentContentType != 'rate')
        "
        @click="getContent(contentManagement.next?contentManagement.next :'rate')"
      >
        <span>Next </span><i class="fa-solid fa-angle-right"></i>
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LectureContentSidebar",
  computed: {
    ...mapGetters("lecturePurchased", [
      "contentManagement",
      "studentIsRated",
      "currentContentType",
    ]),
  },
  methods: {
    ...mapActions("lecturePurchased", ["getLectureProgressApi"]),
    getContent(content) {
      if (content) {
        this.$router.push({ query: { content: content } });
        this.getLectureProgressApi(this.$route.params.id)
          .then(() => {})
          .catch((err) => console.log(err));
      } else {
        this.currentContentType = null;
      }
    },
  },
};
</script>
