import axios from "@/axiosConfig";

export default ({
  state: {
    lectures: [],
    nextPage: 0,
  },
  actions: {
    getLecturesApi({ commit }) {
      axios.get('/top-lectures-list')
        .then(response => {
          commit('SET_LECTURES', response);
        })
        .catch(error => {
          console.log(error)
        })
    },
    getNextLecturesApi({ commit, state }) {
      axios.get('/top-lectures-list?page=' + state.nextPage)

        .then(response => {
          commit('SET_NEXT_LECTURES', response);
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  mutations: {
    SET_LECTURES(state, response) {
      state.lectures = response.data.data;
      state.nextPage = ((response.data.pagination.current_page + 1) <= response.data.pagination.last_page) ? response.data.pagination.current_page + 1 : 0;
    },
    SET_NEXT_LECTURES(state, response) {
      (response.data.data).forEach(element => {
        state.lectures.push(element);
      });
      console.log(response.data.pagination);
      state.nextPage = ((response.data.pagination.current_page + 1) <= response.data.pagination.last_page) ? response.data.pagination.current_page + 1 : 0;
    }
  },
  getters: {
    lectures: state => state.lectures,
    nextPage: state => state.nextPage,
  },
  namespaced: true,
});
