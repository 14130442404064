import Vue from "vue";
import axios from "@/axiosConfig";
export default ({
  state: {
    status : '',
    token : localStorage.getItem('token') ?? '',
    user :  JSON.parse(localStorage.getItem('user')) ?? {},
    education_levels : []
  },
  actions: {
    loginApi({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('/login', user)
        .then(response => {
          Vue.$toast.success(response.data.message);
          const token = response.data.data.token;
          const user = response.data.data.user;
          localStorage.setItem('token',token);
          localStorage.setItem('user',JSON.stringify(user));
          commit('auth_success', response.data.data);
          resolve(response)
        })
        .catch(error => {
          Vue.$toast.error(error.response.data.message);
          commit('auth_error')
          localStorage.removeItem('token')
          reject(error)
        })
      })
    },
    registerApi({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('/register', user)
        .then(response => {
          Vue.$toast.success(response.data.message);
          localStorage.setItem('registeredEmail',user.email);
          resolve(response)
        })
        .catch(error => {
          Vue.$toast.error(error.response.data.message);
          commit('auth_error', error)
          localStorage.removeItem('token')
          reject(error)
        })
      })
    },
    forgetPasswordApi({commit},user){
      return new Promise((resolve, reject) => {
        commit('forget_password_request')
        axios.post('/reset-password', user)
        .then(response => {
          Vue.$toast.success(response.data.message);  
          resolve(response)
        })
        .catch(error => {
          Vue.$toast.error(error.response.data.message);  
          localStorage.removeItem('token')
          reject(error)
        })
      })
    },
    resetPasswordApi(_ , user){
      return new Promise((resolve, reject) => {
        axios.post('/reset/password', user)
        .then(response => {
          Vue.$toast.success(response.data.message);
          resolve(response)
        })
        .catch(error => {
          console.log(error.response);
          Vue.$toast.error(error.response.data.message);
          reject(error)
        })
      })
    },
    educationLevelApi({commit}){
      axios.get('/education-levels')
      .then((response) => {
        var educationLevels = response.data;
        commit('education_levels', educationLevels);
      })
      .catch(() => {
      })
    },
    submitTokenApi({commit}, data){
      return new Promise((resolve, reject) => {
        axios.post('/token', data)
        .then(response => {
          Vue.$toast.success(response.data.message);
          resolve(response)
        })
        .catch(error => {
          Vue.$toast.error(error.response.data.message);
          commit('auth_error', error)
          reject(error)
        })
      })
    },
    resendTokenApi({commit}, data){
      return new Promise((resolve, reject) => {
        axios.post('/resend-token', data)
        .then(response => {
          Vue.$toast.success(response.data.message);
          resolve(response)
        })
        .catch(error => {
          Vue.$toast.error(error.response.data.message);
          commit('auth_error', error)
          reject(error)
        })
      })
    },
    
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    updateSendStatus({commit},state){
      commit('forget_password_redirect', state);
    },
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, data){
      state.status = 'success'
      state.token = data.token
      state.user = data.user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
    forget_password_request(state){
      state.status = 'loading'
    },
    education_levels(state, education_levels){
      state.education_levels = education_levels
    }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    getUserToken: state => state.token,
    authStatus: state => state.status,
    educationLevels: state => state.education_levels,
    user: state => state.user,
  },
  namespaced: true,
});
