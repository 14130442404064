
<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="row">
          <div class="col-12 col-lg-8 no-padding-mobile">
            <div class="main-con-to-posts">
              <Post v-if="post" :post="post" :showComments=true></Post>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="main-con-to-side-bar">
              <teacher-to-follow></teacher-to-follow>
              <top-lectures :topLectures="topLectures"></top-lectures>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TeacherToFollow from "../../components/sidebar/TeacherToFollow.vue";
import TopLectures from "../../components/sidebar/TopLectures.vue";
import Post from "../../components/timeline/posts/Post.vue";
export default {
  components: { TeacherToFollow, TopLectures, Post },
  name: "ShowLecturePost",
  computed: {
    ...mapGetters("post", ["post"]),
    ...mapGetters("home", ["topLectures"]),
  },
  methods: {
    ...mapActions("post", ["showLecturePostApi"]),
    ...mapActions("home", ["topLecturesApi"]),
  },
  beforeMount() {
    this.showLecturePostApi(this.$route.params.id);
    this.topLecturesApi();
  },
};
</script>

<style>
</style>