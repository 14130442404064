<template>
  <div class="row">
    <div class="col-12 col-lg-8">
      <div class="main-con-to-question-card-exam">
        <div
          v-for="(question, index) in content.questions"
          :key="question.id"
          class="main-con-to-question-exam"
        >
          <div class="con-header-question-exam">
            <div class="con-question-exam-number-degree">
              <h1>Question {{ index + 1 }}</h1>
              <span>({{ index + 1 }}/{{ content.questions.length }})</span>
            </div>
            <div class="exam-title-1">
              <p>
                {{ question.question.question }}
              </p>
            </div>
            <div class="con-question-exam-img" v-if="question.question.image">
              <img :src="question.question.image_full_path" alt="" />
            </div>
          </div>
          <div v-if="question.question.type == 'multi_choose'">
            <div
              class="con-answer-card-Question"
              v-for="(answer, index) in question.question.answers"
              :key="answer.id"
            >
              <span>{{ answers[index] }}</span>
              <p>{{ answer.answer }}</p>
              <img v-if="answer.image" :src="image_full_path" alt="" />
            </div>
          </div>
          <div v-else>
            <div class="con-answer-card-Question">
              <span>{{ answers[0] }}</span>
              <p>Right</p>
            </div>
            <div class="con-answer-card-Question">
              <span>{{ answers[1] }}</span>
              <p>Wrong</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="seprator-title">Bubble Sheet</h1>
    <div class="col-12 col-lg-4">
      <div class="main-con-buble-sheet">
        <div v-for="(question, index) in content.questions" :key="question.id">
          <div
            class="con-buble-sheet-card"
            v-if="question.question.type == 'multi_choose'"
          >
            <h1>Question {{ index + 1 }} :</h1>
            <div class="con-answer-buble-sheet">
              <span
                v-for="(answer, index) in question.question.answers"
                :key="answer.id"
                :class="
                  'question_' +
                  question.question_id +
                  ' ' +
                  (question.answer_id == answer.id ? 'active' : '')
                "
                @click="answerQuestion(question.question, answer.id, $event)"
                >{{ answers[index] }}
              </span>
            </div>
          </div>
          <div class="con-buble-sheet-card" v-else>
            <h1>Question {{ index + 1 }} :</h1>
            <div class="con-answer-buble-sheet">
              <span
                :class="
                  'question_' +
                  question.question_id +
                  ' ' +
                  (question.answer_id == 'true' ? 'active' : '')
                "
                @click="answerQuestion(question.question, 'true', $event)"
                >{{ answers[0] }}
              </span>
              <span
                :class="
                  'question_' +
                  question.question_id +
                  ' ' +
                  (question.answer_id == 'false' ? 'active' : '')
                "
                @click="answerQuestion(question.question, 'false', $event)"
                >{{ answers[1] }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ExamBubble",
  props: {
    content: Object,
  },
  data: function () {
    return {
      answers: ["A", "B", "C", "D"],
      questionSelectedAnswers: [],
    };
  },
  methods: {
    ...mapActions("lecturePurchased", ["answerQuestionApi"]),
    answerQuestion(item, answer, event) {
      this.answerQuestionApi({ item_id: item.id, answer: answer })
        .then(() => {
          console.log(item);
          document
            .querySelectorAll(
              ".con-answer-buble-sheet > span.question_" + item.id
            )
            .forEach((element) => {
              element.classList.remove("active");
            });
          event.target.classList.add("active");
          if (
            document.querySelectorAll(
              ".con-answer-buble-sheet > span.question_" + item.id + ".active"
            ).length == this.content.questions.length
          ) {
            this.$emit("endExamStatus", true);
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    if (
      document.querySelectorAll(".con-answer-buble-sheet > span.active")
        .length == this.content.questions.length
    ) {
      console.log(1);
      this.$emit("endExamStatus", true);
    }
  },
};
</script>