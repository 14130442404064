<template>
  <div>
    <app-header />
    <slot />
  </div>
</template>

<script>
import AppHeader from '@/components/partials-auth/AppHeader.vue';


export default {
  components: {
    AppHeader
  },
};
</script>