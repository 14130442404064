<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="con-allteacher-page">
          <h1>All My Lectures</h1>
          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-4 col-lg-4"
              v-for="lecture in lectures"
              :key="lecture.lecture_id"
            >
              <div class="main-con-card-course">
                <div class="con-image-card-course">
                  <!-- <img
                    @click="$router.push('/lecture/' + lecture.lecture_id)"
                    class="img-card-course-course"
                    :src="lecture.lecture_image"
                    alt=""
                  />-->
                  <div class="div-of-my-lec-bac" v-bind:style="{
                    background: `url( ${lecture.lecture_image}) no-repeat center center `,
                  }">
                  </div>
                  <img 
                    @click="$router.push('/teacher/' + lecture.teacher_id)"
                    class="img-card-course-teacher"
                    :src="lecture.teacher_profile_image"
                    alt=""
                  />
                </div>
                <div
                  class="con-instractor-name"
                  @click="$router.push('/teacher/' + lecture.teacher_id)"
                >
                  <i class="fa-solid fa-user"></i
                  ><span>{{ lecture.teacher_name }}</span>
                </div>
                <p
                  class="course-card-title"
                  @click="$router.push('/lecture/' + lecture.lecture_id)"
                >
                  {{ lecture.lecture_title }}
                </p>
                <p class="course-card-des">
                  {{ lecture.lecture_description }}
                </p>
                <div class="con-price-action">
                  <div class="con-course-card-price">
                    <p>Price :</p>
                    <h1>{{ lecture.lecture_subscription_fees }}</h1>
                  </div>
                  <div class="con-course-card-action">
                    <a
                      href="#"
                      @click="$router.push('/lecture/' + lecture.lecture_id)"
                      >Book Now</a
                    >
                  </div>
                </div>
                <div class="con-date-rate">
                  <div class="con-course-card-date">
                    <i class="fa-solid fa-calendar-days"></i
                    ><span>{{ lecture.lecture_created_at }}</span>
                  </div>
                  <div class="con-course-card-rate">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half-stroke"></i>
                    <i class="fa-regular fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="con-pagination">
            <i class="fa-solid fa-angle-left"></i><span>1</span
            ><span class="active">2</span><span>3</span><span>4</span
            ><i class="fa-solid fa-angle-right"></i>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ListMyLecture",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters("listMyLectures", ["lectures", "nextPage"]),
  },
  methods: {
    ...mapActions("listMyLectures", [
      "getMyLecturesApi",
      "getNextMyLecturesApi",
    ]),
    handleScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.body.scrollHeight;
        if (bottomOfWindow && this.nextPage) {
          this.getNextMyLecturesApi();
        }
      };
    },
  },
  beforeMount() {
    this.getMyLecturesApi();
  },
};
</script>