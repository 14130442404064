<template>
  <header>
    <div class="main-con-for-primary-header">
      <div class="row">
        <div class="col-3 col-lg-4">
          <div class="con-logo-website">
            <router-link to="/">
              <img src="@/assets/images/logo horizontal.png" alt="" />
            </router-link>
          </div>
        </div>
        <div class="col-9 col-lg-8">
          <div class="con-input-primary-header-action">
            <a href="#" v-if="this.login" @click="$router.push('/login')"
              >Login</a
            >
            <a href="#" v-else @click="$router.push('/register')">Register</a>
            <switch-language />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import SwitchLanguage from "../SwitchLanguage.vue";
export default {
  components: { SwitchLanguage },
  name: "AppHeader",
  data() {
    return {
      login: false,
    };
  },
  mounted() {
    this.login = this.$router.currentRoute.path != "/login";
  },
  watch: {
    $route(to) {
      this.login = to.path != "/login";
    },
  },
};
</script>
