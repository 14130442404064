<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="row">
          <div class="col-12 col-lg-8 no-padding-mobile">
            <div class="main-con-to-posts">
              <carousel
                class="owl-carousel-customized-1"
                :nav="false"
                :responsive="{
                  0: { items: 3 },
                  600: { items: 4 },
                  1000: { items: 5 },
                }"
              >
                <div class="item">
                  <div class="con-story">
                    <div class="con-story-img">
                      <img
                        class="img-story"
                        src="@/assets/images/vertical-shot-educational-supplements-child-s-education-204803817.jpg"
                        alt=""
                      /><img
                        class="story-teacher-img"
                        src="@/assets/images/successful-college-student-lg.png"
                        alt=""
                      />
                      <p>Shehab Mostafa</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="con-story">
                    <div class="con-story-img">
                      <img
                        class="img-story"
                        src="@/assets/images/vertical-shot-educational-supplements-child-s-education-204803817.jpg"
                        alt=""
                      /><img
                        class="story-teacher-img"
                        src="@/assets/images/successful-college-student-lg.png"
                        alt=""
                      />
                      <p>Shehab Mostafa</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="con-story">
                    <div class="con-story-img">
                      <img
                        class="img-story"
                        src="@/assets/images/istockphoto-1165926842-612x612.jpg"
                        alt=""
                      /><img
                        class="story-teacher-img"
                        src="@/assets/images/successful-college-student-lg.png"
                        alt=""
                      />
                      <p>Shehab Mostafa</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="con-story">
                    <div class="con-story-img">
                      <img
                        class="img-story"
                        src="@/assets/images/istockphoto-992723194-612x612.jpg"
                        alt=""
                      /><img
                        class="story-teacher-img"
                        src="@/assets/images/successful-college-student-lg.png"
                        alt=""
                      />
                      <p>Shehab Mostafa</p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="con-story">
                    <div class="con-story-img">
                      <img
                        class="img-story"
                        src="@/assets/images/e-learning-reading-book-stack-books-yellow-background-vertical-concept-education-electronic-gadgets-192187844.jpg"
                        alt=""
                      /><img
                        class="story-teacher-img"
                        src="@/assets/images/successful-college-student-lg.png"
                        alt=""
                      />
                      <p>Shehab Mostafa</p>
                    </div>
                  </div>
                </div>
              </carousel>
              <!-- <stories></stories> -->
              <div
                v-infinite-scroll="loadMore"
                infinite-scroll-disabled="busy"
                infinite-scroll-distance="10"
              >
                <timeline
                  :emptyCase="emptyCase"
                  :posts="posts"
                  :showMobileTopLecturesAndFollowTeacher="true"
                ></timeline>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <div class="main-con-to-side-bar">
              <teacher-to-follow></teacher-to-follow>
              <top-lectures :topLectures="topLectures"></top-lectures>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Timeline from "../components/timeline/Timeline.vue";
import TeacherToFollow from "../components/sidebar/TeacherToFollow.vue";
import TopLectures from "../components/sidebar/TopLectures.vue";
import carousel from "vue-owl-carousel";
// import Stories from "../components/stories/Stories.vue";

export default {
  name: "Home",
  data() {
    return {
      emptyCase: {
        firstTitle: "Opps!!!!",
        secondTitle: "You Should Follow Teacher First",
        busy: false,
      },
    };
  },
  components: {
    Timeline,
    TeacherToFollow,
    TopLectures,
    carousel,
    // Stories
  },
  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  computed: {
    ...mapGetters("home", ["posts", "nextPage", "topLectures"]),
  },

  methods: {
    ...mapActions("home", [
      "getPostsApi",
      "getNextPostsApi",
      "likePostApi",
      "disLikePostApi",
      "topLecturesApi",
    ]),
    // handleScroll() {
    //   window.onscroll = () => {
    //     let bottomOfWindow =
    //       Math.ceil(document.documentElement.scrollTop) + window.innerHeight ===
    //       document.body.scrollHeight;
    //     if (bottomOfWindow && this.nextPage) {
    //       this.getNextPostsApi();
    //     }
    //   };
    // },
    loadMore: function() {
      this.busy = true;
      setTimeout(() => {
        if (this.nextPage) {
          this.getNextPostsApi();
        }
        this.busy = false;
      }, 1000);
    }
  },
  beforeMount() {
    this.getPostsApi();
    this.topLecturesApi();
  },
};
</script>
<style scoped>
.item {
  margin-left: 10px;
}
</style>
