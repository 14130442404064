<template>
  <div class="con-tags-text">
    <span
      v-for="tag in post.tags"
      :key="tag.id"
      @click="searchTag(tag.id, tag.tag)"
      class="pointer"
      >{{ tag.tag }}</span
    >
  </div>
</template>

<script>
export default {
  name: "LectureTags",
  props: {
    post: Object,
  },
  methods: {
    searchTag(id, tag) {
      this.$router.push({ name: "Search", query: { q: tag, tag: id } });
    },
  },
};
</script>

<style>
</style>