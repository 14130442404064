var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.post.attachment_full_path)?_c('carousel',{staticClass:"owl-carousel-customized-10",attrs:{"nav":false,"dots":true,"responsive":{
      0: { items: 1 },
      600: { items: 1 },
      1000: { items: 1 },
    }}},_vm._l((_vm.post.attachment_full_path),function(attach,index){return _c('div',{key:index,staticClass:"item"},[(_vm.post.attachment)?_c('div',{staticClass:"con-post-img",style:({
          background: `url( ${attach}) no-repeat center center `,
        })}):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"con-post-content con-post-content-Poll"},[_c('h1',[_vm._v(_vm._s(_vm.post.body))])]),_c('post-tags',{attrs:{"post":_vm.post}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }