<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="row row-reverse">
          <div class="col-12 col-md-8">
            <ul class="ul-profile-teacher-tabs">
              <li
                class="active"
                @click.prevent="getMyLectures"
                data-cont=".con-tabs-profile-all"
              >
                My Lectures
              </li>
              <li
                @click.prevent="getSaved"
                data-cont=".con-tabs-profile-lecture"
              >
                Saved
              </li>
              <li
                @click.prevent="getRated"
                data-cont=".con-tabs-profile-my-lecture"
              >
                Rated
              </li>
              <li
                @click.prevent="getTeachers"
                data-cont=".con-tabs-profile-liked"
              >
                Teachers
              </li>
            </ul>
            <div class="main-con-to-posts main-con-to-posts-profile-teacher">
              <div
                class="con-tabs-profile-all"
                v-if="selectedType != 'teachers'"
              >
                <timeline :emptyCase="emptyCase" :posts="posts" :showMobileTopLecturesAndFollowTeacher="false"></timeline>
              </div>
              <div
                class="con-tabs-profile-all"
                v-else-if="selectedType == 'teachers'"
              >
                <div class="row">
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-3"
                    v-for="teacher in teachers"
                    :key="teacher.id"
                  >
                    <div class="con-teacher-card">
                      <img
                        @click="$router.push('/teacher/' + teacher.id)"
                        :src="teacher.profile_image"
                        alt=""
                      /><span> <i class="fa-solid fa-star"></i>(3,5)</span>
                      <p
                        class="teacher-card-name"
                        @click="$router.push('/teacher/' + teacher.id)"
                      >
                        {{ teacher.name }}
                      </p>
                      <p class="teacher-card-department">
                        {{ teacher.subject_name }}
                      </p>
                      <button
                        class="unfollw"
                        v-if="teacher.is_followed"
                        v-on:click.prevent="followTeacher(teacher.id)"
                      >
                        unfollow
                      </button>
                      <button
                        v-else
                        v-on:click.prevent="followTeacher(teacher.id)"
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="main-con-to-side-bar">
              <div
                class="
                  con-card-teacher-img-profile con-card-teacher-img-profile-1
                "
              >
                <!-- <img :src="student.student_profile_image" alt="" /> -->
                <div class="img-profile-back-ground" v-bind:style="{
            background: `url( ${student.student_profile_image}) no-repeat center center `,
          }"></div>
                <p class="side-card-teacher-name">{{ student.student_name }}</p>
                <p class="side-card-teacher-department">#{{ student.student_id }}</p>
                <div class="con-side-card-follow-button">
                  <button @click="$router.push('/profile-edit')">
                    Edit Information
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Timeline from "../../components/timeline/Timeline.vue";

export default {
  name: "Profile",
  data() {
    return {
      selectedType: "posts",
      emptyCase: {
        // 'image' : 'img/4170023.png',
        firstTitle: "OH !!",
        secondTitle: "There is No Posts",
      },
    };
  },
  computed: {
    ...mapGetters("student", ["student", "posts", "teachers"]),
  },
  methods: {
    ...mapActions("student", [
      "getStudentApi",
      "getMyLecturesApi",
      "getSavedApi",
      "getRatedApi",
      "getTeachersApi",
    ]),
    ...mapActions("listAllTeachers", ["followTeacherApi"]),
    removeActiveClass(event) {
      for (const li of document.querySelectorAll(
        ".ul-profile-teacher-tabs li.active"
      )) {
        li.classList.remove("active");
      }
      event.target.classList.add("active");
    },
    getMyLectures(event) {
      this.removeActiveClass(event);
      this.selectedType = "posts";
      this.getMyLecturesApi();
    },
    getSaved(event) {
      this.removeActiveClass(event);
      this.selectedType = "posts";
      this.getSavedApi();
    },
    getRated(event) {
      this.removeActiveClass(event);
      this.selectedType = "posts";
      this.getRatedApi();
    },
    getTeachers(event) {
      this.removeActiveClass(event);
      this.selectedType = "teachers";
      this.getTeachersApi();
    },
    followTeacher(id) {
      this.followTeacherApi(id).then(() => {
        const itemIndex = this.teachers.findIndex((x) => x.id === id);
        if (this.teachers[itemIndex]["is_followed"]) {
          this.teachers[itemIndex]["is_followed"] = 0;
        } else {
          this.teachers[itemIndex]["is_followed"] = 1;
        }
      });
    },
  },
  beforeMount() {
    this.getStudentApi();
    this.getMyLecturesApi();
  },
  components: { Timeline },
};
</script>