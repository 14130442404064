import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);


const messages = {
    en: {
      login:'Login',
      message: {
        value: 'Teachers To Follow'
      }
    },
    ar: {
      login:'تسجيل الدخول',
      message: {
        value: 'مدرسين للمتابعة'
      }
    },
  };

  const i18n = new VueI18n({
    locale: 'en',
    messages
  });
  

export default i18n;