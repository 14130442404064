<template>
  <!-- <div class="main-con-to-exam-result">
    <div class="con-result-card">
      <span>90%</span>
      <div class="con-span-p">
        <p>Result</p>
        <span>30 / 45</span>
      </div>
      <div class="con-span-p">
        <p>Status</p>
        <span class="success"> success<i class="fa-solid fa-star"></i></span>
      </div>
      <div class="con-span-p">
        <p>Status</p>
        <span class="fail">
          Fail<img
            src="images/consist_be-composed_combine_mix_design_amalgam_concur_broken_star-512.png"
            alt=""
        /></span>
          <button>Start Exam</button>
        </form>
      </div>
    </div>
  </div> -->

  <form @submit.prevent="startExam">
    <div class="min-con-ins-coure-102">
      <div class="main-con-for-start-exam">
        <h1 class="h1-of-selext-exam-type">Select Exam Type</h1>
        <div class="main-con-for-Select-type-exam">
          <input
            class="class-selext-exam-type"
            type="radio"
            name="Select-exam-type"
            id="id-selext-exam-type"
          />
          <label for="id-selext-exam-type" @click="selectExamType('bubble')">
            <img src="@/assets/images/7559123.png" alt="" />
            <p>Bubble Sheet</p>
          </label>
          <input
            class="class-selext-exam-type"
            type="radio"
            name="Select-exam-type"
            id="id-selext-exam-type-2"
          />
          <label for="id-selext-exam-type-2" @click="selectExamType('normal')">
            <img src="@/assets/images/7605613.png" alt="" />
            <p>Normal Sheet</p>
          </label>
        </div>
        <button type="submit">Start Exam</button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ExamStart",
  computed: {
    ...mapGetters("lecturePurchased", ["examType"]),
  },
  methods: {
    ...mapActions("lecturePurchased", ["startExamApi", "getLectureProgressApi"]),
    startExam() {
      this.startExamApi()
        .then(() => this.getLectureProgressApi(this.$route.params.id))
        .catch((err) => console.log(err));
    },
    selectExamType(type) {
      this.$store.dispatch("lecturePurchased/setExamType", type);
    },
  },
};
</script>

<style>
</style>