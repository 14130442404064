<template>
  <!-- <div class="container-fluid con-flu-456 con-flu-457 con-flu-4231">
    <img
      src="@/assets/img/tms empty.png"
      alt=""
      class="img-sign-up-096 img-sign-up-022"
    />

    <div class="container con-9020 con-9021 con-9033">
      <div class="con-563-sign-up">
        <div class="container">
          <h1>Verification Code</h1>
          <p class="p-Verification-Code">Enter Verification Code</p>
          <div class="box-input-verification">
            <div class="validation-code">
            <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                separator=""
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-complete="handleOnComplete"
                placeholder="*"
                @blur="validate('token')"
                @keypress="validate('token')"
                :class="[!!errors.token && 'is-invalid']"
              />
              <p
                :class="[
                  'form-input-hint',
                  !!errors.token && 'invalid-feedback',
                ]"
                v-if="!!errors.token"
              >
                {{ errors.token }}
              </p>

            </div>
          </div>
          <p class="p-Checking">Check Your e-mail</p>
          
          <a href="" @click.prevent="resendToken" class="resend-11"> Resend Code</a>

          <form  @submit.prevent="submitToken">
            <input type="submit" value="" id="submit-5843" />
            <label for="submit-5843" class="submit-5843">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="white"
                class="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </label>
          </form>
        </div>
      </div>
    </div>
  </div> -->
  <div>
    <div class="banner-sign-page"></div>
    <div class="main-con-to-sign-form">
      <form @submit.prevent="submitToken">
        <div class="con-logo-letter">
          <img src="@/assets/images/logo fav.icon.png" alt="" />
        </div>
        <h1>OTP Code</h1>
        <div class="con-input-otp">
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-complete="handleOnComplete"
            placeholder="*"
            @blur="validate('token')"
            @keypress="validate('token')"
            :class="[!!errors.token && 'is-invalid']"
          />
          <p
            :class="['form-input-hint', !!errors.token && 'invalid-feedback']"
            v-if="!!errors.token"
          >
            {{ errors.token }}
          </p>
        </div>
        <div class="con-resend-code">
          <p>if you didn't receive a code</p>
          <button @click.prevent="resendToken">Resend Code</button>
        </div>
        <div class="con-input-submit">
          <input type="submit" value="Send " />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as Yup from "yup";

const tokenSchema = Yup.object().shape({
  token: Yup.number()
    .required()
    .test(
      "token",
      "Must be exactly 6 characters",
      (val) => val.toString().length === 6
    ),
});

export default {
  name: "Token",
  data() {
    return {
      email: localStorage.getItem("registeredEmail"),
      values: {
        token: 0,
      },
      errors: {
        token: 0,
      },
    };
  },
  methods: {
    ...mapActions("auth", ["submitTokenApi", "resendTokenApi"]),
    handleOnComplete(value) {
      this.values.token = value;
    },
    validate(field) {
      tokenSchema.validateAt(field, this.values).catch(() => {});
    },
    submitToken: function () {
      tokenSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.errors = {};
          let data = {
            email: this.email,
            token: this.values.token,
          };
          this.submitTokenApi(data)
            .then(() => this.$router.push("/login"))
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },
    resendToken: function () {
      let data = {
        email: this.email,
      };
      this.resendTokenApi(data);
    },
  },
};
</script>

<style>
.otp-input {
  border: 1px solid #4748452c;
  outline: none;
  color: #474845;
  font-size: 40px;
  font-weight: 500;
  width: 60px;
  height: 70px;
  text-align: center;
}
.otp-input .error {
  border: 1px solid red !important;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>