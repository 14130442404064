import axios from "@/axiosConfig";
import router from "../../router";

export default {
  state: {
    contentManagement: [],
    currentContent: [],
    content: [],
    lecture: [],
    teacher: [],
    progress: 0,
    currentContentType: "",
    endExamStatus: false,
    examType: localStorage.getItem("examType") ?? "",
    studentRate: 0,
    studentIsRated:0,
  },
  actions: {
    getLectureProgressApi({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        var currentContent = state.currentContent.id
          ? "current_content_id=" + state.currentContent.id + "&"
          : "";
        var content = router.app._route.query.content
          ? "content_id=" + router.app._route.query.content
          : "";
        axios
          .get("/lectures/progress/" + id + "?" + currentContent + content)
          .then((response) => {
            console.log(response);
            commit("SET_LECTURE", response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    startExamApi({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/lectures/start-exam/" +
              state.teacher.teacher_id +
              "-" +
              state.currentContent.id
          )
          .then((response) => {
            console.log(response);
            commit("SET_EXAM_START", response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    reStartExamApi({ state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            "/lectures/restart-exam/" +
              state.teacher.teacher_id +
              "-" +
              state.currentContent.id
          )
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    endExamApi({ state }) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/lectures/end-exam/" +
              state.teacher.teacher_id +
              "-" +
              state.currentContent.content_id
          )
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    answerQuestionApi({ state }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/lectures/question-answer/" +
              state.teacher.teacher_id +
              "-" +
              state.currentContent.id +
              "?question_id=" +
              data.item_id +
              "&value=" +
              data.answer
          )
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    setExamType({ commit }, newValue) {
      localStorage.setItem("examType", newValue);
      commit("SET_EXAM_TYPE", newValue);
    },
  },
  mutations: {
    SET_LECTURE(state, response) {
      state.contentManagement = response.data.data.contentManagement;
      state.currentContent = response.data.data.currentContent;
      state.studentRate = response.data.data.studentRate;
      state.content = response.data.data.exam;
      state.lecture = response.data.data.lecture;
      state.progress = response.data.data.progress;
      state.teacher = response.data.data.teacher;
      state.currentContentType = response.data.data.currentContent.content.type;
      state.studentIsRated = response.data.data.studentIsRated;
    },
    SET_EXAM_START(state) {
      state.endExamStatus = false;
    },
    SET_EXAM_TYPE(state, response) {
      state.examType = response;
    },
  },
  getters: {
    contentManagement: (state) => state.contentManagement,
    currentContent: (state) => state.currentContent,
    content: (state) => state.content,
    lecture: (state) => state.lecture,
    progress: (state) => state.progress,
    teacher: (state) => state.teacher,
    currentContentType: (state) => state.currentContentType,
    endExamStatus: (state) => state.endExamStatus,
    examType: (state) => state.examType,
    studentRate: (state) => state.studentRate,
    studentIsRated: (state) => state.studentIsRated,
  },
  namespaced: true,
};
