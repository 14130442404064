<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="con-allteacher-page">
          <h1>All Notification</h1>

          <div class="main-con-to-notification-page7" v-for="(noti,index) in allNotifications" :key="index">
            <router-link :to="noti.type == 'lecture' || noti.type == 'question'? `/lecture/${noti.lecture_id}`: `/show-post/${noti.post_id}`">
              <div class="con-one-notification" @click.once="makeRead(noti.id)">
              <div class="con-img-notification">
                <img :src="noti.teacher_profile_image" alt="" /><i
                  :class="{
                    'flaticon-play-button': noti.type == 'lecture',
                    'flaticon-paper-plane': noti.type == 'comment'
                    ,'flaticon-question':noti.type =='question'
                  }"
                ></i>
              </div>
              <div class="content-of-notification">
                <p class="title-of-notification">
                  {{ noti.teacher_name }}
                </p>
                <p class="notification-happen">
                  {{ noti.short_description }}
                </p>
                <span class="date-of-notification">{{
                  noti.created_at
                }}</span>
              </div>
              <span class="unread" v-if="noti.read_at == null"></span>
            </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axiosConfig";
export default{
  
  data(){
    return{
      allNotifications:[],
      nextpage:1,
      hasMore:false,
    }
  },
  methods:{
    getNextPostsApi(){
      axios.get(`/list-notifications?page=${this.nextpage}`).then((response)=>{
        this.allNotifications.push(...response.data.data);
        this.hasMore = response.data.pagination.has_more_pages;
        if(this.hasMore ){
        this.nextpage = response.data.pagination.current_page++;
        console.log(response.data.pagination.current_page++);
        }else{
          this.nextpage = 0
        }
      })
    },  
    handleScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.floor(document.documentElement.scrollTop + window.innerHeight) ===
          document.body.scrollHeight;
        if (bottomOfWindow && this.hasMore) {
          this.getNextPostsApi();
        }
      };
    },
    getAllNoti(){
      axios.get(`/list-notifications`).then((response)=>{
        this.allNotifications = response.data.data;
        this.hasMore = response.data.pagination.has_more_pages;
        if(this.hasMore ){
        this.nextpage = response.data.pagination.current_page + 1;
        }else{
          this.nextpage = 0
        }
      })
    },
    makeRead(id){
      axios.post(`/notification-mark-as-read/${id}`);
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount(){
    this.getAllNoti();
  }
};
</script>
