var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-8"},[_c('div',{staticClass:"main-con-to-question-card-exam"},_vm._l((_vm.content.questions),function(question,index){return _c('div',{key:question.id,staticClass:"main-con-to-question-exam"},[_c('div',{staticClass:"con-header-question-exam"},[_c('div',{staticClass:"con-question-exam-number-degree"},[_c('h1',[_vm._v("Question "+_vm._s(index + 1))]),_c('span',[_vm._v("("+_vm._s(index + 1)+"/"+_vm._s(_vm.content.questions.length)+")")])]),_c('div',{staticClass:"exam-title-1"},[_c('p',[_vm._v(" "+_vm._s(question.question.question)+" ")])]),(question.question.image)?_c('div',{staticClass:"con-question-exam-img"},[_c('img',{attrs:{"src":question.question.image_full_path,"alt":""}})]):_vm._e()]),(question.question.type == 'multi_choose')?_c('div',_vm._l((question.question.answers),function(answer,index){return _c('div',{key:answer.id,staticClass:"con-answer-card-Question"},[_c('span',[_vm._v(_vm._s(_vm.answers[index]))]),_c('p',[_vm._v(_vm._s(answer.answer))]),(answer.image)?_c('img',{attrs:{"src":_vm.image_full_path,"alt":""}}):_vm._e()])}),0):_c('div',[_c('div',{staticClass:"con-answer-card-Question"},[_c('span',[_vm._v(_vm._s(_vm.answers[0]))]),_c('p',[_vm._v("Right")])]),_c('div',{staticClass:"con-answer-card-Question"},[_c('span',[_vm._v(_vm._s(_vm.answers[1]))]),_c('p',[_vm._v("Wrong")])])])])}),0)]),_c('h1',{staticClass:"seprator-title"},[_vm._v("Bubble Sheet")]),_c('div',{staticClass:"col-12 col-lg-4"},[_c('div',{staticClass:"main-con-buble-sheet"},_vm._l((_vm.content.questions),function(question,index){return _c('div',{key:question.id},[(question.question.type == 'multi_choose')?_c('div',{staticClass:"con-buble-sheet-card"},[_c('h1',[_vm._v("Question "+_vm._s(index + 1)+" :")]),_c('div',{staticClass:"con-answer-buble-sheet"},_vm._l((question.question.answers),function(answer,index){return _c('span',{key:answer.id,class:'question_' +
                question.question_id +
                ' ' +
                (question.answer_id == answer.id ? 'active' : ''),on:{"click":function($event){return _vm.answerQuestion(question.question, answer.id, $event)}}},[_vm._v(_vm._s(_vm.answers[index])+" ")])}),0)]):_c('div',{staticClass:"con-buble-sheet-card"},[_c('h1',[_vm._v("Question "+_vm._s(index + 1)+" :")]),_c('div',{staticClass:"con-answer-buble-sheet"},[_c('span',{class:'question_' +
                question.question_id +
                ' ' +
                (question.answer_id == 'true' ? 'active' : ''),on:{"click":function($event){return _vm.answerQuestion(question.question, 'true', $event)}}},[_vm._v(_vm._s(_vm.answers[0])+" ")]),_c('span',{class:'question_' +
                question.question_id +
                ' ' +
                (question.answer_id == 'false' ? 'active' : ''),on:{"click":function($event){return _vm.answerQuestion(question.question, 'false', $event)}}},[_vm._v(_vm._s(_vm.answers[1])+" ")])])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }