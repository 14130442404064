<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="con-allteacher-page">
          <h1>My Wallet</h1>
        </div>
        <div class="main-con-wallet-page">
          <div class="con-wallet-back-ground-img">
            <img src="@/assets/images/purse (3).png" alt="" />
          </div>
          <p class="wallet-amount">
            Your Wallet amount {{ student.student_wallet }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ListMyWallet",
  computed: {
    ...mapGetters("student", ["student"]),
  },
  methods: {
    ...mapActions("student", [
      "getStudentApi",
    ]),
  },
  beforeMount() {
    this.getStudentApi();
  },
};
</script>