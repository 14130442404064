<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="con-allteacher-page">
          <h1>All Teacher</h1>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for=" teacher in teachers" :key="teacher.id">
              <div class="con-teacher-card">
                <img
                 @click="$router.push('/teacher/'+ teacher.id)"
                  :src="teacher.profile_image"
                  alt=""
                /><span> <i class="fa-solid fa-star"></i>(3,5)</span>
                <p class="teacher-card-name" @click="$router.push('/teacher/'+ teacher.id)">{{teacher.name}}</p>
                <p class="teacher-card-department">{{teacher.subject_name}}</p>
                <button class="unfollw" v-if="teacher.is_followed" v-on:click.prevent="followTeacher(teacher.id)">unfollow </button>
                <button v-else v-on:click.prevent="followTeacher(teacher.id)">Follow</button>
              </div>
            </div>
          </div>
          <div class="con-pagination">
            <i class="fa-solid fa-angle-left"></i><span>1</span
            ><span class="active">2</span><span>3</span><span>4</span
            ><i class="fa-solid fa-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ListAllTeachers",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters("listAllTeachers", ["teachers", "nextPage"]),
  },
  methods: {
    ...mapActions("listAllTeachers", [
      "getTeachersApi",
      "getNextTeachersApi",
      "followTeacherApi",
    ]),
    followTeacher(id) {
      this.followTeacherApi(id).then(() => {
        const itemIndex = this.teachers.findIndex((x) => x.id === id);
        if (this.teachers[itemIndex]["is_followed"]) {
          this.teachers[itemIndex]["is_followed"] = 0;
        } else {
          this.teachers[itemIndex]["is_followed"] = 1;
        }
      });
    },
    handleScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.body.scrollHeight;
        if (bottomOfWindow && this.nextPage) {
          this.getNextTeachersApi();
        }
      };
    },
  },
  beforeMount() {
    this.getTeachersApi();
  },
};
</script>