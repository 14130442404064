<template>
  <div class="min-con-ins-coure-102">
    <div
      class="main-con-to-video"
      v-if="
        content
          ? content.content.content.type == 'iframe' ||
            content.content.content.type == 'youtube'
          : ''
      "
      v-html="content.content.content.video"
    ></div>
    <div
      class="main-con-to-video"
      v-else-if="content ? content.content.content.type == 'upload' : ''"
    >
      <video
        src="videos/دقيقه فيها حل لمشكلتين قاتلين دلوقت ليك 👈🏻👉🏻ثانويه عامه❤️.mp4"
      ></video>
    </div>
  </div>
</template>
<script>
export default {
  name: "Video",
  props: {
    content: Object,
  },
};
</script>
<style>
.main-con-to-video > iframe {
  width: 100%;
  max-width: 900px;
  height: 510px;
  border-radius: 20px;
}
</style>