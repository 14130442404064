<template>
  <div class="main-class-to-follow-teacher">
    <div class="main-class-to-follow-techer-header">
      <h2>Follow Teacher</h2>
      <router-link to="/list-all-teachers">See More</router-link>
    </div>
    <div class="main-class-to-follow-techer-body">
      <div class="class-to-follow-techer-card" v-for="teacher in teachersToFollow" :key="teacher.id">
        <img  :src="teacher.profile_image" alt=""  @click="$router.push('/teacher/' + teacher.id)"/>
        <div class="con-follow-teacher-body-content">
          <p @click="$router.push('/teacher/' + teacher.id)">{{ teacher.name }}</p>
          <span>{{ teacher.subject_name }}</span>
        </div>
        <button 
        @click="followTeacher(teacher)">{{!teacher.is_followed?'Follow':'Following'}}</button>
      </div>
    </div>
  </div>
  <!-- <div class="con-teacher-top-follow-side">
    <h1>Follow Teacher</h1>
    <div
      class="con-card-teacher-to-follow"
      v-for="teacher in teachersToFollow"
      :key="teacher.id"
    >
      <img
        @click="$router.push('/teacher/' + teacher.id)"
        :src="teacher.profile_image"
        alt=""
      />
      <div class="con-teacher-department-name">
        <p @click="$router.push('/teacher/' + teacher.id)">
          {{ teacher.name }}
        </p>
        <span>{{ teacher.subject_name }}</span>
      </div>
      <i
        v-if="!teacher.is_followed"
        v-on:click.prevent="followTeacher(teacher.id)"
        class="fa-solid fa-plus pointer"
      ></i>
    </div>
    <router-link to="/list-all-teachers">See More</router-link>
  </div> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TeacherToFollow",
  computed: {
    ...mapGetters("home", ["teachersToFollow"]),
  },
  methods: {
    ...mapActions("home", ["getTeachersToFollowApi", "followTeacherApi"]),
    followTeacher(teacher ) {
      teacher.is_followed = !teacher.is_followed;
      this.followTeacherApi(teacher.id)
    },
  },
  beforeMount() {
    this.getTeachersToFollowApi();
  },
};
</script>
