import axios from "@/axiosConfig";
import Vue from "vue";

export default ({
  state:{
    teachers: [],
    teachersToFollow: [],
    topLectures: [],
    nextPage: 0,
  },
  actions: {
    getTeachersApi({commit}){
        axios.get('/teachers-to-follow-list')
        .then(response => {
          commit('SET_TEACHERS', response);
         })
        .catch(error => {
          console.log(error)
        })
    },
    getNextTeachersApi({commit, state}){
      axios.get('/teachers-to-follow-list?page=' + state.nextPage)

      .then(response => {
        commit('SET_NEXT_TEACHERS', response);
       })
      .catch(error => {
        console.log(error)
      })
    },
    followTeacherApi(_, id){
      axios.post('/follow-teacher/' + id)
      .then(response => {
        Vue.$toast.success(response.data.message);
        console.log(response);
       })
      .catch(error => {
        Vue.$toast.error(error.response.data.message);
        console.log(error)
      })
    },
  },
  mutations: {
    SET_TEACHERS(state, response){
      state.teachers = response.data.data;
      state.nextPage = ((response.data.pagination.current_page + 1 ) <= response.data.pagination.last_page) ? response.data.pagination.current_page + 1  : 0;
    },
    SET_NEXT_TEACHERS(state, response){
      (response.data.data).forEach(element => {
        state.teachers.push(element);
      });
      console.log(response.data.pagination);
      state.nextPage = ((response.data.pagination.current_page + 1 ) <= response.data.pagination.last_page) ? response.data.pagination.current_page + 1  : 0;
    },
    SET_TEACHERS_TO_FOLLOW(state, response){
      state.teachersToFollow = response.data.data;
    },
    SET_TOP_LECTURES(state, response){
      state.topLectures = response.data.data;
    }
  },
  getters : {
    teachers: state => state.teachers,
    nextPage: state => state.nextPage,
  },
  namespaced: true,
});
