<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="con-allteacher-page">
          <h1>Search Terms</h1>
<div class="main-con-to-privacy-and-policy">
  <div class="main-search-terms-con">
    <div class="one-search-terms-card">
      <p> shehab  djvvnfvoj jdsojdsnfvj j ndfjdsnv </p>
      <span> 1 </span>
    </div>
    <div class="one-search-terms-card">
      <p> shehab  djvvnfvoj jdsojdsnfvj j ndfjdsnv </p>
      <span> 1 </span>
    </div>
    <div class="one-search-terms-card">
      <p> shehab  djvvnfvoj jdsojdsnfvj j ndfjdsnv </p>
      <span> 1 </span>
    </div>
    <div class="one-search-terms-card">
      <p> shehab  djvvnfvoj jdsojdsnfvj j ndfjdsnv </p>
      <span> 1 </span>
    </div>
    <div class="one-search-terms-card">
      <p> shehab  djvvnfvoj jdsojdsnfvj j ndfjdsnv </p>
      <span> 1 </span>
    </div>
    <div class="one-search-terms-card">
      <p> shehab  djvvnfvoj jdsojdsnfvj j ndfjdsnv </p>
      <span> 1 </span>
    </div><div class="one-search-terms-card">
      <p> shehab  djvvnfvoj jdsojdsnfvj j ndfjdsnv </p>
      <span> 1 </span>
    </div>
  </div>

</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axiosConfig";
export default{
  
  data(){
    return{
      allNotifications:[],
      nextpage:1,
      hasMore:false,
    }
  },
  methods:{
    getNextPostsApi(){
      axios.get(`/list-notifications?page=${this.nextpage}`).then((response)=>{
        this.allNotifications.push(...response.data.data);
        this.hasMore = response.data.pagination.has_more_pages;
        if(this.hasMore ){
        this.nextpage = response.data.pagination.current_page++;
        console.log(response.data.pagination.current_page++);
        }else{
          this.nextpage = 0
        }
      })
    },  
    handleScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.floor(document.documentElement.scrollTop + window.innerHeight) ===
          document.body.scrollHeight;
        if (bottomOfWindow && this.hasMore) {
          this.getNextPostsApi();
        }
      };
    },
    getAllNoti(){
      axios.get(`/list-notifications`).then((response)=>{
        this.allNotifications = response.data.data;
        this.hasMore = response.data.pagination.has_more_pages;
        if(this.hasMore ){
        this.nextpage = response.data.pagination.current_page + 1;
        }else{
          this.nextpage = 0
        }
      })
    },
    makeRead(id){
      axios.post(`/notification-mark-as-read/${id}`);
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount(){
    this.getAllNoti();
  }
};
</script>
<style scoped>
.main-con-to-privacy-and-policy{
  display:flex;
  align-items:center;
  justify-content:center;
  min-height:60vh;
}
.main-search-terms-con{
  margin:auto;
  max-width:800px;
  width:100%;
}
.one-search-terms-card{
  margin-bottom:15px;
  background-color:white;
  padding:20px;
  border-radius:10px;
  box-shadow:0px 0px 10px 0px #eee;
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100%;
}
.one-search-terms-card p{
  font-size:15px;
  color:#2d2d2d;
  font-weight:600;
  margin-bottom:0px ;
  
}
.one-search-terms-card span{
  font-size:13px;
  color:#816797;
  font-weight:600;
  margin-bottom:0px ;
  background-color:#dcd7e0;
  width:25px;
  height:25px;
  border-radius:50%;
  display:flex;
  align-items:center;
  justify-content:center;
  line-height:1;
}
</style>
