<template>
  <div class="main-con-class-top-lecture">
    <div class="con-class-top-lec-header">
      <h2>Top Lecture</h2>
      <router-link to="/list-top-lectures"> See All </router-link>
    </div>
    <div class="con-class-top-lec-body">
      <div
        class="main-con-class-lec-top-card"
        v-for="lecture in topLectures"
        :key="lecture.lecture_id"
      >
        <div
          class="con-img-of-top-lec-side-bar"
          v-bind:style="{
            background: `url( ${lecture.lecture_image}) no-repeat center center `,
          }"
        >
          <span>{{ lecture.lecture_subscription_fees }}</span>
        </div>
        <div class="con-info-bar-top-lec-class-action">
          <p>{{ lecture.lecture_title }}</p>
          <router-link :to="`/lecture/${lecture.lecture_id}`">
            {{lecture.lecture_is_parchased ? 'Show Now' : 'Buy Now'}}</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="con-top-lecture-side">
    <h1>Top Lecture</h1>
    <div
      class="con-card-lecture-top"
      v-for="lecture in topLectures"
      :key="lecture.lecture_id"
    >
      <div
        class="con-img-lecture-top pointer"
        @click="$router.push('/lecture/' + lecture.lecture_id)"
      >
        <img :src="lecture.lecture_image" alt="" />
        <p class="lecture-top-name">
          {{ lecture.lecture_title }}
        </p>
        <span>{{ lecture.lecture_subscription_fees }}</span>
      </div>
    </div>
    <router-link to="/list-top-lectures">See More</router-link>
  </div> -->
</template>

<script>
export default {
  name: "TopLectures",
  props: {
    topLectures: Array,
  },
};
</script>
