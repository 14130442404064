<template>
  <!-- <div class="container-fluid">
    <div class="row row-0001">
      <div class="col-12 main-col-12">
        <div class="con-h1-head-courses h1-mar-bottom">
          <h1>Edit Information</h1>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="con-edit-img-23">
                <img
                  :src="student.student_profile_image"
                  alt=""
                  class="img-301"
                />
                <div class="con-update-img-button">
                  <div>
                    <input
                      type="file"
                      name="update-img"
                      id="update-img"
                      @change="profileImageHandel"
                    />
                    <label for="update-img">
                      Change Photo
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#2680EB"
                        class="bi bi-camera-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"
                        />
                        <path
                          d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                        /></svg></label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="container con-207">
                <div class="con-form-edit-info-045">
                  <h1 class="edit-info-556">Edit Information</h1>
                  <div class="form-237 form-2021">
                    <input
                      type="text"
                      placeholder="Enter Your name"
                      v-model="values.name"
                      name="name"
                    />

                    <label for="">
                      <svg
                        class="name-holder"
                        xmlns:dc="http://purl.org/dc/elements/1.1/"
                        xmlns:cc="http://creativecommons.org/ns#"
                        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                        xmlns:svg="http://www.w3.org/2000/svg"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        viewBox="0 0 23.812499 29.76562625"
                        x="0px"
                        y="0px"
                      >
                        <g transform="translate(0,-273.187)" display="inline">
                          <path
                            d="M 7.1993875,285.7861 C 4.3762966,286.52529 2.598847,288.31562 2.0224443,290.83023 1.8547249,291.54967 2.1146251,291.7597 2.4374062,291.85911 4.6563131,292.54246 8.7631861,292.78516 10.374906,292.78516 11.011187,292.78516 12.042125,292.74434 13.18698,292.65624 L 16.379707,287.12504 C 15.603112,286.53061 14.658592,286.07632 13.550422,285.7861 L 10.632253,288.23039 C 10.483642,288.35484 10.267202,288.35484 10.118591,288.23039 Z"
                            display="inline"
                            fill="#2680EB"
                            stroke="none"
                          />
                          <path
                            style="paint-order: stroke fill markers"
                            d="M 10.374736,275.16067 C 7.919535,275.16067 5.942965,277.15823 5.942965,279.63946 V 279.94228 C 5.942965,282.4235 7.919535,284.42108 10.374736,284.42108 12.829937,284.42108 14.806507,282.4235 14.806507,279.94228 V 279.63946 C 14.806507,277.15823 12.829937,275.16067 10.374736,275.16067 Z"
                            fill="#2680EB "
                            fill-opacity="1"
                            fill-rule="evenodd"
                            stroke="none"
                          />
                          <path
                            style="paint-order: stroke fill markers"
                            d="M 17.68147,287.70948 14.77059,292.75127 15.20941,295.02583 17.39867,294.2689 20.31009,289.22656 Z"
                            fill="#2680EB "
                            fill-opacity="1"
                            fill-rule="evenodd"
                            stroke="none"
                          />
                          <path
                            style="paint-order: stroke fill markers"
                            d="M 19.76819,284.83269 C 19.49987,284.85144 19.24602,284.99967 19.10084,285.25112 L 18.07952,287.02013 20.70814,288.53722 21.72946,286.76875 C 21.96174,286.36642 21.82494,285.85517 21.42261,285.62288 L 20.24617,284.94373 C 20.09529,284.85663 19.92917,284.82143 19.76819,284.83269 Z"
                            fill="#2680EB "
                            fill-opacity="1"
                            fill-rule="evenodd"
                            stroke="none"
                          />
                        </g>
                      </svg>
                      <span>Change Name</span>
                    </label>
                  </div>
                  <div class="form-237 form-2021">
                    <input
                      type="text"
                      placeholder="Enter Your Phone Number"
                      v-model="values.phone_number"
                      name="phone_number"
                    />

                    <label for="">
                      <svg
                        class="Code-icon-12"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 64 80"
                        enable-background="new 0 0 64 64"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            d="M13.8,1.7c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C19.8,4.4,17.1,1.7,13.8,1.7z M13.8,10.8c-1.7,0-3-1.3-3-3   c0-1.7,1.3-3,3-3c1.7,0,3,1.3,3,3C16.8,9.4,15.5,10.8,13.8,10.8z"
                          />
                          <path
                            d="M32,1.7c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C38,4.4,35.3,1.7,32,1.7z M32,10.8c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3   s3,1.3,3,3C35,9.4,33.7,10.8,32,10.8z"
                          />
                          <path
                            d="M50.2,13.8c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6s-6,2.7-6,6C44.2,11.1,46.9,13.8,50.2,13.8z M50.2,4.7c1.7,0,3,1.3,3,3   c0,1.7-1.3,3-3,3s-3-1.3-3-3C47.2,6.1,48.5,4.7,50.2,4.7z"
                          />
                          <path
                            d="M13.8,18.9c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C19.8,21.6,17.1,18.9,13.8,18.9z M13.8,27.9c-1.7,0-3-1.3-3-3   c0-1.7,1.3-3,3-3c1.7,0,3,1.3,3,3C16.8,26.5,15.5,27.9,13.8,27.9z"
                          />
                          <path
                            d="M32,18.9c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C38,21.6,35.3,18.9,32,18.9z M32,27.9c-1.7,0-3-1.3-3-3   c0-1.7,1.3-3,3-3s3,1.3,3,3C35,26.5,33.7,27.9,32,27.9z"
                          />
                          <path
                            d="M50.2,18.9c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C56.2,21.6,53.5,18.9,50.2,18.9z M50.2,27.9c-1.7,0-3-1.3-3-3   c0-1.7,1.3-3,3-3s3,1.3,3,3C53.2,26.5,51.9,27.9,50.2,27.9z"
                          />
                          <path
                            d="M13.8,36c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C19.8,38.7,17.1,36,13.8,36z M13.8,45c-1.7,0-3-1.3-3-3   c0-1.7,1.3-3,3-3c1.7,0,3,1.3,3,3C16.8,43.7,15.5,45,13.8,45z"
                          />
                          <path
                            d="M32,36c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C38,38.7,35.3,36,32,36z M32,45c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3   s3,1.3,3,3C35,43.7,33.7,45,32,45z"
                          />
                          <path
                            d="M32,50.2c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C38,52.9,35.3,50.2,32,50.2z M32,59.3c-1.7,0-3-1.3-3-3   c0-1.7,1.3-3,3-3s3,1.3,3,3C35,57.9,33.7,59.3,32,59.3z"
                          />
                          <path
                            d="M50.2,36c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6C56.2,38.7,53.5,36,50.2,36z M50.2,45c-1.7,0-3-1.3-3-3   c0-1.7,1.3-3,3-3s3,1.3,3,3C53.2,43.7,51.9,45,50.2,45z"
                          />
                        </g>
                      </svg>
                      <span> Change Phone Number</span>
                    </label>
                  </div>
                  <div class="form-submit-948">
                    <input
                      type="submit"
                      value=""
                      @click.prevent="changeProfileInfoHandel"
                      id="Sumit-457"
                    />
                    <label for="Sumit-457">Save</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4">
             <div class="con-edit-img-23">
                <img
                  :src="student.student_profile_image"
                  alt=""
                  class="img-301"
                />
                <div class="con-update-img-button">
                  <div>
                    <input
                      type="file"
                      name="update-img"
                      id="update-img"
                      @change="profileImageHandel"
                    />
                    <label for="update-img">
                      Change Photo
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#2680EB"
                        class="bi bi-camera-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"
                        />
                        <path
                          d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
                        /></svg
                    ></label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="container con-207">
                <div class="con-form-edit-info-045">
                  <h1 class="edit-info-556">Password</h1>
                  <div class="form-237 form-2021">
                    <input
                      :type="passwordStatus ? 'text' : 'password'"
                      v-model="values.password"
                      placeholder="*************"
                      name="password"
                    />

                    <svg
                      v-show="passwordStatus"
                      @click="passwordToggleShow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path
                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                      />
                    </svg>
                    <svg
                      v-show="!passwordStatus"
                      @click="passwordToggleShow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye-slash-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"
                      />
                      <path
                        d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"
                      />
                    </svg>
                    <label for="">
                      <span>Change password</span>
                    </label>
                  </div>
                  <div class="form-237 form-2021">
                    <input
                      :type="passwordConfirmationStatus ? 'text' : 'password'"
                      v-model="values.passwordConfirmation"
                      placeholder="*************"
                      name="passwordConfirmation"
                    />

                    <svg
                      v-show="passwordConfirmationStatus"
                      @click="passwordConfirmationToggleShow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path
                        d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                      />
                    </svg>
                    <svg
                      v-show="!passwordConfirmationStatus"
                      @click="passwordConfirmationToggleShow"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-eye-slash-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"
                      />
                      <path
                        d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"
                      />
                    </svg>
                    <label for="">
                      <span>Change Confirm Password</span>
                    </label>
                  </div>
                  <div class="form-submit-948">
                    <input
                      type="button"
                      value=""
                      @click.prevent="changeProfileHandel"
                      id="Sumit-457"
                    />
                    <label for="Sumit-457">Save</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="main-con-edit-profile-page">
        <div class="row">
          <div class="col-12 col-md-4">
            <div
              class="
                con-card-teacher-img-profile con-card-teacher-img-profile-1
              "
            >
              <!-- <img :src="student.student_profile_image" alt="" /> -->
              <my-upload field="image"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
        v-model="show"
		:width="300"
		:height="300"
		url="https://udegram-lumen.evoola.com/public/student/profile/update"
		:params="params"
    :lang-ext="en"
		:headers="headers"
		img-format="png"></my-upload>
    <img :src="imgDataUrl">
              <div class="img-profile-back-ground" v-bind:style="{
            background: `url( ${student.student_profile_image}) no-repeat center center `,
          }"></div>
              <p class="side-card-teacher-name">{{ student.student_name }}</p>
              <p class="side-card-teacher-department">#{{ student.student_id }}</p>
              <div class="con-side-card-follow-button">
                <!-- <input
                  type="file"
                  id="change-photo-id"
                  @change="profileImageHandel"
                /> -->
                <label class="edit-photo-label" @click="toggleShow">
                  <i class="fa-solid fa-camera-retro"></i>Edit Image</label
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="con-form-edit-info">
              <h1>Edit Information</h1>
              <div class="con-input-form-sign">
                <label for="">Change Name</label>
                <input
                  type="email"
                  placeholder="Enter Your Name"
                  v-model="values.name"
                /><i class="fa-regular fa-user"></i>
              </div>
              <div class="con-input-form-sign">
                <label for="">Change Phone Number </label>
                <input
                  type="number"
                  placeholder="Enter Your Father's Phone number "
                  value="01154868210"
                />
                <i class="fa-solid fa-mobile-screen-button"></i>
              </div>

              <div class="con-side-card-follow-button">
                <button
                  class="edit-photo-label"
                  @click.prevent="changeProfileInfoHandel"
                >
                  Save
                </button>
              </div>
            </div>
            <div class="con-form-edit-info">
              <h1>Edit Password</h1>
              <div class="con-input-form-sign">
                <label for="">Change Password</label>
                <input
                  placeholder="Enter Your Password"
                  :type="passwordStatus ? 'text' : 'password'"
                  v-model="values.password"
                /><i
                  v-show="passwordStatus"
                  @click="passwordToggleShow"
                  class="fa-regular fa-eye"
                ></i>
                <i
                  v-show="!passwordStatus"
                  @click="passwordToggleShow"
                  class="fa-regular fa-eye-slash"
                ></i>
              </div>
              <div class="con-input-form-sign">
                <label for="">Confirm Password</label>
                <input
                  :type="passwordConfirmationStatus ? 'text' : 'password'"
                  v-model="values.passwordConfirmation"
                  placeholder="Enter Your Password"
                /><i
                  v-show="passwordConfirmationStatus"
                  @click="passwordConfirmationToggleShow"
                  class="fa-regular fa-eye"
                ></i>
                <i
                  v-show="!passwordConfirmationStatus"
                  @click="passwordConfirmationToggleShow"
                  class="fa-regular fa-eye-slash"
                ></i>
              </div>
              <div class="con-side-card-follow-button">
                <button
                  class="edit-photo-label"
                  @click.prevent="changeProfileHandel"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as Yup from "yup";
	import myUpload from 'vue-image-crop-upload/upload-2.vue';
const profileInfoSchema = Yup.object().shape({
  name: Yup.string().required(),
  phone_number: Yup.number().required(),
});

export default {
  name: "ProfileEdit",
  components:{
    myUpload
  },
  data() {
    return {
      en: {
		hint: 'Click or drag the file here to upload',
		loading: 'Uploading…',
		noSupported: 'Browser is not supported, please use IE10+ or other browsers',
		success: 'Upload success',
		fail: 'Upload failed',
		preview: 'Preview',
		btn: {
			off: 'Cancel',
			close: 'Close',
			back: 'Back',
			save: 'Save'
		},
		error: {
			onlyImg: 'Image only',
			outOfSize: 'Image exceeds size limit: ',
			lowestPx: 'Image\'s size is too low. Expected at least: '
		}
	},
      image: "",
      passwordStatus: 0,
      passwordConfirmationStatus: 0,
      values: {
        name: "",
        phone_number: "",
        password: "",
        passwordConfirmation: "",
      },
      show: false,
			params: {
				"_method":"PATCH"
			},
			headers: {
				"Authorization":"",
			},
			imgDataUrl: '',
      errors: {
        name: "",
        phone_number: "",
        password: "",
        passwordConfirmation: "",
      },
    };
  },
  computed: {
    ...mapGetters("student", ["student"]),
    ...mapGetters("auth", ["user","getUserToken"]),
  },
  methods: {
    ...mapActions("student", ["getStudentApi", "updateProfileApi"]),
    validate(field) {
      profileInfoSchema.validateAt(field, this.values).catch(() => {});
    },
    toggleShow() {
				this.show = !this.show;
			},
            /**
			 * crop success
			 *
			 * [param] imgDataUrl
			 * [param] field
			 */
			cropSuccess(imgDataUrl){
				console.log('-------- crop success --------');
				this.imgDataUrl = imgDataUrl;
        
			},
			/**
			 * upload success
			 *
			 * [param] jsonData  server api return data, already json encode
			 * [param] field
			 */
			cropUploadSuccess(jsonData, field){
				console.log('-------- upload success --------');
				console.log(jsonData);
				console.log('field: ' + field);
        
        this.student.student_profile_image = this.imgDataUrl;
        this.user.profile_image_full_path = this.imgDataUrl;
        var user = JSON.parse(localStorage.getItem("user"));
        user.profile_image_full_path = jsonData.data.student_profile_image;
        localStorage.setItem("user", JSON.stringify(user));
			},
			/**
			 * upload fail
			 *
			 * [param] status    server api return error status, like 500
			 * [param] field
			 */
			cropUploadFail(status, field){
				console.log('-------- upload fail --------');
				console.log(status);
				console.log('field: ' + field);
        console.log(this.imgDataUrl);
			},
    setProfileValues() {
      this.values.name = this.student.student_name;
      this.values.phone_number = this.student.student_phone_number;
    },
    passwordToggleShow() {
      this.passwordStatus = !this.passwordStatus;
    },
    passwordConfirmationToggleShow() {
      this.passwordConfirmationStatus = !this.passwordConfirmationStatus;
    },
    profileImageHandel(event) {
      this.image = event.target.files[0];
      this.changeProfileImageHandel();
    },
    changeProfileImageHandel() {
      const formData = new FormData();
      formData.append("image", this.image);
      formData.append("_method", "patch");
      this.updateProfileApi(formData).then((res) => {
        this.student.student_profile_image = URL.createObjectURL(this.image);
        this.user.profile_image_full_path = URL.createObjectURL(this.image);
        var user = JSON.parse(localStorage.getItem("user"));
        user.profile_image_full_path = res.data.data.student_profile_image;
        localStorage.setItem("user", JSON.stringify(user));
      });
    },
    changeProfileInfoHandel() {
      profileInfoSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.errors = {};
          let data = {
            name: this.values.name,
            phone_number: this.values.phone_number,
            _method: "patch",
          };
          this.updateProfileApi(data);
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },

    changeProfileHandel() {
      console.log(0);
      let data = {
        password: this.values.password,
        _method: "patch",
      };
      this.updateProfileApi(data);
    },
  },
  beforeMount() {
    this.getStudentApi();
  },
  mounted() {
    this.setProfileValues();
    this.headers.Authorization = "Bearer " + this.getUserToken;
    console.log(this.getUserToken);
    
  },
};
</script>

<style scoped>
.form-2021 svg {
  fill: #2680eb !important;
  color: #2680eb;
}
</style>