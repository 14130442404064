<template>
  <!-- <div class="container-fluid con-flu-456 con-flu-457 con-flu-4231">
    <img
      src="@/assets/img/tms empty.png"
      alt=""
      class="img-sign-up-096 img-sign-up-022"
    />
    <div class="container con-9020 con-9021 con-9033">
      <div class="con-563-sign-up">
        <div class="container">
          <h1>Reset Password Form</h1>
          <form @submit.prevent="resetPassword">
            <p class="p-Verification-Code">Reset Password</p>
            <div class="new-form-237 new-form-237-34">
              <input
              :type="passwordStatus ? 'text' : 'password'"
                v-model="values.password"
                placeholder="Enter Your password"
                @blur="validate('password')"
                @keypress="validate('password')"
                :class="['form-control', !!errors.password && 'is-invalid']"
              />
              <p
                :class="[
                  'form-input-hint',
                  !!errors.password && 'invalid-feedback',
                ]"
                v-if="!!errors.password"
              >
                {{ errors.password }}
              </p>

            <svg
              v-show="passwordStatus"
              @click="passwordToggleShow"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-eye-fill-113"
              viewBox="0 0 16 16"
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path
                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
              />
            </svg>
            <svg
              v-show="!passwordStatus"
              @click="passwordToggleShow"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-eye-slash-fill-113"
              viewBox="0 0 16 16"
            >
              <path
                d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"
              />
              <path
                d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"
              />
            </svg>
            </div>
            <div class="new-form-237">
              <input
              :type="passwordConfirmStatus ? 'text' : 'password'"
                v-model="values.passwordConfirmation"
                placeholder="Confirm Your password"
                @blur="validate('passwordConfirmation')"
                @keypress="validate('passwordConfirmation')"
                :class="['form-control', !!errors.passwordConfirmation && 'is-invalid']"
              />
              <p
                :class="[
                  'form-input-hint',
                  !!errors.passwordConfirmation && 'invalid-feedback',
                ]"
                v-if="!!errors.passwordConfirmation"
              >
                {{ errors.passwordConfirmation }}
              </p>
            <svg
              v-show="passwordConfirmStatus"
              @click="passwordConfirmToggleShow"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-eye-fill-113"
              viewBox="0 0 16 16"
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path
                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
              />
            </svg>
            <svg
              v-show="!passwordConfirmStatus"
              @click="passwordConfirmToggleShow"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-eye-slash-fill-113"
              viewBox="0 0 16 16"
            >
              <path
                d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"
              />
              <path
                d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"
              />
            </svg>
            </div>
            <div>
              <input type="submit" value="" id="submit-5843" />
              <label
                for="submit-5843"
                class="submit-5843 submit-58223 submit-58223-4"
              >
                Save
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->
  <div>
    <div class="banner-sign-page"></div>
    <div class="main-con-to-sign-form">
      <form @submit.prevent="resetPassword">
        <div class="con-logo-letter">
          <img src="@/assets/images/logo fav.icon.png" alt="" />
        </div>
        <h1>Reset Password</h1>
        <div class="con-input-form-sign">
          <label for="">Password</label>
          <input
            :type="passwordStatus ? 'text' : 'password'"
            v-model="values.password"
            placeholder="Enter Your password"
            @blur="validate('password')"
            @keypress="validate('password')"
            :class="['form-control', !!errors.password && 'is-invalid']"
          />
          <p
            :class="[
              'form-input-hint',
              !!errors.password && 'invalid-feedback',
            ]"
            v-if="!!errors.password"
          >
            {{ errors.password }}
          </p>
          <i
            v-show="passwordStatus"
            @click="passwordToggleShow"
            class="fa-regular fa-eye"
          ></i
          ><i
            v-show="!passwordStatus"
            @click="passwordToggleShow"
            class="fa-regular fa-eye-slash"
          ></i>
        </div>
        <div class="con-input-form-sign">
          <label for="">Confirm Password</label>
          <input
            :type="passwordConfirmStatus ? 'text' : 'password'"
            v-model="values.passwordConfirmation"
            placeholder="Confirm Your password"
            @blur="validate('passwordConfirmation')"
            @keypress="validate('passwordConfirmation')"
            :class="[
              'form-control',
              !!errors.passwordConfirmation && 'is-invalid',
            ]"
          />
          <p
            :class="[
              'form-input-hint',
              !!errors.passwordConfirmation && 'invalid-feedback',
            ]"
            v-if="!!errors.passwordConfirmation"
          >
            {{ errors.passwordConfirmation }}
          </p>
          <i
            v-show="passwordConfirmStatus"
            @click="passwordConfirmToggleShow"
            class="fa-regular fa-eye"
          ></i
          ><i
            v-show="!passwordConfirmStatus"
            @click="passwordConfirmToggleShow"
            class="fa-regular fa-eye-slash"
          ></i>
        </div>
        <div class="con-input-submit">
          <input type="submit" value="Save" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as Yup from "yup";

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export default {
  name: "ResetPassword",
  data() {
    return {
      passwordStatus: 0,
      passwordConfirmStatus: 0,
      values: {
        password: "",
        passwordConfirmation: "",
      },
      errors: {
        password: "",
        passwordConfirmation: "",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["resetPasswordApi"]),
    validate(field) {
      resetPasswordSchema.validateAt(field, this.values).catch(() => {});
    },
    resetPassword: function () {
      resetPasswordSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.errors = {};
          let password = this.values.password;
          let password_confirmation = this.values.passwordConfirmation;
          let token = this.$route.query.token;
          let email = this.$route.query.email;
          console.log({
            password,
            password_confirmation,
            token,
            email,
          });
          this.resetPasswordApi({
            password,
            password_confirmation,
            token,
            email,
          })
            .then(() => this.$router.push("/login"))
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },
    passwordToggleShow() {
      this.passwordStatus = !this.passwordStatus;
    },
    passwordConfirmToggleShow() {
      this.passwordConfirmStatus = !this.passwordConfirmStatus;
    },
  },
};
</script>