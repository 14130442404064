<template>
  <div>
    <div class="min-con-ins-coure-102">
      <div class="con-rate-ins-course">
        <div class="con-rate-lec-card">
          <div class="back-ground-rate">
            <div class="con-back-img-for-rate">
              <img src="@/assets/images/stars.png" alt="" />
            </div>
          </div>
          <div class="con-rate-lec-content">
            <h1>Rate Lecture</h1>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestias,
            neque fugit optio adipisci corporis iure suscipit nihil eveniet,
            sunt enim deserunt est pariatur rem ea eos numquam accusamus saepe
            officia.
          </p>
          <star-rating
            :item-size="20"
            :spacing="3"
            :max-rating="5"
            :rounded-corners="true"
            :border-width="0"
            :show-rating="false"
            :padding="15"
            :rating="rate"
            @rating-selected ="setRating"
          ></star-rating>
          <div class="con-buttons-of-rate-or-skip">
            <button @click="rateing()">Rate </button>
            <button @click="skip()" v-if="rate != 0"> Skip</button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import axios from "@/axiosConfig";
import { mapGetters } from "vuex";
export default {
  data(){
    return{
      rate:0,
    }
  },
  components: {
    StarRating,
  },
  computed: {
    ...mapGetters("lecturePurchased", ["lecture","studentRate"]),
  },
  methods:{
    setRating(rating){
      
      this.rate = rating
    },
    rateing(){
      axios.post(`lectures/lecture-rate/${this.lecture.id}`,{'student_rate':this.rate})
    },
    skip(){
      this.rate = 0;
      axios.post(`lectures/lecture-rate/${this.lecture.id}`,{'student_rate':this.rate})
    }
  },
  beforeMount () {
    this.rate = this.studentRate
  }
};
</script>

<style></style>
