<template>
  <div class="main-con-to-side-bar-2">
    <div class="con-side-course-page-details">
      <img :src="teacher.teacher_profile_image" alt="" />
      <div class="con-side-course-page-icon-info">
        <i class="fa-solid fa-user"></i>
        <p>Instractor :</p>
        <span
          >{{ teacher.teacher_name }} ({{ teacher.teacher_subject_name }})</span
        >
      </div>
      <hr />
      <div class="con-side-course-page-icon-info">
        <i class="fa-solid fa-chalkboard-user"></i>
        <p>Content :</p>
        <span>{{ lecture.lecture_contents_count }}</span>
      </div>
      <hr />
      <div class="con-side-course-page-icon-info">
        <i class="fa-solid fa-hourglass-end"></i>
        <p>Duration :</p>
        <span>{{ lecture.lecture_duration }}</span>
      </div>
      <hr />
      <div class="con-side-course-page-icon-info">
        <i class="fa-solid fa-graduation-cap"></i>
        <p>Education level :</p>
        <span>{{ lecture.lecture_education_level_name }}</span>
      </div>
      <hr />
      <div class="con-side-course-page-icon-info no-border">
        <i class="fa-solid fa-money-bill-1-wave"></i>
        <p>Price :</p>
        <h1>{{ lecture.lecture_subscription_fees }}</h1>
      </div>
      <button
        v-if="
          !lecture.lecture_is_parchased &&
          !lecture.lecture_subscription_fees_amount
        "
        @click="purchaseLectureFromWallet"
      >
        Buy Now
      </button>
      <button v-else-if="!lecture.lecture_is_parchased" v-b-modal.modal-1>
        Buy Now
      </button>
      <button
        v-else
        @click="$router.push('/lecture-purchased/' + $route.params.id)"
      >
        Show Now
      </button>
    </div>
    <b-modal
      size="lg"
      id="modal-1"
      header="test"
      header-class="justify-content-center"
      title="Confirm Approval"
      hide-footer
      hide-header
    >
      <div class="main-con-tabs-pop-up-pay">
        <ul>
          <li @click="switchtabPopUp" class="active" data-cont=".con-fawry-tab">
            Pay with Fawry
          </li>
          <li @click="switchtabPopUp" data-cont=".con-visa-tab">
            Pay with Visa
          </li>
          <li @click="switchtabPopUp" data-cont=".con-coupon-tab">
            Pay with Coupon
          </li>
        </ul>
        <div class="main-tabs-pop-up-pay-contont">
          <div class="con-fawry-tab">
            <button
              v-if="!lecture.lecture_pending_fawry_code"
              class="pay-with-fawry-button"
              @click="purchaseLectureByFawry"
            >
              Pay With Fawry
            </button>
            <div v-else-if="lecture.lecture_pending_fawry_code">
              <img src="@/assets/images/pos (2).png" alt="" />
              <p>Pay with fawry on 789</p>
              <button class="copy-code">
                <span @click="copy(lecture.lecture_pending_fawry_code)">{{
                  lecture.lecture_pending_fawry_code
                }}</span
                ><i class="fa-solid fa-copy"></i>
              </button>
            </div>
          </div>
          <div class="con-visa-tab">
            <form @submit.prevent="purchaseLectureByCard">
              <div class="con-input-form-sign">
                <label for="">Card holder name</label>
                <input
                  type="text"
                  placeholder="Enter Card holder name"
                  v-model="cardValues.card_name"
                  :class="[
                    'form-control',
                    !!cardErrors.card_name && 'is-invalid',
                  ]"
                />
                <p
                  :class="[
                    'form-input-hint',
                    !!cardErrors.card_name && 'invalid-feedback',
                  ]"
                  v-if="!!cardErrors.card_name"
                >
                  {{ cardErrors.card_name }}
                </p>
              </div>
              <div class="con-input-form-sign">
                <label for="">Card number</label>
                <input
                  type="number"
                  placeholder="Enter Card number"
                  v-model="cardValues.card_number"
                  :class="[
                    'form-control',
                    !!cardErrors.card_number && 'is-invalid',
                  ]"
                />

                <p
                  :class="[
                    'form-input-hint',
                    !!cardErrors.card_number && 'invalid-feedback',
                  ]"
                  v-if="!!cardErrors.card_number"
                >
                  {{ cardErrors.card_number }}
                </p>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="con-input-form-sign">
                    <label for="">Expired Month</label>
                    <select
                      :class="[
                        'form-control',
                        !!cardErrors.card_expired_month && 'is-invalid',
                      ]"
                      id="cardMonth"
                      v-model="cardValues.card_expired_month"
                      data-ref="cardDate"
                    >
                      <option value="" disabled selected>Month</option>
                      <option
                        v-bind:value="n < 10 ? '0' + n : n"
                        v-for="n in 12"
                        v-bind:disabled="n < minCardMonth"
                        v-bind:key="n"
                      >
                        {{ 10 > n ? "0" + n : n }}
                      </option>
                    </select>

                    <p
                      :class="[
                        'form-input-hint',
                        !!cardErrors.card_expired_month && 'invalid-feedback',
                      ]"
                      v-if="!!cardErrors.card_expired_month"
                    >
                      {{ cardErrors.card_expired_month }}
                    </p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="con-input-form-sign">
                    <label for="">Expired Year</label>
                    <select
                      :class="[
                        'form-control',
                        !!cardErrors.card_expired_year && 'is-invalid',
                      ]"
                      id="cardYear"
                      v-model="cardValues.card_expired_year"
                      @change="checkMonth"
                      data-ref="cardDate"
                    >
                      <option value="" disabled selected>Year</option>
                      <option
                        v-bind:value="$index + minCardYear"
                        v-for="(n, $index) in 12"
                        v-bind:key="n"
                      >
                        {{ $index + minCardYear }}
                      </option>
                    </select>
                    <p
                      :class="[
                        'form-input-hint',
                        !!cardErrors.card_expired_year && 'invalid-feedback',
                      ]"
                      v-if="!!cardErrors.card_expired_year"
                    >
                      {{ cardErrors.card_expired_year }}
                    </p>
                  </div>
                </div>
                <div class="col-4">
                  <div class="con-input-form-sign">
                    <label for="">CVV</label>
                    <input
                      type="number"
                      placeholder="###"
                      v-model="cardValues.card_cvv"
                      :class="[
                        'form-control',
                        !!cardErrors.card_cvv && 'is-invalid',
                      ]"
                    />

                    <p
                      :class="[
                        'form-input-hint',
                        !!cardErrors.card_cvv && 'invalid-feedback',
                      ]"
                      v-if="!!cardErrors.card_cvv"
                    >
                      {{ cardErrors.card_cvv }}
                    </p>
                  </div>
                </div>
              </div>
              <input class="pay-with-visa" type="submit" value="Pay" />
            </form>
          </div>
          <div class="con-coupon-tab">
            <form @submit.prevent="purchaseLectureByCoupon">
              <div
                :class="[
                  'con-input-pay-with-coupon',
                  !!couponErrors.coupon && 'is-invalid',
                ]"
              >
                <input
                  type="text"
                  v-model="couponValues.coupon"
                  placeholder="Enter Your Coupon"
                  @blur="validate('coupon')"
                  @keypress="validate('coupon')"
                />
                <button type="submit">Pay</button>
              </div>
              <p
                :class="[
                  'form-input-hint',
                  !!couponErrors.coupon && 'invalid-feedback',
                ]"
                v-if="!!couponErrors.coupon"
              >
                {{ couponErrors.coupon }}
              </p>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import * as Yup from "yup";

const couponSchema = Yup.object().shape({
  coupon: Yup.string().required(),
});

const cardSchema = Yup.object().shape({
  coupon: Yup.string().required(),
});

export default {
  name: "LectureSidebar",

  data: function () {
    return {
      minCardYear: new Date().getFullYear(),
      cardYear: "",
      minCardMonth: 0,
      cardValues: {
        card_name: "",
        card_number: "",
        card_expired_month: "",
        card_expired_year: "",
        card_cvv: "",
      },
      cardErrors: {
        card_name: "",
        card_number: "",
        card_expired_month: "",
        card_expired_year: "",
        card_cvv: "",
      },
      couponValues: {
        coupon: "",
      },
      couponErrors: {
        coupon: "",
      },
    };
  },
  computed: {
    ...mapGetters("lecture", ["lecture", "teacher"]),
  },
  methods: {
    ...mapActions("lecture", [
      "purchaseLectureByCouponApi",
      "purchaseLectureFromWalletApi",
      "purchaseLectureByFawryApi",
      "purchaseLectureByCardApi",
    ]),
    switchtabPopUp(event) {
      event.preventDefault();
      document
        .querySelector(".main-con-tabs-pop-up-pay li.active")
        .classList.remove("active");
      let divsCourse = document.querySelectorAll(
        ".main-tabs-pop-up-pay-contont > div"
      );
      let divsArrayCourse = Array.from(divsCourse);
      divsArrayCourse.forEach((div) => {
        div.style.display = "none";
      });
      var clickedtab = event.currentTarget;
      clickedtab.classList.add("active");
      document.querySelector(event.currentTarget.dataset.cont).style.display =
        "block";
    },
    validate(field) {
      couponSchema.validateAt(field, this.couponValues).catch(() => {});
    },
    purchaseLectureByCard() {
      cardSchema
        .validate(this.cardValues, { abortEarly: false })
        .then(() => {
          this.errors = {};
          this.purchaseLectureByCardApi(this.cardValues)
            .then(() =>
              this.$router.push("/lecture-purchased/" + this.lecture.lecture_id)
            )
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.cardValues[error.path] = error.message;
          });
        });
    },
    purchaseLectureByFawry() {
      this.purchaseLectureByFawryApi()
        .then(
          (data) =>
            (this.lecture.lecture_pending_fawry_code =
              data.data.data.reference_number)
        )
        .catch((err) => console.log(err));
    },
    purchaseLectureByCoupon() {
      couponSchema
        .validate(this.couponValues, { abortEarly: false })
        .then(() => {
          this.errors = {};
          this.purchaseLectureByCouponApi(this.couponValues.coupon)
            .then(() =>
              this.$router.push("/lecture-purchased/" + this.lecture.lecture_id)
            )
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.couponErrors[error.path] = error.message;
          });
        });
    },
    purchaseLectureFromWallet() {
      this.purchaseLectureFromWalletApi()
        .then(() =>
          this.$router.push("/lecture-purchased/" + this.lecture.lecture_id)
        )
        .catch((err) => console.log(err));
    },
    copy(mytext) {
      navigator.clipboard.writeText(mytext);
      Vue.$toast.success("Copied");
    },
    checkMonth() {
      if (new Date().getFullYear() == this.cardValues.card_expired_year) {
        this.minCardMonth = new Date().getMonth() + 1;
        this.cardValues.card_expired_month = '';
      }else{
        this.minCardMonth = 0;
      }
    },
  },
};
</script>

<style>
.modal-body {
  padding: 0rem !important;
}
</style>