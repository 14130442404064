<template>
  <!-- <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-9">
          <h1 class="lecture-tittle-ins">{{ lecture.lecture_title }}</h1>
          <div class="main-con-top-nav-bar-ins">
            <img
              @click="$router.push('/teacher/' + lecture.teacher_id)"
              :src="teacher.teacher_profile_image"
              alt=""
              class="img-teacher-top-nav"
            />

            <div class="con-top-nav-bar-ins">
              <p
                class="p-teacher-top-nav-bar-01"
                @click="$router.push('/teacher/' + lecture.teacher_id)"
              >
                Teacher
              </p>
              <a
                href="#"
                class="p-teacher-name-top-nav-bar-01"
                @click="$router.push('/teacher/' + lecture.teacher_id)"
                >{{ teacher.teacher_name }}</a
              >
            </div>
            <div class="con-top-nav-bar-ins">
              <p class="time-title-ins">Created At</p>
              <p class="time-ins">{{ lecture.lecture_created_at }}</p>
            </div>
            <div class="con-review-countity-review">
              <p class="review-top-nav-019">Review</p>
              <span
                ><i class="fas fa-star" aria-hidden="true"></i>
                <i class="fas fa-star" aria-hidden="true"></i>
                <i class="fas fa-star" aria-hidden="true"></i>
                <i class="fas fa-star" aria-hidden="true"></i>
                <i class="far fa-star" aria-hidden="true"></i>
              </span>
              <span>4.22</span>
              <span>(35)</span>
            </div>
          </div>
          <img :src="lecture.lecture_image" alt="" class="lecture-img-ins-1" />
          <form action="">
            <input type="radio" name="categories-1" id="categories-11" />
            <input type="radio" name="categories-1" id="categories-12" />
            <input type="radio" name="categories-1" id="categories-13" />
            <input type="radio" name="categories-1" id="categories-14" />
            <ul class="my-ul-for-ins">
              <li class="active">
                <a href="#tab-1">
                  <label for="categories-11">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-bookmarks"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z"
                      ></path>
                      <path
                        d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z"
                      ></path>
                    </svg>
                    Description
                  </label>
                </a>
              </li>
              <li class="">
                <a href="#tab-2">
                  <label for="categories-12">
                    <i class="fas fa-book" aria-hidden="true"></i>
                    Curriculum</label
                  >
                </a>
              </li>
              <li>
                <a href="#tab-3">
                  <label for="categories-13"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-star-2349"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
                      ></path>
                    </svg>
                    Reviews</label
                  >
                </a>
              </li>
              <li>
                <a href="#tab-4">
                  <label for="categories-14">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-person-2-34"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                      ></path>
                    </svg>
                    Teacher</label
                  >
                </a>
              </li>
            </ul>
          </form>
          <div class="tab-1-description active" id="tab-1">
            {{ lecture.lecture_description }}
          </div>
          <div class="tab-1-description" id="tab-2">
            <div class="con-h1-ins-curcl">
              <h1>Topics for this course</h1>
              <div class="con-h1-ins-curcl-coun-lesson">
                <p>{{ lecture.lecture_contents_count }} Contents</p>
                <p>{{ lecture.lecture_duration }}</p>
              </div>
            </div>
            <input type="checkbox" name="" id="Open-list-curcl" checked />
            <div class="main-con-culcl-lesson-1">
              <label for="Open-list-curcl">
                <div class="con-culcl-lessons-1">
                  <div class="div-text-ins">
                    <div class="con-div-for-line-mins-plus">
                      <div class="div-for-line-plus"></div>
                      <div class="div-for-line-mins"></div>
                    </div>
                    Contents
                  </div>
                </div>
              </label>
              <div
                class="con-culcl-lessons-1"
                v-for="content in lecture.lecture_contents"
                :key="content.content_id"
              >
                <div class="div-text-ins div-text-ins-1">
                  <div class="con-video-icon-name">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      fill="currentColor"
                      class="bi bi-play-21"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
                      ></path>
                    </svg>
                    <p>{{ content.content_title }}</p>
                  </div>
                  <p v-if="content.content_type == 'video'">00:4:45</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-1-description" id="tab-3">
            <h1 class="studend-feedback-1">Student Feedback</h1>
            <div class="containe-rating-1">
              <div class="containe-rating-2">
                <h1>4.3</h1>
                <div class="containe-rating-stars">
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="far fa-star" aria-hidden="true"></i>
                </div>
                <p>Total <span>2</span> Ratings</p>
              </div>
              <div class="containe-rating-3">
                <div class="containe-rating-3-big">
                  <div class="containe-rating-3-small">
                    <i class="fas fa-star" aria-hidden="true"></i>
                    <span>5</span>
                    <div class="div-progress-div-1"></div>
                    <span>0</span>
                    <p>rating</p>
                  </div>
                  <div class="containe-rating-3-small">
                    <i class="fas fa-star" aria-hidden="true"></i>
                    <span>5</span>
                    <div class="div-progress-div-1"></div>
                    <span>0</span>
                    <p>rating</p>
                  </div>
                  <div class="containe-rating-3-small">
                    <i class="fas fa-star" aria-hidden="true"></i>
                    <span>5</span>
                    <div
                      class="div-progress-div-1 div-progress-div-1-color"
                    ></div>
                    <span>2</span>
                    <p>ratings</p>
                  </div>
                  <div class="containe-rating-3-small">
                    <i class="fas fa-star" aria-hidden="true"></i>
                    <span>5</span>
                    <div class="div-progress-div-1"></div>
                    <span>0</span>
                    <p>rating</p>
                  </div>
                  <div class="containe-rating-3-small">
                    <i class="fas fa-star" aria-hidden="true"></i>
                    <span>5</span>
                    <div class="div-progress-div-1"></div>
                    <span>0</span>
                    <p>rating</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="containe-comment-1">
              <div class="div-img-container">SS</div>
              <div class="con-writer-comment-1">
                <p>Steven Smith</p>
                <p>3 months ago</p>
                <div>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="far fa-star" aria-hidden="true"></i>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Veritatis odit labore eius, accusantium dignissimos illum
                laboriosam iusto sapiente omnis ratione, ut adipisci suscipit
                pariatur, laudantium repellendus sed nemo minima neque.
              </p>
            </div>
            <div class="containe-comment-1 containe-last-comment">
              <div class="div-img-container div-img-container-color">ES</div>
              <div class="con-writer-comment-1">
                <p>Steven Smith</p>
                <p>3 months ago</p>
                <div>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="fas fa-star" aria-hidden="true"></i>
                  <i class="far fa-star" aria-hidden="true"></i>
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Veritatis odit labore eius, accusantium dignissimos illum
                laboriosam iusto sapiente omnis ratione, ut adipisci suscipit
                pariatur, laudantium repellendus sed nemo minima neque.
              </p>
            </div>
          </div>
          <div class="tab-1-description" id="tab-4">
            <h1 class="instructor-about-h1">About the Teacher</h1>
            <div class="row">
              <div class="col-4">
                <div class="con-img-instructor-1">
                  <img
                    :src="teacher.teacher_profile_image"
                    alt=""
                    class="img-instructor-12"
                  />
                  <div>
                    <i class="fas fa-star" aria-hidden="true"></i>
                    4.2/5
                  </div>
                </div>
              </div>
              <div class="col-8">
                <div class="div-name-categoriesfor-20">
                  {{ teacher.teacher_name }}
                  <span>/ {{ teacher.teacher_subject_name }}</span>
                </div>
                <p class="number-of-coures-0349">
                  <span>{{ teacher.teacher_courses_count }}</span> Courses
                </p>
                <p class="about-of-instructor-29-details">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                  labore quaerat saepe autem earum distinctio ducimus accusamus
                  unde tenetur. Pariatur eaque ipsam laudantium sed mollitia
                  nemo laborum, facere animi repudiandae.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="con-to-sticy">
            <ul class="ul-follow-teacher">
              <div class="con-side-bar-icon-info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-person-342"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                  ></path>
                </svg>
                Teacher : <span> {{ teacher.teacher_name }}</span>
              </div>
              <div class="con-side-bar-icon-info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-bookmark"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"
                  ></path>
                </svg>
                Contents : <span> {{ lecture.lecture_contents_count }}</span>
              </div>
              <div class="con-side-bar-icon-info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-clock"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
                  ></path>
                  <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"
                  ></path>
                </svg>
                Duration : <span> {{ lecture.lecture_duration }}</span>
              </div>
              <div class="con-side-bar-icon-info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-tag"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"
                  ></path>
                  <path
                    d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"
                  ></path>
                </svg>
                Education Level :
                <span> {{ lecture.lecture_education_level_name }}</span>
              </div>
              <div class="con-side-bar-icon-info no-for-last-div">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-currency-dollar"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"
                  ></path>
                </svg>
                Price :
                <span class="spesiphy-price">
                  {{ lecture.lecture_subscription_fees }}</span
                >
              </div>
              <div class="form-for-enroll">
                <button
                  v-if="
                    !lecture.lecture_is_parchased &&
                    !lecture.lecture_subscription_fees_amount
                  "
                  @click="purchaseLectureFromWallet"
                >
                  Buy Now
                </button>
                <button
                  v-else-if="!lecture.lecture_is_parchased"
                  v-b-modal.modal-1
                >
                  Buy Now
                </button>
                <button
                  v-else
                  @click="
                    $router.push('/lecture-purchased/' + $route.params.id)
                  "
                >
                  Show Now
                </button>
                <b-modal
                  centered
                  id="modal-1"
                  header="test"
                  header-class="justify-content-center"
                  title="Confirm Approval"
                  hide-footer
                  hide-header
                >
                  <div class="card-for-payment">
                    <div class="main-con-for-payment-logo-1">
                      <ul class="ul-for-payment-logo">
                        <li
                          @click="activelink"
                          data-type="card"
                          class="li-for-payment-logo active-payment-method"
                        >
                          <a href="#">
                            <span class="icon">
                              <img
                                class="visa-icon-390"
                                src=""
                              />
                            </span>
                            <span class="name">Visa</span>
                          </a>
                        </li>
                        <li
                          @click="activelink"
                          data-type="fawry"
                          class="li-for-payment-logo"
                        >
                          <a href="#">
                            <span class="icon">
                              <img
                                class="fawry-icon-390"
                                src=""
                              /> </span
                            ><span class="name">Fawry</span></a
                          >
                        </li>
                        <li
                          @click="activelink"
                          data-type="coupon"
                          class="li-for-payment-logo"
                        >
                          <a href="#">
                            <span class="icon">
                              <img
                                class="coupon-icon-390"
                                src=""
                              /> </span
                            ><span class="name">Coupon</span></a
                          >
                        </li>
                        <div class="back-for-payment"></div>
                      </ul>
                      <div class="con-x-icon" @click="$bvModal.hide('modal-1')">
                        <i
                          @click="$bvModal.hide('modal-1')"
                          class="fas fa-times fa-times-203"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div class="card-li-for-payment-logo">
                      <div class="con-main-form-for-payment">
                        <div class="form-237 no-mar-for-top">
                          <input
                            type="text"
                            name=""
                            placeholder="Enter Your Price"
                          />
                          <label for="">
                            <img
                              class="price-icon-12"
                              src=""
                            />
                            <span>Price</span></label
                          >
                        </div>
                        <form class="form-237 no-mar-for-top" action="">
                          <input
                            type="text"
                            name=""
                            placeholder="Enter Cardholder Name"
                          />
                          <label for="">
                            <img
                              class="name-holder name-holder-124"
                              src=""
                            />
                            <span>Cardholder Name </span></label
                          >
                        </form>
                        <form class="form-237 no-mar-for-top" action="">
                          <input
                            type="text"
                            name=""
                            placeholder="Enter Card Number"
                          />
                          <label for="">
                            <img
                              class="Code-icon-12"
                              src=""
                            />
                            <span>Card Numbercalender-icon</span></label
                          >
                        </form>
                        <div class="con-two-form-1223">
                          <form class="form-237 no-mar-for-top" action="">
                            <input type="text" name="" placeholder="xx/dd" />
                            <label for="">
                              <img
                                class="calender-icon"
                                src=""
                              />
                              <span>Valid Through </span></label
                            >
                          </form>
                          <form class="form-237 no-mar-for-top" action="">
                            <input type="password" name="" placeholder="****" />
                            <label for=""><span>CCV</span></label>
                          </form>
                        </div>
                        <form class="form-button-for-submittt" action="">
                          <button>Pay</button>
                        </form>
                      </div>
                    </div>
                    <div class="fawry-li-for-payment-logo">
                      <div class="con-main-form-for-payment"></div>
                    </div>
                    <div class="coupon-li-for-payment-logo">
                      <form
                        class="con-main-form-for-payment"
                        @submit.prevent="purchaseLectureByCoupon"
                      >
                        <div class="form-237 no-mar-for-top" action="">
                          <input
                            v-model="values.coupon"
                            type="text"
                            name="coupon"
                            style="width: 100%"
                            placeholder="Type your Coupon"
                            @blur="validate('coupon')"
                            @keypress="validate('coupon')"
                            :class="[
                              'form-control',
                              !!errors.coupon && 'is-invalid',
                            ]"
                          />
                          <p
                            :class="[
                              'form-input-hint',
                              !!errors.coupon && 'invalid-feedback',
                            ]"
                            v-if="!!errors.coupon"
                          >
                            {{ errors.coupon }}
                          </p>
                          <label for=""><span>Coupon</span></label>
                        </div>
                         <button
                            type="submit"
                            class="btn btn-orange btn-medium"
                            data-animation="animated zoomInUp"
                          >
                            Pay by Coupon
                          </button> 
                        <div class="form-button-for-submittt">
                          <button type="submit">Pay</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </b-modal>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div>
    <div class="main-con-to-home-page-contnent">
      <div class="container">
        <div class="main-con-course-page-content">
          <div class="row row-reverse">
            <div class="col-12 col-md-8">
              <h1 class="course-page-title">
                {{ lecture.lecture_title }}
              </h1>
              <div class="con-top-card-to-instractor">
                <img
                  @click="$router.push('/teacher/' + lecture.teacher_id)"
                  :src="teacher.teacher_profile_image"
                  alt=""
                />
                <div
                  class="top-card-to-instractor-name"
                  @click="$router.push('/teacher/' + lecture.teacher_id)"
                >
                  <h1>Teacher</h1>
                  <p>{{ teacher.teacher_name }}</p>
                </div>
                <div class="top-card-to-instractor-date">
                  <h1>Created At</h1>
                  <p>{{ lecture.lecture_created_at }}</p>
                </div>
                <div class="top-card-to-instractor-review">
                  <h1>Ratings</h1>
                  <div class="con-reviews-top-card-to-instractor">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-regular fa-star"></i
                    ><span
                      >({{ lecture.rate_average }}) {{ lecture.rate_count }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="div-con-img-of-course-background" v-bind:style="{background:`url( ${lecture.lecture_image}) no-repeat center center `}">

              </div>
              <!-- <img
                class="course-page-img-course"
                :src="lecture.lecture_image"
                alt=""
              /> -->
              <ul class="tabs-course-page">
                <li class="active" data-cont=".con-Description">Description</li>
                <li data-cont=".con-Curriculum">Curriculum</li>
                <li data-cont=".con-Question">Question</li>
              </ul>
              <div class="con-tabs-course-page-content">
                <div class="con-Description">
                  <h1 class="part-h1">Description</h1>
                  <div class="con-Description-section">
                    <p>
                      {{ lecture.lecture_description }}
                    </p>
                    <!-- <span v-show="lecture.lecture_description.length > 500 ">See More </span> -->
                  </div>
                </div>
                <div class="con-Curriculum">
                  <h1 class="part-h1">Topics for this course</h1>
                  <div class="main-con-to-curriculum-card show">
                    <div class="first-curriculum">
                      <p>Content</p>
                      <!-- <div class="con-plus-icon"></div> -->
                    </div>
                    <div class="main-con-rest-of-curriculum">
                      <div
                        class="con-rest-of-curriculum"
                        v-for="content in lecture.lecture_contents"
                        :key="content.content_id"
                      >
                        <!-- <i class="fa-regular fa-circle-play"></i> -->

                        <span v-html="content.content_type_icon"></span>

                        <p>
                          {{ content.content_title }}
                        </p>
                        <span v-if="content.content_type == 'exam'">{{
                          content.content_duration
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="con-Question">
                  <h1 class="part-h1">Question</h1>
                  <div class="main-con-post-comment ">
                   <div v-if="!lecture.lecture_disable_comments ">
                     <div class="con-write-comment">
                      <!-- <img
                        src="images/successful-college-student-lg.png"
                        alt=""
                      /> -->
                      <input
                        class="input-text-comment"
                        type="text"
                        v-model="question"
                        placeholder="Type Your Comment"
                      />
                      <input
                        class="input-comment-attachment-class"
                        type="file"
                        name=""
                        @change="onChangeUploadAttachment"
                        id="input-comment-attachment-id"
                      />
                      <label
                        class="attachment-label-icon"
                        for="input-comment-attachment-id"
                      >
                        <i class="fa-solid fa-paperclip"></i
                      ></label>
                      <button>
                        <i
                          class="fa-solid fa-arrow-right"
                          @click="askQuestion"
                        ></i>
                      </button>
                    </div>
                    <div
                      class="con-comment-one"
                      v-for="comment in comments"
                      :key="comment.question_id"
                    >
                      <img
                        :src="comment.student_profile_image_full_path"
                        alt=""
                      />
                      <div class="main-con-comment-content">
                        <div class="con-comment-content">
                          <p class="comment-writer-name">
                            {{ comment.student_name }}
                          </p>
                          <p class="comment-content-content">
                            {{ comment.question_question }}
                          </p>
                        </div>
                        <img
                          v-if="comment.question_image"
                          :src="comment.question_image_full_path"
                          alt=""
                        />
                        <div class="con-comment-actions">
                          <span>{{ comment.question_created_at }}</span>
                          <div class="con-likes-comment">
                            <i
                              class="fa-star"
                              :class="{
                                'fa-regular': !comment.is_liked_by_student,
                                'fa-solid': comment.is_liked_by_student,
                              }"
                              @click="likecomment(comment)"
                            ></i>
                            <p class="stars-number">
                              {{ comment.question_likes_count }}
                            </p>
                          </div>
                          <i
                            class="fa-solid fa-share"
                            @click="showReply(comment.question_id)"
                          ></i>
                          <span class="repl-count-comment">{{comment.question_replies_count}}</span>
                        </div>
                      <div v-if="showReplyStatus ==  comment.question_id">
                        <div
                          class="con-write-comment"
                          
                        >
                          <!-- <img :src="user.profile_image_full_path" alt="" /> -->
                          <input
                            class="input-text-comment"
                            type="text"
                            placeholder="Add Reply . . ."
                            v-model="reply"
                            required
                          />
                          <input
                            class="input-comment-attachment-class"
                            type="file"
                            name=""
                            @change="onChangeUploadAttachment"
                            accept="image/*"
                            id="input-comment-attachment-id"
                          />
                          <label
                            class="attachment-label-icon"
                            for="input-comment-attachment-id"
                          >
                            <i class="fa-solid fa-paperclip"></i
                          ></label>
                          <button
                            type="submit"
                            @click="addReply(comment.question_id)"
                          >
                            <i class="fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                        <div
                          class="con-comment-one"
                          v-for="reply in comment.question_replys"
                          :key="reply.reply_id"
                        >
                          <img
                            :src="reply.reply_sender_profile_image_full_path"
                            alt=""
                          />
                          <div class="main-con-comment-content">
                            <div class="con-comment-content">
                              <p class="comment-writer-name">
                                {{ reply.reply_sender_name }}
                              </p>
                              <p class="comment-content-content">
                                {{ reply.reply_answer }}
                              </p>
                            </div>
                            <img
                              v-if="reply.reply_image_full_path"
                              :src="reply.reply_image_full_path"
                              alt=""
                            />
                            <div class="con-comment-actions">
                              <span>{{ reply.reply_created_at }}</span>
                              <div class="con-likes-comment">
                                <i
                                  class="fa-star"
                                  @click="starReply(reply)"
                                  :class="{
                                    'fa-regular': !reply.is_liked_by_student,
                                    'fa-solid': reply.is_liked_by_student,
                                  }"
                                ></i>
                                <p class="stars-number">
                                  {{ reply.reply_likes_count }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="con-empty-comments con-empty-comments-1" v-else>
                    <i class="flaticon-no-talking"></i>
                    <p>No Comments Are Avalible You Should Buy Lecture First</p>
                  </div>
                  </div>
                  <div
                    v-if="!isLastPageComment"
                    class="main-con-to-see-more-button"
                  >
                    <button @click="showNextComments">
                      See More <i class="fa-solid fa-ellipsis"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <LectureSidebar></LectureSidebar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LectureSidebar from "../../components/lecture/LectureSidebar.vue";
import axios from "@/axiosConfig";
export default {
  name: "Lecture",
  data: function () {
    return {
      question: "",
      reply: "",
      showReplyStatus: 0,
      image: "",
      comment_image: "",
    };
  },
  computed: {
    ...mapGetters("lecture", ["lecture", "teacher"]),
    ...mapGetters("post", ["comments", "nextPage", "isLastPageComment"]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("lecture", ["getLectureApi"]),
    ...mapActions("post", ["askQuestionApi", "addReplyApi", "getCommentsApi"]),
    switchtab(event) {
      event.preventDefault();
      document
        .querySelector(".tabs-course-page li.active")
        .classList.remove("active");
      let divsCourse = document.querySelectorAll(
        ".con-tabs-course-page-content > div"
      );
      let divsArrayCourse = Array.from(divsCourse);
      divsArrayCourse.forEach((div) => {
        div.style.display = "none";
      });
      var clickedtab = event.currentTarget;
      clickedtab.classList.add("active");
      document.querySelector(event.currentTarget.dataset.cont).style.display =
        "block";
    },
    likecomment(comment) {
      comment.is_liked_by_student = !comment.is_liked_by_student;
      if (comment.is_liked_by_student) {
        comment.question_likes_count++;
      } else {
        comment.question_likes_count--;
      }
      axios.post(
        `lecture-questions/comments-like/${this.lecture.post_id}/${comment.question_id}`
      );
    },
        starReply(reply) {
      axios.post(`/lecture-questions/replies-like/${this.lecture.post_id}/${reply.reply_id}`)
      reply.is_liked_by_student = !reply.is_liked_by_student;
      if(reply.is_liked_by_student){
        reply.reply_likes_count++;
      }else{
        reply.reply_likes_count--;
      }
    },
    activelink(event) {
      var list = document.querySelectorAll(".li-for-payment-logo");
      list.forEach((item) => {
        item.classList.remove("active-payment-method");
        document.querySelector(
          "." + item.getAttribute("data-type") + "-li-for-payment-logo"
        ).style.display = "none";
      });
      event.currentTarget.classList.add("active-payment-method");
      document.querySelector(
        "." +
          event.currentTarget.getAttribute("data-type") +
          "-li-for-payment-logo"
      ).style.display = "block";
    },
    showNextComments() {
      var data = {
        post_id: this.lecture.post_id,
        nextPage: this.nextPage,
      };
      this.getCommentsApi(data);
    },
    showReply(id) {
      this.reply = "";
      if(this.showReplyStatus == 0 ){
        this.showReplyStatus = id ;
      }else{
        this.showReplyStatus = 0 ;
      }
      
    },
    onChangeUploadAttachment(event) {
      this.image = event.target.files[0];
      this.comment_image = URL.createObjectURL(this.image);
    },
    askQuestion() {
      if (this.question || this.image) {
        // var data = { question: this.question };
        const formData = new FormData();
        formData.append("image", this.image);
        formData.append("question", this.question ? this.question : "");
        formData.append("post_id", this.lecture.post_id);
        this.askQuestionApi(formData).then(() => {
          // let date = new Date();
          // let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
          //   date
          // );
          // let month = new Intl.DateTimeFormat("en", { month: "short" }).format(
          //   date
          // );
          // let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
          //   date
          // );
          // let time = new Intl.DateTimeFormat("en", {
          //   hour: "numeric",
          //   minute: "numeric",
          // }).format(date);
          // this.comments.unshift({
          //   question_created_at: `${month} ${day},${year} : ${time}`,
          //   question_question: data.question,
          //   student_id: this.user.id,
          //   student_name: this.user.name,
          //   student_profile_image_full_path: this.user.profile_image_full_path,
          //   question_image_full_path: this.comment_image,
          //   question_image: this.comment_image,
          // });
        });
        this.question = null;
        this.image = null;
      }
    },
    getQuestion() {
      var data = {
        post_id: this.lecture.post_id,
        nextPage: 1,
      };
      this.getCommentsApi(data);
    },
    addReply(question_id) {
      if (this.reply) {
        var data = {
          body: {
            reply: this.reply,
          },
          post_id: this.lecture.post_id,
          question_id: question_id,
        };
        const formData = new FormData();
        formData.append("image", this.image);
        formData.append("question_id", question_id);
        // formData.append("post_id", this.lecture.post_id);
        formData.append("reply", this.reply);
        formData.append("_method", "patch");
        data.body = formData;
        this.addReplyApi(data).then(() => {
          let date = new Date();
          let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
            date
          );
          let month = new Intl.DateTimeFormat("en", { month: "short" }).format(
            date
          );
          let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
            date
          );
          let time = new Intl.DateTimeFormat("en", {
            hour: "numeric",
            minute: "numeric",
          }).format(date);
          const index = this.comments.findIndex(
            (comment) => comment["question_id"] === question_id
          );
          this.comments[index]["question_replys"].push({
            reply_created_at: `${month} ${day},${year} : ${time}`,
            reply_answer: data.body.getAll("reply")[0],
            reply_sender_id: this.user.id,
            reply_sender_name: this.user.name,
            reply_sender_profile_image_full_path:
              this.user.profile_image_full_path,
            reply_image: this.comment_image,
            reply_image_full_path: this.comment_image,
          });
        });
        this.reply = null;
      }
    },
  },
  watch: {
    lecture: [
      {
        handler: "getQuestion",
      },
    ],
  },
  beforeMount() {
    this.getLectureApi(this.$route.params.id);
  },
  mounted: function () {
    var tabsCourse = document.querySelectorAll(".tabs-course-page > li");
    let tabsArrayCourse = Array.from(tabsCourse);
    for (var i = 0; i < tabsArrayCourse.length; i++) {
      tabsArrayCourse[i].addEventListener("click", this.switchtab);
    }
  },
  components: { LectureSidebar },
};
</script>

<style scoped>
.fawry-li-for-payment-logo,
.coupon-li-for-payment-logo {
  display: none;
}
.main-con-post-comment{
      background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #eee ;
}
.repl-count-comment{
  margin-left:5px;
}
.con-write-comment{
  margin-top:15px;
}
.con-empty-comments{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 20px;
  border-top: 1px solid #eee;
}
.con-empty-comments-1{
  border-top: none;
}
.con-empty-comments i{
  font-size: 70px;
  color: #888da8;
  margin-bottom: 10px;
  display: flex;
}
.con-empty-comments p{
  font-size: 17px;
  color: #2d2d2d;
  font-weight: 700;
  margin-bottom: 0px;
}
</style>
