import axios from "@/axiosConfig";
import Vue from "vue";

export default ({
  state: {
    teacher: [],
    posts:[],
    nextPage:0,
    topLectures:[]
  },
  actions: {
    getTeacherApi({ commit }, id) {
      axios.get('/teachers/show/' + id)
        .then(response => {
          console.log(response);
          commit('SET_TEACHER', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    followTeacherApi(_, id) {
      axios.post('/follow-teacher/' + id)
        .then(response => {
          Vue.$toast.success(response.data.message);
          console.log(response);
        })
        .catch(error => {
          Vue.$toast.error(error.response.data.message);
          console.log(error)
        })
    },

    getPostsApi({ commit }, id) {
      axios.get('/teachers/posts/' + id)
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getLecturesApi({ commit }, id) {
      axios.get('/teachers/lectures/' + id)
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getMyLecturesApi({ commit }, id) {
      axios.get('/teachers/mylectures/' + id)
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getSavedApi({ commit }, id) {
      axios.get('/teachers/saved/' + id)
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getLikedApi({ commit }, id) {
      axios.get('/teachers/liked/' + id)
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },
    getTopLecturesApi({ commit }, id) {
      axios.get('/teachers/top-lectures/' + id)
        .then(response => {
          console.log(response);
          commit('SET_TOP_LECTURES', response);
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  mutations: {
    SET_TEACHER(state, response) {
      state.teacher = response.data.data.teacher;
    },
    SET_TOP_LECTURES(state, response) {
      state.topLectures = response.data.data;
    },
    SET_POSTS(state, response){
      state.posts = response.data.data;
      state.nextPage = ((response.data.pagination.current_page + 1 ) <= response.data.pagination.total) ? response.data.pagination.current_page + 1  : response.data.pagination.total;
    },
  },
  getters: {
    teacher: state => state.teacher,
    posts: state => state.posts,
    nextPage: state => state.nextPage,
    topLectures: state => state.topLectures,
  },
  namespaced: true,
});
