<template>
  <!-- 
    <div class="container-fluid">
        <div class="row row-0001">
            <input type="checkbox" id="nav-open">
            <div class=" nav-bar">
                <ul>
                    <li> <a href="#"><svg class="home-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" viewBox="0 0 32 40" version="1.1" xml:space="preserve"  x="0px" y="0px" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"><path d="M24.375,31L7.625,31C5.971,31 4.625,29.654 4.625,28L4.625,17L2,17C1.596,17 1.23,16.756 1.076,16.383C0.921,16.009 1.007,15.579 1.293,15.293L15.293,1.293C15.684,0.902 16.316,0.902 16.707,1.293L30.707,15.293C30.993,15.579 31.079,16.009 30.924,16.383C30.769,16.756 30.404,17 30,17L27.375,17L27.375,28C27.375,29.654 26.029,31 24.375,31ZM20.404,29L24.375,29C24.927,29 25.375,28.551 25.375,28L25.375,16C25.375,15.448 25.822,15 26.375,15L27.586,15L16,3.414L4.414,15L5.625,15C6.178,15 6.625,15.448 6.625,16L6.625,28C6.625,28.551 7.073,29 7.625,29L11.597,29L11.597,22.607C11.597,20.179 13.573,18.204 16,18.204C18.428,18.204 20.404,20.179 20.404,22.607L20.404,29ZM18.404,29L18.404,22.607C18.404,21.282 17.326,20.204 16,20.204C14.675,20.204 13.597,21.282 13.597,22.607L13.597,29L18.404,29Z" fill-rule="nonzero"/></svg>Home</a></li>
                    <li> <a href="#"><svg class="courses-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 160" x="0px" y="0px"><title>Books</title><g><path d="M115.652,22.736h-18a4,4,0,0,0-4,4v1.993h-14V26.736a4,4,0,0,0-4-4h-17.7a4,4,0,0,0-4,3.975L39.566,21.5a4,4,0,0,0-5.123,2.4L8.587,95.371a4.006,4.006,0,0,0,2.4,5.122l15.046,5.443h0a3.966,3.966,0,0,0,1.354.238,4.009,4.009,0,0,0,3.767-2.639l22.8-63.021v62.22a4,4,0,0,0,4,4h57.7a4,4,0,0,0,4-4v-76A4,4,0,0,0,115.652,22.736ZM31.877,89.787,16.831,84.344l4.259-11.772,15.046,5.444ZM27.4,102.176h0L12.349,96.731l3.121-8.626,15.047,5.444ZM37.5,74.255,22.45,68.81,38.205,25.265,53.25,30.708ZM97.652,87.479h18v6.75h-18Zm18-60.744,0,56.744h-18V26.736Zm-57.7,0h17.7l0,76h-17.7Zm21.7,76V49.271h6a2,2,0,0,0,0-4h-6V42.1h6a2,2,0,0,0,0-4h-6V32.729h14V75.065h-6a2,2,0,0,0,0,4h6v3.167h-6a2,2,0,1,0,0,4h6v16.5Zm18,0V98.229h18v4.506Zm9-59.229a6.027,6.027,0,1,0-6.027-6.027A6.034,6.034,0,0,0,106.652,43.506Zm0-8.054a2.027,2.027,0,1,1-2.027,2.027A2.029,2.029,0,0,1,106.652,35.452Zm5.387,14.9a2,2,0,0,1-2,2h-6.774a2,2,0,0,1,0-4h6.774A2,2,0,0,1,112.039,50.354ZM66.8,35.479A5.924,5.924,0,0,0,60.887,41.4v46.25a5.917,5.917,0,1,0,11.833,0V41.4A5.924,5.924,0,0,0,66.8,35.479Zm1.917,52.167a1.917,1.917,0,1,1-3.833,0V41.4a1.917,1.917,0,1,1,3.833,0ZM28.789,82.014a2.6,2.6,0,1,1-1.563-3.336A2.6,2.6,0,0,1,28.789,82.014Zm9.786-50.508a2,2,0,0,1,2.562-1.2l6.636,2.4a2,2,0,1,1-1.361,3.762l-6.636-2.4A2,2,0,0,1,38.575,31.506Zm-.132,6.244,6.636,2.4a2,2,0,1,1-1.361,3.762l-6.636-2.4a2,2,0,1,1,1.361-3.762Zm5.142,12.407a2,2,0,0,1-2.562,1.2l-6.635-2.4a2,2,0,1,1,1.361-3.762l6.635,2.4A2,2,0,0,1,43.585,50.157Z"/></g></svg>My Courses</a><div class="num-li">4</div></li>
                    <li> <a href="#"><svg class="files-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 60 75" enable-background="new 0 0 60 60" xml:space="preserve"><path d="M49.95,14.74c-0.01-0.05-0.01-0.09-0.03-0.14c-0.02-0.04-0.05-0.07-0.07-0.11c-0.05-0.08-0.09-0.15-0.15-0.21  c0,0,0-0.01-0.01-0.01c-3.44-3.24-7.23-6.93-7.76-7.66c0,0,0,0,0,0c0,0-0.01-0.01-0.01-0.01c-0.04-0.1-0.11-0.19-0.18-0.27  c-0.02-0.03-0.05-0.05-0.08-0.07c-0.06-0.06-0.13-0.1-0.21-0.14c-0.03-0.02-0.06-0.03-0.09-0.05C41.24,6.03,41.12,6,41,6H11  c-0.55,0-1,0.45-1,1v46c0,0.55,0.45,1,1,1h38c0.55,0,1-0.45,1-1V15c0-0.01,0-0.01,0-0.01C50,14.9,49.97,14.82,49.95,14.74z M42,9.63  c0.96,0.98,2.39,2.38,4.49,4.37H42V9.63z M48,52H12V8h28v7c0,0.55,0.45,1,1,1h7V52z"/><path d="M19,20c0,0.55,0.45,1,1,1s1-0.45,1-1v-3h3c0.55,0,1-0.45,1-1s-0.45-1-1-1h-3v-3c0-0.55-0.45-1-1-1s-1,0.45-1,1v3h-3  c-0.55,0-1,0.45-1,1s0.45,1,1,1h3V20z"/><path d="M17,25c0,0.55,0.45,1,1,1h24c0.55,0,1-0.45,1-1s-0.45-1-1-1H18C17.45,24,17,24.45,17,25z"/><path d="M42,29H18c-0.55,0-1,0.45-1,1s0.45,1,1,1h24c0.55,0,1-0.45,1-1S42.55,29,42,29z"/><path d="M42,34H18c-0.55,0-1,0.45-1,1s0.45,1,1,1h24c0.55,0,1-0.45,1-1S42.55,34,42,34z"/><path d="M42,39H18c-0.55,0-1,0.45-1,1s0.45,1,1,1h24c0.55,0,1-0.45,1-1S42.55,39,42,39z"/><path d="M34,44H18c-0.55,0-1,0.45-1,1s0.45,1,1,1h16c0.55,0,1-0.45,1-1S34.55,44,34,44z"/></svg>My Files</a><div class="num-li">8</div></li>
                    <li> <a href="#"><svg class="exam-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 80" x="0px" y="0px"><g><path d="M47,56H12a3,3,0,0,1-3-3V7a3,3,0,0,1,3-3H38a1,1,0,0,1,.74.32l11,12A1,1,0,0,1,50,17V53A3,3,0,0,1,47,56ZM12,6a1,1,0,0,0-1,1V53a1,1,0,0,0,1,1H47a1,1,0,0,0,1-1V17.39L37.56,6Z"/><path d="M49,18H40a3,3,0,0,1-3-3V5a1,1,0,0,1,.64-.93,1,1,0,0,1,1.1.25l11,12a1,1,0,0,1,.18,1.08A1,1,0,0,1,49,18ZM39,7.57V15a1,1,0,0,0,1,1h6.73Z"/><path d="M22,30H16a1,1,0,0,1-1-1V23a1,1,0,0,1,.62-.92,1,1,0,0,1,1.09.21L19,24.59l2.29-2.3a1,1,0,0,1,1.09-.21A1,1,0,0,1,23,23v6A1,1,0,0,1,22,30Zm-5-2h4V25.41l-1.29,1.3a1,1,0,0,1-1.42,0L17,25.41Z"/><path d="M22,41H16a1,1,0,0,1-1-1V34a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v6A1,1,0,0,1,22,41Zm-5-2h4V35H17Z"/><path d="M22,52H16a1,1,0,0,1-1-1V45a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v6A1,1,0,0,1,22,52Zm-5-2h4V46H17Z"/><path d="M37,25H27a1,1,0,0,1,0-2H37a1,1,0,0,1,0,2Z"/><path d="M41,29H27a1,1,0,0,1,0-2H41a1,1,0,0,1,0,2Z"/><path d="M37,36H27a1,1,0,0,1,0-2H37a1,1,0,0,1,0,2Z"/><path d="M41,40H27a1,1,0,0,1,0-2H41a1,1,0,0,1,0,2Z"/><path d="M37,47H27a1,1,0,0,1,0-2H37a1,1,0,0,1,0,2Z"/><path d="M41,51H27a1,1,0,0,1,0-2H41a1,1,0,0,1,0,2Z"/><path d="M19,27a1,1,0,0,1-.71-.29l-3-3a1,1,0,0,1,1.42-1.42L19,24.59l5.29-5.3a1,1,0,0,1,1.42,1.42l-6,6A1,1,0,0,1,19,27Z"/><path d="M52,60H15a3,3,0,0,1-3-3V55a1,1,0,0,1,1-1H47a1,1,0,0,0,1-1V17.39l-5.24-5.71a1,1,0,0,1-.18-1.08,1,1,0,0,1,.92-.6H52a3,3,0,0,1,3,3V57A3,3,0,0,1,52,60ZM14,56v1a1,1,0,0,0,1,1H52a1,1,0,0,0,1-1V13a1,1,0,0,0-1-1H45.77l4,4.32A1,1,0,0,1,50,17V53a3,3,0,0,1-3,3Z"/><path d="M33,17H15a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1H33a1,1,0,0,1,1,1v6A1,1,0,0,1,33,17ZM16,15H32V11H16Z"/></g></svg>My Exams</a></li>
                    <li> <a href="#"><svg class="wallet-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 60 75" enable-background="new 0 0 60 60" xml:space="preserve"><path d="M50.35,19.92h-3.2v-7.7c0-0.55-0.45-1-1-1h-5.09l-3.73-5.77c-0.14-0.22-0.37-0.38-0.63-0.43c-0.26-0.06-0.53-0.01-0.75,0.14  l-5.09,3.3l-1.94-3c-0.14-0.22-0.37-0.38-0.63-0.43c-0.26-0.06-0.53-0.01-0.75,0.14l-9.36,6.06h-7.61c-2.95,0-5.35,2.4-5.35,5.35  v33.08C5.2,52.6,7.6,55,10.55,55h39.8c2.46,0,4.45-2,4.45-4.46v-8.92v-8.48v-8.77C54.8,21.92,52.8,19.92,50.35,19.92z M45.15,13.22  v4.32l-2.8-4.32H45.15z M36.19,7.38l8.12,12.54H16.83l14.27-9.24c0,0,0,0,0,0s0,0,0,0L36.19,7.38z M27.77,7.38l1.4,2.16L13.14,19.92  h-2.59c-0.56,0-1.08-0.15-1.54-0.39L27.77,7.38z M10.55,13.22h4.52l-7.5,4.86c-0.23-0.46-0.37-0.96-0.37-1.51  C7.2,14.73,8.7,13.22,10.55,13.22z M50.35,53h-39.8C8.7,53,7.2,51.5,7.2,49.65V20.71c0.92,0.75,2.08,1.21,3.35,1.21h35.6h4.2  c1.35,0,2.45,1.1,2.45,2.46v7.77h-4.96c-2.89,0-5.24,2.35-5.24,5.24s2.35,5.24,5.24,5.24h4.96v7.92C52.8,51.9,51.7,53,50.35,53z   M52.8,40.63h-4.96c-1.79,0-3.24-1.45-3.24-3.24s1.45-3.24,3.24-3.24h4.96V40.63z"/><path d="M47.4,37c-0.05,0.13-0.08,0.26-0.08,0.39s0.03,0.26,0.08,0.38c0.05,0.12,0.12,0.23,0.22,0.32c0.19,0.19,0.44,0.3,0.71,0.3  c0.26,0,0.51-0.11,0.7-0.3c0.19-0.19,0.29-0.44,0.29-0.7c0-0.13-0.02-0.26-0.07-0.39c-0.05-0.12-0.13-0.23-0.22-0.32  c-0.28-0.28-0.72-0.37-1.09-0.22c-0.12,0.05-0.23,0.13-0.32,0.22C47.52,36.77,47.45,36.88,47.4,37z"/><path d="M12.03,27.18h35.94c0.55,0,1-0.45,1-1s-0.45-1-1-1H12.03c-0.55,0-1,0.45-1,1S11.48,27.18,12.03,27.18z"/><path d="M47.97,47.6H12.03c-0.55,0-1,0.45-1,1s0.45,1,1,1h35.94c0.55,0,1-0.45,1-1S48.52,47.6,47.97,47.6z"/></svg>My Wallet</a></li>
                </ul>
            </div>
            <div class="col-12 main-col-12">
                <div class="con-h1-head-courses h1-mar-bottom">
                    <h1>My exams</h1>
                </div>
                <div class="container-fluid no-pad">
                    <div class="container no-pad">
                        <table class="table-1 table-55 ">
                            <thead>
                                <tr>
                                    <th>Exam</th>
                                    <th>Degree</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                        </table>
                        <table class="table-2 table-55">
                            <tbody>
                                <tr>
                                    <td>
                                        Lecture 1
                                    </td>
                                    <td class="main-con-deg-001">
                                        45/50
                                        <div class="con-deg-001">
                                            <div class="back-color-90 wid-90">

                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        1/09/2021
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="con-allteacher-page">
          <h1>My Exams</h1>
          <table class="table-1">
            <thead>
              <tr>
                <th>Exam</th>
                <th>Degree</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(exam,index) in exams.data" :key="index">
                <td>{{exam.title}}</td>
                <td>
                  <div class="con-progress-tabel-degree">
                    <div class="con-progress-tabel"></div>
                    <p>45%</p>
                  </div>
                </td>
                <td>1/09/2021</td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ListMyExams",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters("listMyExams", ["exams", "nextPage"]),
  },
  methods: {
    ...mapActions("listMyExams", [
      "getMyExamsApi",
      "getNextMyExamsApi",
    ]),
    handleScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.body.scrollHeight;
        if (bottomOfWindow && this.nextPage) {
          this.getNextMyExamsApi();
        }
      };
    },
  },
  beforeMount() {
    this.getMyExamsApi();
  },
};
</script>