<template>
  <!-- <div class="container-fluid">
    <div class="row row-0001">
      <input type="checkbox" id="nav-open" />
      <div class="col-12 main-col-12">
        <div class="con-h1-head-courses">
          <h1>Top Lectures</h1>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-4" v-for="lecture in lectures" :key="lecture.lecture_id">
              <div class="con-courses-main-98">
                <div class="con-catagories-123 light-grreen">
                  {{ lecture.lecture_subject_name }}
                </div>
                <div class="con-img-657">
                  <div
                    class="con-img-658"
                    @click="
                      $router.push(
                        '/lecture/' +
                          lecture.lecture_id
                      )
                    "
                  >
                    <img :src="lecture.lecture_image" alt="" class="img-86" />
                  </div>
                  <img
                    @click="$router.push('/teacher/' + lecture.teacher_id)"
                    :src="lecture.teacher_profile_image"
                    alt=""
                    class="img-87"
                  />
                </div>
                <p
                  class="p-mr-name"
                  @click="$router.push('/teacher/' + lecture.teacher_id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#ec927e"
                    class="bi bi-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    />
                  </svg>
                  {{ lecture.teacher_name }}
                </p>
                <h1
                  @click="
                    $router.push(
                      '/lecture/' + lecture.lecture_id
                    )
                  "
                >
                  {{ lecture.lecture_title }}
                </h1>
                <p class="p-details">
                  {{ lecture.lecture_description }}
                </p>
                <div class="con-price-button-96">
                  <h1>{{ lecture.lecture_subscription_fees }}</h1>
                  <div>
                    <button
                      @click="
                        $router.push(
                          '/lecture/' +
                            lecture.lecture_id
                        )
                      "
                    >
                      Book Now
                    </button>
                  </div>
                </div>
                <hr class="hr-courses" />
                <div class="con-duration-66">
                  <div class="con-duration-65">
                    <svg
                      class="calender-icon-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 40"
                      x="0px"
                      y="0px"
                    >
                      <g>
                        <path
                          d="M30,31H2a1,1,0,0,1-1-1V4A1,1,0,0,1,2,3H5V2A1,1,0,0,1,7,2V3H25V2a1,1,0,0,1,2,0V3h3a1,1,0,0,1,1,1V30A1,1,0,0,1,30,31ZM3,29H29V11H3ZM3,9H29V5H27V6a1,1,0,0,1-2,0V5H7V6A1,1,0,0,1,5,6V5H3ZM26,25H24a1,1,0,0,1-1-1V22a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v2A1,1,0,0,1,26,25Zm-1-2h0Zm-5,2H18a1,1,0,0,1-1-1V22a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v2A1,1,0,0,1,20,25Zm-1-2h0Zm-5,2H12a1,1,0,0,1-1-1V22a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v2A1,1,0,0,1,14,25Zm-1-2h0ZM8,25H6a1,1,0,0,1-1-1V22a1,1,0,0,1,1-1H8a1,1,0,0,1,1,1v2A1,1,0,0,1,8,25Zm18-6H24a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v2A1,1,0,0,1,26,19Zm-1-2h0Zm-5,2H18a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v2A1,1,0,0,1,20,19Zm-1-2h0Zm-5,2H12a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v2A1,1,0,0,1,14,19Zm-1-2h0ZM8,19H6a1,1,0,0,1-1-1V16a1,1,0,0,1,1-1H8a1,1,0,0,1,1,1v2A1,1,0,0,1,8,19ZM7,17H7Z"
                        />
                      </g>
                    </svg>
                    <p>{{ lecture.lecture_created_at }}</p>
                  </div>
                  <div class="con-duration-64">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#ffcb66"
                      class="bi bi-star-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#ffcb66"
                      class="bi bi-star-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#ffcb66"
                      class="bi bi-star-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#ffcb66"
                      class="bi bi-star-half"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#ffcb66"
                      class="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <div class="con-allteacher-page">
          <h1>All Top Lectures</h1>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-for="lecture in lectures" :key="lecture.lecture_id">
              <div class="main-con-card-course">
                <div class="con-image-card-course">
                  <!-- <img
                    @click="
                      $router.push(
                        '/lecture/' +
                          lecture.lecture_id
                      )
                    "
                    class="img-card-course-course"
                    :src="lecture.lecture_image"
                    alt=""
                  /> -->
                  <div class="div-of-my-lec-bac" v-bind:style="{
                    background: `url( ${lecture.lecture_image}) no-repeat center center `,
                  }">
                  </div>
                  <img
                  @click="$router.push('/teacher/' + lecture.teacher_id)"
                    class="img-card-course-teacher"
                    :src="lecture.teacher_profile_image"
                    alt=""
                  />
                </div>
                <div class="con-instractor-name" @click="$router.push('/teacher/' + lecture.teacher_id)">
                  <i class="fa-solid fa-user"></i><span>{{ lecture.teacher_name }}</span>
                </div>
                <p class="course-card-title"  @click="
                    $router.push(
                      '/lecture/' + lecture.lecture_id
                    )
                  ">{{ lecture.lecture_title }}</p>
                <p class="course-card-des">
                  {{ lecture.lecture_description }}
                </p>
                <div class="con-price-action">
                  <div class="con-course-card-price">
                    <p>Price :</p>
                    <h1>{{ lecture.lecture_subscription_fees }}</h1>
                  </div>
                  <div class="con-course-card-action">
                    <a href="#"  @click="
                        $router.push(
                          '/lecture/' +
                            lecture.lecture_id
                        )
                      ">Book Now</a>
                  </div>
                </div>
                <div class="con-date-rate">
                  <div class="con-course-card-date">
                    <i class="fa-solid fa-calendar-days"></i
                    ><span>{{ lecture.lecture_created_at }}</span>
                  </div>
                  <div class="con-course-card-rate">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half-stroke"></i>
                    <i class="fa-regular fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="con-pagination">
            <i class="fa-solid fa-angle-left"></i><span>1</span
            ><span class="active">2</span><span>3</span><span>4</span
            ><i class="fa-solid fa-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ListTopLectures",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters("listTopLectures", ["lectures", "nextPage"]),
  },
  methods: {
    ...mapActions("listTopLectures", ["getLecturesApi", "getNextLecturesApi"]),
    handleScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.body.scrollHeight;
        if (bottomOfWindow && this.nextPage) {
          this.getNextLecturesApi();
        }
      };
    },
  },
  beforeMount() {
    this.getLecturesApi();
  },
};
</script>