<template>
  <div class="con-side-bar-content-ins-course" :class="{'hide':!show}" id="con-side-ins-course-id">
    <LectureContentSidebar @show="openSidebar"></LectureContentSidebar>
    <div class="con-content-ins-course">
      <LectureContentHeader></LectureContentHeader>
      <Video
        v-if="currentContentType == 'video'"
        :content="currentContent"
      ></Video>
      <File
        v-else-if="currentContentType == 'file'"
        :content="currentContent"
      ></File>
      <Exam
        v-else-if="currentContentType == 'exam'"
        :content="currentContent"
      ></Exam>
      <Rate
        v-else-if="currentContentType == 'rate'"
      ></Rate>
      <lecture-content-navigation v-if="currentContentType != 'exam' || (currentContentType == 'exam' && currentContent.isDone)"></lecture-content-navigation>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LectureContentSidebar from "@/components/lecture-purchased/LectureContentSidebar.vue";
import LectureContentHeader from "@/components/lecture-purchased/LectureContentHeader.vue";
import LectureContentNavigation from "@/components/lecture-purchased/LectureContentNavigation.vue";
import File from "@/components/lecture-purchased/contents/File.vue";
import Video from "@/components/lecture-purchased/contents/Video.vue";
import Exam from "@/components/lecture-purchased/contents/Exam.vue";
import Rate from "@/components/lecture-purchased/contents/Rate.vue";

export default {
  name: "LecturePurchased",
  components: {
    LectureContentSidebar,
    LectureContentHeader,
    LectureContentNavigation,
    File,
    Video,
    Exam,
    Rate,
  },
  data(){
    return{
      show:false,
    }
  },  
  computed: {
    ...mapGetters("lecturePurchased", [
      "contentManagement",
      "currentContent",
      "content",
      "lecture",
      "progress",
      "currentContentType",
      "studentRate"
    ]),
  },
  methods: {
    ...mapActions("lecturePurchased", ["getLectureProgressApi"]),
    openSidebar(v){
      this.show = v;
    }
  },
  // async created() {
  beforeMount() {
    this.getLectureProgressApi(this.$route.params.id);
  },
};
</script>