import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Default from "./layouts/Default";
import Auth from "./layouts/Auth";
import Lecture from "./layouts/Lecture";

import "@/assets/css/all.min.css";
import "@/assets/css/fontawesome.min.css";
import "@/assets/mycollection (1)/font/flaticon_mockdeal.css";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import OtpInput from "@bachdgvn/vue-otp-input";
import './plugins/Toasted';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import AxiosPlugin from 'vue-axios-cors';
import i18n from '@/plugins/i18n';
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);
Vue.use(AxiosPlugin)
Vue.use(IconsPlugin)
Vue.use(BootstrapVue)

Vue.component('default-layout', Default);
Vue.component('auth-layout', Auth);
Vue.component('lecture-layout', Lecture);
Vue.component("v-otp-input", OtpInput);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n
}).$mount("#app");
