import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import home from "./modules/home";
import lecture from "./modules/lecture";
import lecturePurchased from "./modules/lecture-purchased";
import examResult from "./modules/exam-result";
import teacher from "./modules/teacher";
import post from "./modules/post";
import listAllTeachers from "./modules/list-all-teachers";
import listTopLectures from "./modules/list-top-lectures";
import listMyLectures from "./modules/list-my-lectures";
import listMyExams from "./modules/list-my-exams";
import search from "./modules/search";
import student from "./modules/student";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    home,
    lecture,
    lecturePurchased,
    examResult,
    teacher,
    post,
    listAllTeachers,
    listTopLectures,
    listMyLectures,
    listMyExams,
    search,
    student,
  },
});
