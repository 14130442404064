<template>
  <div v-if="currentContent.isDone" class="main-con-to-exam-result">
    <div class="con-result-card">
      <span>{{ currentContent.examResultPercentageLabel }}</span>
      <div class="con-span-p">
        <p>Result</p>
        <span>{{ currentContent.examResultLabel }}</span>
      </div>
      <div v-if="currentContent.isPassed">
        <div class="con-span-p">
          <p>Status</p>
          <span class="success"> success<i class="fa-solid fa-star"></i></span>
        </div>
        <div class="con-input-see-result-of-exam-22">
          <router-link
            :to="
              '/lecture-exam-results/' +
              teacher.teacher_id +
              '-' +
              currentContent.id
            "
            target="_blank"
          >
            Result Details
          </router-link>
        </div>
      </div>
      <div v-else>
        <div class="con-span-p">
          <p>Status</p>
          <span class="fail">
            Fail<img
              src="@/assets/images/consist_be-composed_combine_mix_design_amalgam_concur_broken_star-512.png"
              alt=""
          /></span>
        </div>
        <div style="text-align: center" v-if="!currentContent.cannot_skip">
          <div v-if="currentContent.allowedRestartExam">
            <p>Number of Attemptes Left</p>
            <p>
              {{ currentContent.content.attempts - currentContent.attempts }}
            </p>
            <div class="con-input-see-result-of-exam-22">
              <a @click="reStartExam"> Restart Exam </a>
            </div>
          </div>
          <div v-else>Contact Moderators</div>
        </div>
      </div>
    </div>
  </div>

  <div v-else-if="content.questions.length > 0" class="min-con-ins-coure-102">
    <div class="main-con-to-timer">
      <div class="main-con-to-hours">
        <div class="con-span-h">
          <span>{{ hours | twoDigits }}</span>
        </div>
        <h1>Hours</h1>
      </div>
      <span>:</span>
      <div class="main-con-to-hours">
        <div class="con-span-h">
          <span>{{ minutes | twoDigits }}</span>
        </div>
        <h1>Minutes</h1>
      </div>
      <span>:</span>
      <div class="main-con-to-hours">
        <div class="con-span-h">
          <span>{{ seconds | twoDigits }}</span>
        </div>
        <h1>Seconds</h1>
      </div>
    </div>
    <ExamBubble
      v-if="examType == 'bubble'"
      :content="content"
      @endExamStatus="setEndExamStatus"
    ></ExamBubble>
    <ExamNormal
      v-else-if="examType == 'normal'"
      :content="content"
      @endExamStatus="setEndExamStatus"
    ></ExamNormal>
    <div class="con-input-finish-exam" v-if="endExamStatus">
      <button @click="endExam">Finish Exam</button>
    </div>
  </div>

  <div v-else class="min-con-ins-coure-102">
    <ExamStart></ExamStart>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ExamStart from "@/components/lecture-purchased/contents/exams/ExamStart.vue";
import ExamBubble from "@/components/lecture-purchased/contents/exams/ExamBubble.vue";
import ExamNormal from "@/components/lecture-purchased/contents/exams/ExamNormal.vue";

export default {
  name: "Exam",
  components: {
    ExamStart,
    ExamBubble,
    ExamNormal,
  },
  data: function () {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      finish: false,
      endExamStatus: false,
    };
  },
  mounted() {
    const _self = this;
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
      if (!this.finish && this.calculatedDate - this.now <= 0) {
        _self.finish = true;
        _self.$emit("onFinish");
      }
    }, 1000);
  },
  computed: {
    ...mapGetters("lecturePurchased", [
      "contentManagement",
      "currentContent",
      "content",
      "lecture",
      "progress",
      "teacher",
      "examType",
    ]),
    secondCount() {
      return this.calculatedDate - this.now;
    },
    calculatedDate() {
      return Math.trunc(Date.parse(this.currentContent.expired_at) / 1000);
    },
    seconds() {
      if (this.secondCount < 0) return 0;
      return this.secondCount % 60;
    },
    minutes() {
      if (this.secondCount < 0) return 0;
      return Math.trunc(this.secondCount / 60) % 60;
    },
    hours() {
      if (this.secondCount < 0) return 0;
      return Math.trunc(this.secondCount / 60 / 60) % 24;
    },
    days() {
      if (this.secondCount < 0) return 0;
      return Math.trunc(this.secondCount / 60 / 60 / 24);
    },
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    },
  },
  methods: {
    ...mapActions("lecturePurchased", [
      "startExamApi",
      "reStartExamApi",
      "getLectureProgressApi",
      "endExamApi",
    ]),
    startExam() {
      this.startExamApi()
        .then(() => this.getLectureProgressApi(this.$route.params.id))
        .catch((err) => console.log(err));
    },
    reStartExam() {
      this.reStartExamApi()
        .then(() => this.getLectureProgressApi(this.$route.params.id))
        .catch((err) => console.log(err));
    },
    endExam() {
      this.endExamApi()
        .then(() => this.getLectureProgressApi(this.$route.params.id))
        .catch((err) => console.log(err));
    },
    setEndExamStatus(value) {
      this.endExamStatus = value;
    },
  },
  watch: {},
};
</script>