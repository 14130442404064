var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-con-ins-coure-102"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"main-con-to-question-card-exam main-con-to-question-card-exam-2"},_vm._l((_vm.content.questions),function(question,index){return _c('div',{key:question.id,staticClass:"main-con-to-question-exam"},[_c('div',{staticClass:"con-header-question-exam"},[_c('div',{staticClass:"con-question-exam-number-degree"},[_c('h1',[_vm._v("Question "+_vm._s(index + 1))]),_c('span',[_vm._v("("+_vm._s(index + 1)+"/"+_vm._s(_vm.content.questions.length)+")")])]),_c('div',{staticClass:"exam-title-1"},[_c('p',[_vm._v(" "+_vm._s(question.question.question)+" ")])]),(question.question.image)?_c('div',{staticClass:"con-question-exam-img"},[_c('img',{attrs:{"src":question.question.image_full_path,"alt":""}})]):_vm._e()]),(question.question.type == 'multi_choose')?_c('div',_vm._l((question.question.answers),function(answer,index){return _c('div',{key:answer.id,class:'con-answer-card-Question question_' +
                question.question_id +
                (question.answer_id == answer.id ? ' selected' : ''),on:{"click":function($event){return _vm.answerQuestion(question.question, answer.id, $event)}}},[_c('span',[_vm._v(_vm._s(_vm.answers[index]))]),_c('p',[_vm._v(_vm._s(answer.answer))]),(answer.image)?_c('img',{attrs:{"src":_vm.image_full_path,"alt":""}}):_vm._e()])}),0):_c('div',[_c('div',{class:'con-answer-card-Question question_' +
                question.question_id +
                (question.answer_id == 'true'  ? ' selected' : ''),on:{"click":function($event){return _vm.answerQuestion(question.question, 'true' , $event)}}},[_c('span',[_vm._v(_vm._s(_vm.answers[index]))]),_c('p',[_vm._v("Right")])]),_c('div',{class:'con-answer-card-Question question_' +
                question.question_id +
                (question.answer_id == 'false'  ? ' selected' : ''),on:{"click":function($event){return _vm.answerQuestion(question.question, 'false' , $event)}}},[_c('span',[_vm._v(_vm._s(_vm.answers[index]))]),_c('p',[_vm._v("Wrong")])])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }