<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-to-home-page-contnent">
        <!-- <div class="main-con-to-profile-teacher-page">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="con-card-teacher-img-profile">
                <img :src="teacher.teacher_profile_image" alt="" />
                <p class="side-card-teacher-name">{{ teacher.teacher_name }}</p>
                <p class="side-card-teacher-department">
                  {{ teacher.teacher_subject_name }}
                </p>
                <div class="con-side-card-rateing">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star-half-stroke"></i>
                  <i class="fa-regular fa-star"></i><span>(3.5)</span>
                </div>
                <div class="con-side-card-follow-button">
                  <button
                    v-if="!teacher.teacher_is_followed"
                    @click="followTeacher(teacher.teacher_id)"
                  >
                    Follow <i class="fas fa-plus"></i>
                  </button>
                  <button
                  class="unfollw"
                    v-else
                    @click="followTeacher(teacher.teacher_id)"
                  >
                    UnFollow
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="con-right-side-card-about">
                <h1>About Teacher</h1>
                <p>
                  {{teacher.description}}
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12 col-lg-8">
            <ul class="ul-profile-teacher-tabs">
              <li
                class="active"
                data-cont=".con-tabs-profile-all"
                @click.prevent="getPosts(teacher.teacher_id, $event)"
              >
                All
              </li>
              <li
                data-cont=".con-tabs-profile-lecture"
                @click.prevent="getLectures(teacher.teacher_id, $event)"
              >
                Lectures
              </li>
              <li
                data-cont=".con-tabs-profile-my-lecture"
                @click.prevent="getMyLectures(teacher.teacher_id, $event)"
              >
                My Lectures
              </li>
              <li
                data-cont=".con-tabs-profile-saved"
                @click.prevent="getSaved(teacher.teacher_id, $event)"
              >
                Saved
              </li>
              <li
                data-cont=".con-tabs-profile-liked"
                @click.prevent="getLiked(teacher.teacher_id, $event)"
              >
                Liked
              </li>
            </ul>
            <div class="main-con-to-posts main-con-to-posts-profile-teacher">
              <timeline :emptyCase="emptyCase" :posts="posts" :showMobileTopLecturesAndFollowTeacher="false"></timeline>
            </div>
          </div>
          <div class="col-12 col-lg-4">
           
            <div class="main-con-to-side-bar">
               <div class="con-card-teacher-img-profile" >
                <!-- <img :src="teacher.teacher_profile_image" alt="" /> -->
                <div class="img-back-ground-teacher-profile" v-bind:style="{
            background: `url( ${teacher.teacher_profile_image}) no-repeat center center `,
          }"></div>
                <p class="side-card-teacher-name">{{ teacher.teacher_name }}</p>
                <p class="side-card-teacher-department">
                  {{ teacher.teacher_subject_name }}
                </p>
                <div class="con-side-card-rateing">
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star"></i>
                  <i class="fa-solid fa-star-half-stroke"></i>
                  <i class="fa-regular fa-star"></i><span>(3.5)</span>
                </div>
                <div class="con-side-card-follow-button">
                  <button
                    v-if="!teacher.teacher_is_followed"
                    @click="followTeacher(teacher.teacher_id)"
                  >
                    Follow <i class="fas fa-plus"></i>
                  </button>
                  <button
                  class="unfollw"
                    v-else
                    @click="followTeacher(teacher.teacher_id)"
                  >
                    UnFollow
                  </button>
                </div>
              </div>
              <div class="con-right-side-card-about">
                <h1>About Teacher</h1>
                <p>
                  {{teacher.description}}
                </p>
              </div>
              <!-- <top-lectures :topLectures = topLectures></top-lectures> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import TopLectures from "../../components/sidebar/TopLectures.vue";
import Timeline from "../../components/timeline/Timeline.vue";

export default {
  components: { Timeline },
  name: "TeacherProfile",
  data() {
    return {
      emptyCase: {
        // 'image' : 'img/4170023.png',
        firstTitle: "OH !!",
        secondTitle: "There is No Posts",
      },
    };
  },
  computed: {
    ...mapGetters("teacher", ["teacher", "posts", "topLectures"]),
  },
  methods: {
    ...mapActions("teacher", [
      "getTeacherApi",
      "followTeacherApi",
      "getPostsApi",
      "getLecturesApi",
      "getMyLecturesApi",
      "getSavedApi",
      "getLikedApi",
      "getTopLecturesApi"
    ]),
    followTeacher(id) {
      this.followTeacherApi(id).then(() => {
        this.teacher.teacher_is_followed = this.teacher.teacher_is_followed
          ? 0
          : 1;
      });
    },
    removeActiveClass(event) {
      for (const li of document.querySelectorAll(
        ".ul-profile-teacher-tabs li.active"
      )) {
        li.classList.remove("active");
      }
      console.log(event,  event.target, event.target.parentElement);
      event.target.classList.add("active");
    },
    getPosts(teacherId, event) {
      this.removeActiveClass(event);
      this.getPostsApi(teacherId);
    },
    getLectures(teacherId, event) {
      this.removeActiveClass(event);
      this.getLecturesApi(teacherId);
    },
    getMyLectures(teacherId, event) {
      this.removeActiveClass(event);
      this.getMyLecturesApi(teacherId);
    },
    getSaved(teacherId, event) {
      this.removeActiveClass(event);
      this.getSavedApi(teacherId);
    },
    getLiked(teacherId, event) {
      this.removeActiveClass(event);
      this.getLikedApi(teacherId);
    },
    getTopLectures(teacherId) {
      this.getTopLecturesApi(teacherId);
    },
  },
  beforeMount() {
    this.getTeacherApi(this.$route.params.id);
    this.getPostsApi(this.$route.params.id);
    this.getTopLectures(this.$route.params.id);
  },
};
</script>