import Vue from "vue";
import axios from "@/axiosConfig";

export default ({
  state: {
    teacher: [],
    lecture: [],
  },
  actions: {
    getLectureApi({ commit }, id) {
      axios.get('/lectures/' + id)
        .then(response => {
          console.log(response);
          commit('SET_LECTURE', response);
        })
        .catch(error => {
          console.log(error)
        })
    },
    purchaseLectureByCouponApi({ state }, coupon) {
      return new Promise((resolve, reject) => {
        axios.post('/purchase/purchase-by-coupon/' + state.lecture.lecture_id, {
          'coupon': coupon,
        })
          .then(response => {
            Vue.$toast.success(response.data.message);
            // commit(_, response);
            resolve(response)
          })
          .catch(error => {
            Vue.$toast.error(error.response.data.message);
            reject(error)
          })
      })
    },
    purchaseLectureFromWalletApi({state}) {
      return new Promise((resolve, reject) => {
        axios.post('/purchase/purchase-by-wallet/' + state.lecture.lecture_id)
          .then(response => {
            Vue.$toast.success(response.data.message);
            resolve(response)
          })
          .catch(error => {
            Vue.$toast.error('failed');
            console.log(error)
            reject(error)
          })
      })
    },
    purchaseLectureByFawryApi({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/purchase/purchase-by-fawry/' + state.lecture.lecture_id)
          .then(response => {
            Vue.$toast.success(response.data.message);
            commit('', response);
            resolve(response)
          })
          .catch(error => {
            Vue.$toast.error('failed');
            console.log(error)
            reject(error)
          })
      })
    },
    purchaseLectureByCardApi({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/purchase/purchase-by-card/' + state.lecture.lecture_id, data)
          .then(response => {
            Vue.$toast.success(response.data.message);
            commit('', response);
            resolve(response)
          })
          .catch(error => {
            Vue.$toast.error('failed');
            console.log(error)
            reject(error)
          })
      })
    }
  },
  mutations: {
    SET_LECTURE(state, response) {
      state.teacher = response.data.data.teacher;
      state.lecture = response.data.data.lecture;
    },
  },
  getters: {
    teacher: state => state.teacher,
    lecture: state => state.lecture,
  },
  namespaced: true,
});
