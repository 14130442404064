import axios from "@/axiosConfig";

export default ({
  state: {
    student: [],
    posts: [],
    nextPage: 0,
    teachers: [],
  },
  actions: {
    getStudentApi({ commit }) {
      axios.get('/profile')
        .then(response => {
          commit('SET_STUDENT', response);
        })
        .catch(error => {
          console.log(error)
        })
    },
    getMyLecturesApi({ commit }) {
      axios.get('/profile/mylectures/')
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getSavedApi({ commit }) {
      axios.get('/profile/saved/')
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getRatedApi({ commit }) {
      axios.get('/profile/rated/')
        .then(response => {
          console.log(response);
          commit('SET_POSTS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    getTeachersApi({ commit }) {
      axios.get('/profile/teachers')
        .then(response => {
          console.log(response);
          commit('SET_TEACHERS', response);
        })
        .catch(error => {
          console.log(error)
        })
    },

    updateProfileApi(_, image) {

      return new Promise((resolve, reject) => {
console.log(image);
        axios.post('/profile/update', image)
          .then(response => {
            console.log(response);
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
  },
  mutations: {
    SET_STUDENT(state, response) {
      state.student = response.data.data.student;
    },
    SET_POSTS(state, response) {
      state.posts = response.data.data;
      state.nextPage = ((response.data.pagination.current_page + 1) <= response.data.pagination.total) ? response.data.pagination.current_page + 1 : response.data.pagination.total;
    },
    SET_TEACHERS(state, response) {
      state.teachers = response.data.data;
      state.nextPage = ((response.data.pagination.current_page + 1) <= response.data.pagination.total) ? response.data.pagination.current_page + 1 : response.data.pagination.total;
    },
  },
  getters: {
    student: state => state.student,
    posts: state => state.posts,
    nextPage: state => state.nextPage,
    teachers: state => state.teachers,
  },
  namespaced: true,
});
