<template>
  <!-- <div class="container-fluid con-flu-456 con-flu-457 con-flu-4231">
    <img
      src="@/assets/img/tms empty.png"
      alt=""
      class="img-sign-up-096 img-sign-up-022"
    />

    <div v-if="!isEmailSend" class="container con-9020 con-9021 con-9033">
      <div class="con-563-sign-up">
        <div class="container">
          <h1>Forgot Password Form</h1>
          <p class="p-Verification-Code">Forgot Password</p>
          <form @submit.prevent="forgetPassword">
            <div class="new-form-237 new-form-237-34">
              <input
                type="text"
                v-model="values.email"
                placeholder="Enter Your Email"
                @blur="validate('email')"
                @keypress="validate('email')"
                :class="['form-control', !!errors.email && 'is-invalid']"
              />
              <p
                :class="[
                  'form-input-hint',
                  !!errors.email && 'invalid-feedback',
                ]"
                v-if="!!errors.email"
              >
                {{ errors.email }}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-envelope-fill-11"
                viewBox="0 0 16 16"
              >
                <path
                  d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"
                />
              </svg>
            </div>
            <input type="submit" value="" id="submit-5843" />
            <label for="submit-5843" class="submit-5843 submit-58223">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="white"
                class="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </label>
          </form>
        </div>
      </div>
      <div class="con-box-you-have-acc">
        <p>Don:t have an account?</p>
        <router-link to="/register">Register</router-link>
      </div>
    </div>
    <div v-else class="container con-9020 con-9021 con-9033">
      <div class="con-563-sign-up">
        <div class="container">
          <h1>Login</h1>
          <p class="p-Verification-Code">Forgot Password</p>
          <p class="active-link-1">
            An activation link has sent to your e-mail .
          </p>
        </div>
      </div>
    </div>
  </div> -->
  <div>
    <div class="banner-sign-page"></div>
    <div v-if="!isEmailSend" class="main-con-to-sign-form">
      <form @submit.prevent="forgetPassword">
        <div class="con-logo-letter">
          <img src="@/assets/images/logo fav.icon.png" alt="" />
        </div>
        <h1>Forget Password</h1>
        <div class="con-input-form-sign">
          <label for="">Email</label>
          <input
            type="text"
            v-model="values.email"
            placeholder="Enter Your Email"
            @blur="validate('email')"
            @keypress="validate('email')"
            :class="['form-control', !!errors.email && 'is-invalid']"
          />
          <p
            :class="['form-input-hint', !!errors.email && 'invalid-feedback']"
            v-if="!!errors.email"
          >
            {{ errors.email }}
          </p>
          <i class="fa-regular fa-envelope"></i>
        </div>
        <div class="con-input-submit">
          <input type="submit" value="Send Email" />
        </div>
        <div class="con-i-not-member">
          <p>Not a member?</p>
          <router-link to="/register">Register</router-link>
        </div>
      </form>
    </div>
    <div v-else class="main-con-to-sign-form">
      <form>
        <div class="con-logo-letter">
          <img src="@/assets/images/logo udegram.svg" alt="" />
        </div>
        <h1>Forget Password</h1>
        <h2>An activation link has sent to your e-mail .</h2>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as Yup from "yup";

const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().required().email(),
});

export default {
  name: "ForgetPassword",
  data() {
    return {
      isEmailSend: null,
      values: {
        email: "",
      },
      errors: {
        email: "",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["forgetPasswordApi"]),
    validate(field) {
      forgetPasswordSchema.validateAt(field, this.values).catch(() => {});
    },
    forgetPassword: function () {
      forgetPasswordSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.errors = {};
          let email = this.values.email;
          this.forgetPasswordApi({ email })
            .then(() => {
              this.isEmailSend = 1;
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },
  },
};
</script>
