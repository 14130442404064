<template>
  <div class="main-con-to-home-page-contnent">
    <div class="container">
      <div class="con-row-more-page">
        <div class="row">
          <div class="col-6 col-md-4 col-lg-3">
            <div class="con-card-more" @click="$router.push('/')">
              <img src="@/assets/images/home 1.png" alt="" />
              <h1>Home</h1>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3">
            <div
              class="con-card-more"
              @click="$router.push('/list-my-lectures')"
            >
              <img src="@/assets/images/my lectures.png" alt="" />
              <h1>My Lectures</h1>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3">
            <div class="con-card-more" @click="$router.push('/files')">
              <img src="@/assets/images/files2.png" alt="" />
              <h1>Files</h1>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3" @click="$router.push('/exams')">
            <div class="con-card-more">
              <img src="@/assets/images/my exams.png" alt="" />
              <h1>My Exams</h1>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3" @click="$router.push('/wallet')">
            <div class="con-card-more">
              <img src="@/assets/images/my wallet .png" alt="" />
              <h1>My Wallet</h1>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3">
            <div class="con-card-more" @click="$router.push('/profile')">
              <img src="@/assets/images/profile.png" alt="" />
              <h1>My Profile</h1>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3" @click="$router.push('/privacy')">
            <div class="con-card-more">
              <img src="@/assets/images/privcy and policy.png" alt="" />
              <h1>privacy & policy</h1>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3" @click="$router.push('/termsandconditions')">
            <div class="con-card-more">
              <img src="@/assets/images/terms-and-conditions.png" alt="" />
              <h1>Terms & Conditions</h1>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3" @click="$router.push('/terms')">
            <div class="con-card-more">
              <img src="@/assets/images/search terms.png" alt="" />
              <h1>Serch Terms</h1>
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3" @click="out">
            <div class="con-card-more">
              <img src="@/assets/images/log out.png" alt="" />
              <h1>Log Out</h1>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Profile",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    out() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
<style scoped>
.container-fluid {
  height: 90%;
}
</style>