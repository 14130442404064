<template>
  <div>
    <button v-if="$i18n.locale != 'ar'" @click="SetLocale('ar')">
      العربية
    </button>
    <button v-else @click="SetLocale('en')">English</button>
  </div>
</template>
<script>
export default {
  name: "SwitchLocale",
  data() {
    return { langs: { en: "English", ar: "العربية" } };
  },
  methods: {
    SetLocale(locale) {
      // var rtl = null;

      if (locale == "ar") {
        // require("@/assets/css/rtl.css");
        // rtl =
        //   document.querySelectorAll("style")[
        //     document.querySelectorAll("style").length - 1
        //   ];
        // if (rtl.hasAttribute("media")) {
        //   rtl.removeAttribute("media");
        // }
        document.querySelector(".rtl-css").disabled = false;
      } else {
        // rtl =
        //   document.querySelectorAll("style")[
        //     document.querySelectorAll("style").length - 1
        //   ];
        // if (!rtl.hasAttribute("media")) {
        //   rtl.setAttribute("media", "max-width: 1px");
        // }
        document.querySelector(".rtl-css").disabled = true;
      }
      this.$i18n.locale = locale;
    },
  },
};
</script>