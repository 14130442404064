<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>

const default_layout = "default";

export default {
  computed: {
    layout(){
      return ((this.$route.meta.layout || default_layout) + '-layout');
    }
  }
};
</script>

<style>
  .form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: none !important;
  }
  .is-invalid~.invalid-feedback~.bi{
    top: 33%;
  }

  .is-invalid~.invalid-feedback~ label{
    bottom: 43% !important;
  }
  .form-control:focus{
    color:#212529;
    background-color:#fff;
    border-color:#86b7fe;
    outline:0;
    box-shadow:0 0 0 0rem rgba(13,110,253,.25) !important
  }
</style>