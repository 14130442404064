<template>
  <div class="con-post-header">
    <div
      class="con-img-post-teacher"
      @click="$router.push('/teacher/' + post.teacher_id)"
    >
      <img :src="post.teacher_profile_image" alt="" />
    </div>
    <div class="con-post-header-contnent">
      <div class="content-follow-name-verifi">
        <p
          class="teacher-post-name"
          @click="$router.push('/teacher/' + post.teacher_id)"
        >
          {{ post.teacher_name }}
          <i v-if="post.teacher_status" class="fa-solid fa-circle-check"></i>
        </p>
        <button
          type="button"
          data-bs-toggle="modal"
          :data-bs-target="`#modal-follow-teahcer_${post.teacher_id}`"
        >
          {{notFollow ? 'following': 'follow'}}
        </button>
      </div>

      <div class="teacher-post-date">
        <p>{{ post.created_at }}</p>
        <span>({{ post.teacher_subject_name }})</span>
      </div>
    </div>
    <div class="con-more-post">
      <div>
        <b-dropdown
          size="lg"
          variant="button"
          toggle-class="text-decoration-none"
          class="post-more-drop-down"
          no-caret
        >
          <template #button-content>
            <i class="fa-solid fa-ellipsis"></i>
          </template>
          <b-dropdown-item
            type="button"
            data-bs-toggle="modal"
            :data-bs-target="`#Report_id_${post._id}`"
          >
            <div class="con-dorp-more-img">
              <img src="@/assets/images/exclamation (1).png" alt="" />
            </div>
            <span>Report</span></b-dropdown-item
          >
          <hr />
          <b-dropdown-item @click="showPost">
            <i class="fa-regular fa-eye"></i
            ><span>See Post</span></b-dropdown-item
          >

          <hr />
          <b-dropdown-item @click="copy"
            ><i class="fa-solid fa-link"></i
            ><span>Copy Link</span></b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>

    <div
      class="modal fade report-modal-post"
      :id="`Report_id_${post._id}`"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="main-con-modal-reprt-content">
              <i class="flaticon-exclamation-mark-in-a-circle"> </i>
              <h2>Select One Reason</h2>
              <div
                class="con-reason-of-report"
                v-for="(repo, index) in getReportTypes"
                :key="index"
              >
                <input
                  type="radio"
                  name="report"
                  :value="repo.id"
                  :id="`reason-${repo.id}`"
                  v-model="reasonreport"
                />
                <label :for="`reason-${repo.id}`">
                  <span></span>
                  <p>{{ repo.name }}</p>
                </label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="Other Reason"
                  v-model="reportother"
                  v-if="repo.id == reasonreport && repo.status == 1"
                ></textarea>
              </div>

              <!-- <div class="con-reason-of-report">
            <input type="radio" name="report" id="reason-4">
            <label for="reason-4">
              <span></span>
              <p>other</p>
            </label>
          </div> -->
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
            <button
              type="button"
              class="report-button"
              @click="reportPost(post._id)"
              :data-bs-dismiss="reasonreport != 0 ? 'modal' : false"
            >
              Report
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade modal-follow"
      :id="`modal-follow-teahcer_${post.teacher_id}`"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body"><div class="main-con-to-un-follow-teacher">
            <i class="flaticon-info"></i>
            <p> look out You Are about to unfollow teacher now </p>
          </div></div>
          <div class="modal-footer">
            <button
              type="button"
              
              data-bs-dismiss="modal"
              @click.once="unfollowPost()"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
// import { mapActions, mapGetter } from "vuex/types/helpers";
import axios from "@/axiosConfig";
export default {
  name: "PostHeader",
  data() {
    return {
      reportother: "",
      reasonreport: 0,
      notFollow:1,
    };
  },
  props: {
    post: Object,
  },
  computed: {
    ...mapGetters("post", ["getReportTypes"]),
  },
  methods: {
    ...mapActions("post", ["reportTypesApi"]),
    showPost() {
      if (this.$router.currentRoute.name != "Show Post") {
        this.$router.push("/show-post/" + this.post._id);
      }
    },
    copy() {
      navigator.clipboard.writeText(
        location.origin + "/show-post/" + this.post._id
      );
      Vue.$toast.success("Copied");
    },
    unfollowPost(){
      this.notFollow = 0;
      axios.post(`/follow-teacher/${this.post.teacher_id}`)
    },
    reportPost(id) {
      if (this.reasonreport != []) {
        this.post.is_reported_by_student = !this.post.is_reported_by_student;
        axios.post(`/report-post/${id}`, {
          report_type: this.reasonreport,
          text: this.reportother,
        });
      }
    },
  },
  mounted() {
    this.reportTypesApi();
  },
};
</script>
